import { type AppThunk } from '~/Modules';
import {
  ADDRESS_MAPBOX_DEFAULT,
  addressMapboxFactory,
  RIDE_DEFAULT,
  rideFactory,
  type AddressMapbox,
  type RideModel,
  type RideProps
} from '~/models';
import { setRides } from '../RideBooking.slice';

export const initializeRidesThunk = (): AppThunk<RideModel[]> => {
  return function (dispatch, getState) {
    const patientStore = getState().patients;
    const memberProfileStore = getState().memberProfile;

    const rideOne: RideProps = RIDE_DEFAULT();

    // Use the member's home address. I do not know why it's
    // called rideData...
    if (patientStore?.patientDetails?.rideData) {
      const defaultAddress = patientStore.patientDetails.rideData;
      const departAddress: AddressMapbox = ADDRESS_MAPBOX_DEFAULT();

      departAddress.value = defaultAddress.from;
      // Convert lat and lng strings to floats
      departAddress.latitude = +defaultAddress.fromLat;
      departAddress.longitude = +defaultAddress.fromLng;
      departAddress.zip = defaultAddress.fromZip;

      rideOne.departAddress = addressMapboxFactory(departAddress);
    }

    if (memberProfileStore?.formData?.personalInfo?.otherDetails) {
      rideOne.notes = memberProfileStore.formData.personalInfo.otherDetails;
    }

    const rides = [rideFactory(rideOne)];

    dispatch(setRides(rides));

    return rides;
  };
};
