import React, { useState, useReducer, useEffect, useRef } from 'react';
import { Button, Section } from '@SRHealth/frontend-lib';
import useAnalytics from '~/hooks/useAnalytics';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  addReturnRideThunk,
  completeSection,
  editRides,
  removeReturnRide,
  RIDE_SECTION_ORDER,
  selectIsActive
} from '~/Modules/rideBooking';
import Loader from '~/Pages/RideBooking/Loader';
import RideCard from './subcomponents/RideCard';
import RideBookingMap, {
  MapContext,
  type MapMarker
} from './subcomponents/RideBookingMap';
import RidesSummary from './subcomponents/RidesSummary';
import { getRideRouteData } from './Rides.utils';

const inlineCSS = `
  [data-testid="section-rides-content-inner"] {
    padding: 0;
  }
`;

type RidesState = {
  time: number;
  totalTime: number;
  isFocused: boolean;
  isLoading: boolean;
};

type ReducerAction =
  | { type: 'focusin' }
  | { type: 'focusout' }
  | { type: 'resetTime' }
  | { type: 'loading'; isLoading: boolean }
  | { type: 'endTracking' };

const createInitialState = (): RidesState => ({
  time: 0,
  totalTime: 0,
  isFocused: false,
  isLoading: false
});

const reducer: React.Reducer<RidesState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'focusin':
      return state.isFocused ? state : { ...state, time: Date.now(), isFocused: true };
    case 'focusout':
      return !state.isFocused
        ? state
        : {
          ...state,
          isFocused: false,
          totalTime: state.totalTime + (Date.now() - state.time)
        };
    case 'resetTime':
      return { ...state, time: 0, totalTime: 0 };
    case 'loading':
      return { ...state, isLoading: action.isLoading };
    case 'endTracking':
      return {
        ...state,
        isFocused: false,
        totalTime: state.totalTime + (Date.now() - state.time)
      };
  }
};

const Rides = () => {
  const { trackEvent } = useAnalytics();
  const appDispatch = useAppDispatch();
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const rides = useAppSelector(state => state.rideBooking.rides);
  const date = useAppSelector(state => state.rideBooking.date.date);
  const isActive = useAppSelector(state => selectIsActive(state, 'rides'));
  const isCompletedRef = useRef(false);

  const [state, localDispatch] = useReducer(
    reducer,
    createInitialState(),
    createInitialState
  );

  /** Actions provided to the MapContext to manipulate the markers. */
  const markerActions = {
    addMarker: (marker: MapMarker) => setMarkers([...markers, marker]),
    removeMarker: (idx: number) => setMarkers(markers.toSpliced(idx, 1)),
    setMarkers: (markers: MapMarker[]) => setMarkers(markers)
  };

  /**
   * Handles adding a return ride to the ride booking by first
   * committing any outstanding changes to the initial ride
   * and then updating the RideBooking slice.
   */
  function handleAddRide() {
    localDispatch({ type: 'loading', isLoading: true });

    return getRideRouteData(rides[0], date)
      .then(data => {
        const _data = data ?? [];
        rides[0].distance = _data?.[0];
        rides[0].duration = _data?.[1];
      })
      .then(rides[0].commit)
      .then(success => {
        if (success) appDispatch(addReturnRideThunk());

        return success;
      })
      .catch(() => false)
      .finally(() => localDispatch({ type: 'loading', isLoading: false }));
  }

  /** Handles removing the return ride. This will always cause the
   * rides array to reset to a single leg. */
  function handleRemoveRide() {
    appDispatch(removeReturnRide());
    document
      .querySelector<HTMLButtonElement>('[data-testid="button-edit-ride"]')
      ?.click();
  }

  /** Handles the edit ride event by setting the distance on the
   * target ride to undefined. */
  function handleEditRide(idx: number) {
    if (!isActive) appDispatch(editRides());

    if (idx === 0) appDispatch(removeReturnRide());

    rides[idx].distance = undefined;

    return Promise.resolve();
  }

  /** Track the start of the rides section. */
  function handleStart() {
    trackEvent('RBF Rides', {
      EditStatus: 'Started',
      TimeOnSection: 0
    });
  }

  /**
   * The event handler attached to the RBF parent element. It detects if the user
   * clicks anywhere inside of the RBF element and then determines if the target is
   * within Funding Source. If it is, it triggers the focusin event. Otherwise it
   * triggers the focusout event.
   *
   * **Note:** This handler is only refreshed when the formRef changes. Logging state
   * will display data at the time that the handler was created. It will potentially be
   * outdated.
   */
  function handleFocus({ target }: PointerEvent) {
    const oldTarget = document.activeElement;
    const ridesElement = document.getElementById('rides')!;

    if (target === oldTarget) return;

    if (ridesElement?.contains(target as Node)) {
      localDispatch({ type: 'focusin' });
    } else if (ridesElement?.contains(oldTarget as Node)) {
      localDispatch({ type: 'focusout' });
    }
  }

  /** Event handler when completing rides section. */
  function handleReviewRide() {
    localDispatch({ type: 'loading', isLoading: true });

    const lastRide = rides.at(-1)!;

    const returnRideAddress =
      rides.length > 1 ? lastRide.toJSON()['departAddress']['value'] : false;

    getRideRouteData(lastRide, date)
      .then(data => {
        const _data = data ?? [];
        lastRide.distance = _data?.[0];
        lastRide.duration = _data?.[1];
      })
      .then(lastRide.commit)
      .then(success => {
        if (success) {
          isCompletedRef.current = true;
          localDispatch({ type: 'endTracking' });
          trackEvent('RBF Rides', {
            EditStatus: 'Completed',
            TimeOnSection: state.totalTime + (Date.now() - state.time),
            ReturnTripAddressChange: returnRideAddress
              ? returnRideAddress !== lastRide.departAddress.value
              : false
          });
          appDispatch(completeSection('rides'));
          document.querySelector(`[data-testid="section-rides"]`)?.scrollIntoView();
        }
      })
      .catch(() => {})
      .finally(() => localDispatch({ type: 'loading', isLoading: false }));
  }

  useEffect(() => {
    const rbfElement = document.querySelector('[data-testid="rbf"]');

    if (!rbfElement) {
      throw Error(
        'The data-testid attribute has been altered or removed on the RideBooking component.'
      );
    }

    rbfElement.addEventListener('pointerdown', handleFocus);
    return () => rbfElement.removeEventListener('pointerdown', handleFocus);
  }, []);

  useEffect(() => {
    if (state.isFocused && state.totalTime === 0) handleStart();
  }, [state.isFocused, state.totalTime]);

  useEffect(() => {
    if (!isActive && state.isFocused) {
      localDispatch({ type: 'endTracking' });
      trackEvent('RBF Rides', {
        EditStatus: isCompletedRef.current ? 'Completed' : 'Abandoned',
        TimeOnSection: state.totalTime + (Date.now() - state.time)
      });
    }

    return () => {
      if (state.isFocused && !isCompletedRef.current) {
        trackEvent('RBF Rides', {
          EditStatus: 'Abandoned',
          TimeOnSection: state.totalTime + (Date.now() - state.time)
        });
      }
    };
  }, [isActive]);

  return (
    <Section id="rides" icon="TransportCar" label="Rides">
      <style>{inlineCSS}</style>
      <Loader isLoading={state.isLoading} />
      {isActive ? (
        <MapContext.Provider value={[markers, markerActions]}>
          <div className="flex flex-row w-full">
            <div className="flex flex-col flex-1 gap-[16px] items-center px-[8px] py-[24px] bg-white">
              {rides.map((ride, idx) => (
                <RideCard
                  key={idx}
                  ride={ride}
                  onEditRide={() => handleEditRide(idx)}
                  onAddRide={idx > 0 ? undefined : handleAddRide}
                  onRemoveRide={idx > 0 ? handleRemoveRide : undefined}
                />
              ))}

              <div className="pt-[12px] pb-[24px]">
                <Button label="Review Ride" size="sm" onClick={handleReviewRide} />
              </div>
            </div>
            <div className="flex-1 shrink-0">
              <RideBookingMap />
            </div>
          </div>
        </MapContext.Provider>
      ) : (
        <RidesSummary rides={rides.map(r => r.toJSON())} onEditRide={handleEditRide} />
      )}
      <Section.Footer>
        {/* <div className="flex flex-row items-center justify-center">
          <Button label="Select Provider" onClick={NOOP} />
        </div> */}
      </Section.Footer>
    </Section>
  );
};

Rides.sectionIndex = RIDE_SECTION_ORDER.rides;

export { Rides };
