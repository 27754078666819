import React from 'react';
import { type RideBookingMode } from '../RideBooking';
import { Link as RouterLink } from 'react-router-dom';
import { SubHeader, Link, TOKENS } from '@SRHealth/frontend-lib';

type RideBookingSubHeaderProps = {
  mode: RideBookingMode;
  id: string;
  firstName: string | undefined;
  lastName: string | undefined;
};

/**
 *  Determine what label to display in the subheader based on the page mode
 * and the passenger name. If a first and last name are provided, an empty
 * string is returned.
 */
function getLabel(mode: RideBookingMode, firstName: string, lastName: string) {
  if (!firstName && !lastName) return '';

  const prefix = mode === 'new' ? 'New Transport Trip For' : 'Edit Transport Trip For';

  return `${prefix} ${firstName} ${lastName}`;
}

/**
 * Determine what label and href to display in the back link based on the page mode
 * and id.
 */
function getBackLink(mode: RideBookingMode, id: string) {
  const label = mode === 'new' ? 'Member Profile' : 'Ride Details';
  const href = mode === 'new' ? `/member-profiles/${id}/general/personal-info` : `#`;

  return [label, href];
}

export default function RideBookingSubHeader({
  mode,
  id,
  firstName = '',
  lastName = ''
}: RideBookingSubHeaderProps) {
  /** Computed Properties */
  const label = getLabel(mode, firstName, lastName);
  const [backLabel, backHref] = getBackLink(mode, id);

  return (
    <SubHeader>
      <div
        className="relative flex flex-row w-full align-center items-center justify-start"
        style={{ minHeight: '80px', marginTop: '-12px', marginBottom: '-8px' }}
      >
        <div className="absolute left-0">
          <RouterLink to={backHref} className="reset-a">
            <Link
              id="to-member-profile"
              href={backHref}
              label={backLabel}
              iconLeft="ChevronLeft"
            />
          </RouterLink>
        </div>
        <div
          className={`flex-1 heading-xl text-center text-${TOKENS.FONT.NEUTRAL}`}
          style={{ minHeight: '23px' }}
        >
          {label}
        </div>
      </div>
    </SubHeader>
  );
}
