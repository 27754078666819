import { put, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash-es';
import { stringify } from 'querystring';
import axios from './safeAxios';
import { token } from '~/utilities/auth.helper';
import {
  parseSubPlans,
  parseBenefits,
  parseBenefitCategory,
  parseBenefitCategoryList,
  parseTreatments,
  parseLocations
} from '~/utilities/plans.helper';

export const PLANS_GET_LIST = 'PLANS/get';
export const PLANS_GET_BENEFIT_CATEGORY_LIST = 'PLANS/getBenefitCategoryList';
export const PLANS_GET_BENEFIT_CATEGORY_LIST_SUCCESS =
  'PLANS/getBenefitCategoryList/success';
export const PLANS_GET_BENEFIT_CATEGORY_LIST_FAILURE =
  'PLANS/getBenefitCategoryList/failure';
export const PLAN_GET = 'PLAN/get';
export const PLAN_GET_SUCCESS = 'PLAN/get/success';
export const PLAN_GET_FAILURE = 'PLAN/get/failure';
export const PLANS_GET_LIST_SUCCESS = 'PLANS/get/success';
export const PLANS_GET_LIST_ERROR = 'PLANS/get/error';
export const PLANS_CREATE = 'PLANS/create';
export const PLANS_CREATE_SUCCESS = 'PLANS/create/success';
export const PLANS_CREATE_ERROR = 'PLANS/create/error';
export const PLANS_UPDATE = 'PLANS/update';
export const PLANS_UPDATE_SUCCESS = 'PLANS/update/success';
export const PLANS_UPDATE_ERROR = 'PLANS/update/error';
export const PLANS_DELETE = 'PLANS/delete';
export const PLANS_DELETE_SUCCESS = 'PLANS/delete/success';
export const PLANS_DELETE_ERROR = 'PLANS/delete/error';
export const PLANS_BENEFITS_GET = 'PLANS/benefits/get';
export const PLANS_BENEFITS_GET_SUCCESS = 'PLANS/benefits/get/success';
export const PLANS_BENEFITS_GET_ERROR = 'PLANS/benefits/get/error';
export const PLANS_BENEFITS_CREATE = 'PLANS/benefits/create';
export const PLANS_BENEFITS_CREATE_SUCCESS = 'PLANS/benefits/create/success';
export const PLANS_BENEFITS_CREATE_ERROR = 'PLANS/benefits/create/error';
export const PLANS_BENEFITS_UPDATE = 'PLANS/benefits/update';
export const PLANS_BENEFITS_UPDATE_SUCCESS = 'PLANS/benefits/update/success';
export const PLANS_BENEFITS_UPDATE_ERROR = 'PLANS/benefits/update/error';
export const PLANS_BENEFITS_DELETE = 'PLANS/benefits/delete';
export const PLANS_BENEFITS_DELETE_SUCCESS = 'PLANS/benefits/delete/success';
export const PLANS_BENEFITS_DELETE_ERROR = 'PLANS/benefits/delete/error';

export const PLANS_BENEFIT_CATEGORY_GET = 'PLANS/benefitsCategory/get';
export const PLANS_BENEFIT_CATEGORY_GET_SUCCESS = 'PLANS/benefitsCategory/get/success';
export const PLANS_BENEFIT_CATEGORY_GET_ERROR = 'PLANS/benefitsCategory/get/error';
export const PLANS_BENEFIT_CATEGORY_CREATE = 'PLANS/benefitsCategory/create';
export const PLANS_BENEFIT_CATEGORY_CREATE_SUCCESS =
  'PLANS/benefitsCategory/create/success';
export const PLANS_BENEFIT_CATEGORY_CREATE_ERROR = 'PLANS/benefitsCategory/create/error';
export const PLANS_BENEFIT_CATEGORY_UPDATE = 'PLANS/benefitsCategory/update';
export const PLANS_BENEFIT_CATEGORY_UPDATE_SUCCESS =
  'PLANS/benefitsCategory/update/success';
export const PLANS_BENEFIT_CATEGORY_UPDATE_ERROR = 'PLANS/benefitsCategory/update/error';
export const PLANS_BENEFIT_CATEGORY_DELETE = 'PLANS/benefitsCategory/delete';
export const PLANS_BENEFIT_CATEGORY_DELETE_SUCCESS =
  'PLANS/benefitsCategory/delete/success';
export const PLANS_BENEFIT_CATEGORY_DELETE_ERROR = 'PLANS/benefitsCategory/delete/error';

export const PLANS_TREATMENTS_GET = 'PLANS/treatments/get';
export const PLANS_TREATMENTS_GET_SUCCESS = 'PLANS/treatments/get/success';
export const PLANS_TREATMENTS_GET_ERROR = 'PLANS/treatments/get/error';
export const PLANS_TREATMENTS_RESET = 'PLANS/treatments/reset';
export const PLANS_LOCATIONS_GET = 'PLANS/locations/get';
export const PLANS_LOCATIONS_GET_SUCCESS = 'PLANS/locations/get/success';
export const PLANS_LOCATIONS_GET_ERROR = 'PLANS/locations/get/error';
export const PLANS_RESTRICTIONS_GET = 'PLANS/restrictions/get';
export const PLANS_RESTRICTIONS_GET_SUCCESS = 'PLANS/restrictions/get/success';
export const PLANS_RESTRICTIONS_GET_ERROR = 'PLANS/restrictions/get/error';
export const PLANS_RESTRICTIONS_CREATE = 'PLANS/restrictions/create';
export const PLANS_RESTRICTIONS_CREATE_SUCCESS = 'PLANS/restrictions/create/success';
export const PLANS_RESTRICTIONS_CREATE_ERROR = 'PLANS/restrictions/create/error';
export const PLANS_RESTRICTIONS_PLAN_GET = 'PLANS/restrictions/plan/get';
export const PLANS_RESTRICTIONS_PLAN_GET_SUCCESS = 'PLANS/restrictions/plan/get/success';
export const PLANS_RESTRICTIONS_PLAN_GET_ERROR = 'PLANS/restrictions/plan/get/error';
export const PLANS_RESTRICTIONS_PLAN_UPDATE = 'PLANS/restrictions/plan/update';
export const PLANS_RESTRICTIONS_PLAN_UPDATE_SUCCESS =
  'PLANS/restrictions/plan/update/success';
export const PLANS_RESTRICTIONS_PLAN_UPDATE_ERROR =
  'PLANS/restrictions/plan/update/error';
export const PLANS_RESTRICTIONS_PLAN_PATCH = 'PLANS/restrictions/plan/patch';
export const PLANS_RESTRICTIONS_PLAN_PATCH_SUCCESS =
  'PLANS/restrictions/plan/patch/success';
export const PLANS_RESTRICTIONS_PLAN_PATCH_ERROR = 'PLANS/restrictions/plan/patch/error';
export const PLANS_RESTRICTIONS_PLAN_DELETE = 'PLANS/restrictions/plan/delete';
export const PLANS_RESTRICTIONS_PLAN_DELETE_SUCCESS =
  'PLANS/restrictions/plan/delete/success';
export const PLANS_RESTRICTIONS_PLAN_DELETE_ERROR =
  'PLANS/restrictions/plan/delete/error';
export const PLANS_RESTRICTIONS_PLAN_CLEAR = 'PLANS/restrictions/plan/clear';
export const PLANS_CUSTOMFIELDS_GET = 'PLANS/customFields/get';
export const PLANS_CUSTOMFIELDS_GET_SUCCESS = 'PLANS/customFields/get/success';
export const PLANS_CUSTOMFIELDS_GET_ERROR = 'PLANS/customFields/get/error';
export const PLANS_CUSTOMFIELDS_PUT = 'PLANS/customFields/put';
export const PLANS_CUSTOMFIELDS_PUT_SUCCESS = 'PLANS/customFields/put/success';
export const PLANS_CUSTOMFIELDS_PUT_ERROR = 'PLANS/customFields/put/error';
export const PLANS_CUSTOMFIELDS_DELETE = 'PLANS/customFields/delete';
export const PLANS_CUSTOMFIELDS_DELETE_SUCCESS = 'PLANS/customFields/delete/success';
export const PLANS_CUSTOMFIELDS_DELETE_ERROR = 'PLANS/customFields/delete/error';
export const PLANS_LOCATIONS_RESET = 'PLANS/locations/reset';
export const PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE =
  'PLANS/restrictions/memberPortal/update';
export const PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE_SUCCESS =
  'PLANS/restrictions/memberPortal/update/success';
export const PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE_ERROR =
  'PLANS/restrictions/memberPortal/update/error';

const initialState = {
  currentPage: 1
};

/**
 * removes hospital network data
 * @return {dispatch} - returns dispatch
 */
export const clearRestrictionsPlan = () => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_PLAN_CLEAR
    });
  };
};

/**
 * Dispatch action for getting plans
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getPlans = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_GET_LIST,
      data: params
    });
  };
};

/**
 * Dispatch action for getting benefit categories
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getBenefitCategoryList = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_GET_BENEFIT_CATEGORY_LIST,
      data: params
    });
  };
};

/**
 * Dispatch action for getting plans
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getPlan = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLAN_GET,
      data: params
    });
  };
};

/**
 * Dispatch action for creating a plan
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const createPlan = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_CREATE,
      data: params
    });
  };
};

/**
 * Dispatch action for updating a plan
 * @param   {Object} params Action dispatch params
 * @returns {Function} Dispatch action
 */
export const updatePlan = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_UPDATE,
      data: params
    });
  };
};

/**
 * Dispatch action for deleting a plan
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const deletePlan = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_DELETE,
      data: params
    });
  };
};

/**
 * Dispatch action for getting a subplan's benefits
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const getBenefits = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFITS_GET,
      data: params
    });
  };
};

/**
 * Dispatch action to create a benefit
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const createBenefit = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFITS_CREATE,
      data: params
    });
  };
};

/**
 * Dispatch action to update a benefit
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const updateBenefit = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFITS_UPDATE,
      data: params
    });
  };
};

/**
 * Dispatch action to delete a benefit
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const deleteBenefit = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFITS_DELETE,
      data: params
    });
  };
};

/**
 * Dispatch action for getting a subplan's benefit categories
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const getBenefitCategories = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFIT_CATEGORY_GET,
      data: params
    });
  };
};

/**
 * Dispatch action to create a benefit category
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const createBenefitCategory = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFIT_CATEGORY_CREATE,
      data: params
    });
  };
};

/**
 * Dispatch action to update a benefit category
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const updateBenefitCategory = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFIT_CATEGORY_UPDATE,
      data: params
    });
  };
};

/**
 * Dispatch action to delete a benefit category
 * @param   {Object}    params  Action dispatch params
 * @returns {Function}          Dispatch action
 */
export const deleteBenefitCategory = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_BENEFIT_CATEGORY_DELETE,
      data: params
    });
  };
};

/**
 * Dispatch action for getting a subplan's treatments
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getTreatments = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_TREATMENTS_GET,
      data: params
    });
  };
};

/**
 * Dispatch action for getting a subplan's locations
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getLocations = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_LOCATIONS_GET,
      data: params
    });
  };
};

/**
 * Dispatch action for getting access restrictions for health networks
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getAccessRestrictions = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_GET,
      data: params
    });
  };
};

/**
 * Dispatch action for creating access restrictions for health networks
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const createAccessRestrictions = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_CREATE,
      data: params
    });
  };
};

/**
 * Dispatch action for creating access restrictions for health networks
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getAccessRestriction = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_PLAN_GET,
      data: params
    });
  };
};

/**
 * Dispatch action for creating access restrictions for health networks
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const updateAccessRestriction = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_PLAN_UPDATE,
      data: params
    });
  };
};

/**
 * Dispatch action for creating access restrictions for health networks
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const patchAccessRestriction = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_PLAN_PATCH,
      data: params
    });
  };
};

/**
 * Dispatch action for creating access restrictions for health networks
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const deleteAccessRestriction = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_PLAN_DELETE,
      data: params
    });
  };
};

/**
 * Get a list of all custom fields for this plan
 * @param   {Object}  params action dispatch params
 * @returns {Function}  dispatch action
 */
export const getCustomFields = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_CUSTOMFIELDS_GET,
      data: params
    });
  };
};

/**
 * Call to initiate API call to insert/update a custom field
 * @param {Object} params Data for object to create
 * @returns {Function} dispatch action
 */
export const postCustomField = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_CUSTOMFIELDS_PUT,
      data: params
    });
  };
};

/**
 * Call to initiate API call to delete a custom field
 * @param {Object} params
 * @returns {Function} dispatch action
 */
export const deleteCustomField = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_CUSTOMFIELDS_DELETE,
      data: params
    });
  };
};

/**
 * Call to initiate API call to update member portal network
 * for subplan
 * @param {Object} params
 * @returns {Function} dispatch action
 */
export const updateMemberPortalConfig = (params = {}) => {
  return dispatch => {
    dispatch({
      type: PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE,
      data: params
    });
  };
};

/**
 * Generator function for getting plans from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* getAccessRestrictionsFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/network-permissions?offset=${params.offset}&limit=${params.limit}`,
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    };
    const results = yield axios(config);
    const data = _.get(results, 'data.data.networks', []);
    const totalCount = _.get(results, 'data.data.count', 0);
    yield put({
      type: PLANS_RESTRICTIONS_GET_SUCCESS,
      data,
      params,
      totalCount
    });
  } catch (error) {
    yield put({
      type: PLANS_RESTRICTIONS_GET_ERROR,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* createAccessRestrictionsFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'POST',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/network-permissions`,
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({
      type: PLANS_RESTRICTIONS_CREATE_SUCCESS,
      data,
      params
    });
  } catch (error) {
    yield put({
      type: PLANS_RESTRICTIONS_CREATE_ERROR,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* getAccessRestrictionPlanFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/network-permissions/${params.id}`,
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_GET_SUCCESS,
      data,
      params
    });
  } catch (error) {
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_GET_ERROR,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* updateAccessRestrictionPlanFromApi(action = {}) {
  try {
    const params = action.data;

    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/network-permissions/${params.id}`,
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      },
      data: {
        permission: params.permission
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_UPDATE_SUCCESS,
      data,
      params,
      permission: params.permission
    });
  } catch (error) {
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_UPDATE_ERROR,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* patchAccessRestrictionPlanFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'PATCH',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/network-permissions/${params.id}`,
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_PATCH_SUCCESS,
      data,
      params
    });
  } catch (error) {
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_PATCH_ERROR,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* deleteAccessRestrictionPlanFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'DELETE',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/network-permissions/${params.id}`,
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_DELETE_SUCCESS,
      data,
      params
    });
  } catch (error) {
    yield put({
      type: PLANS_RESTRICTIONS_PLAN_DELETE_ERROR,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* getPlansFromApi(action = {}) {
  try {
    const params = action.data;
    const user = yield select(state => state.user);
    let currentPage = yield select(state => state.plans.currentPage);

    if (params.incrementPage) {
      currentPage += 1;
    }

    const queryParams = {
      healthPlanId: user.healthPlan.id,
      page: currentPage,
      pageSize: 300,
      sortBy: 'id',
      sortDirection: 'asc',
      mode: 'simple'
    };
    const config = {
      method: 'GET',
      url: `//${
        process.env.REACT_APP_ANALYTICS_API_HOST
      }/api/v1/health-plans/sub-plans?${stringify(queryParams)}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };
    const results = yield axios(config);
    const data = parseSubPlans(results.data.data);
    const totalPlanCount = results?.data?.data?.subPlans?.meta?.totalCount;
    yield put({
      type: PLANS_GET_LIST_SUCCESS,
      data,
      totalPlanCount,
      params
    });
  } catch (error) {
    yield put({
      type: PLANS_GET_LIST_ERROR,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* getBenefitCategoryListFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.id}/categories`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };
    const results = yield axios(config);
    const data = parseBenefitCategoryList(results.data.data);
    yield put({
      type: PLANS_GET_BENEFIT_CATEGORY_LIST_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: PLANS_GET_BENEFIT_CATEGORY_LIST_FAILURE,
      error
    });
  }
}

/**
 * Generator function for getting plans from API
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* getPlanFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params}?mode=simple`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };
    const results = yield axios(config);
    const data = results.data;
    yield put({
      type: PLAN_GET_SUCCESS,
      data,
      params
    });
  } catch (error) {
    yield put({
      type: PLAN_GET_FAILURE,
      error
    });
  }
}

/**
 * Generator function for creating a plan
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* createPlanFromApi(action = {}) {
  try {
    const params = action.data;
    const user = yield select(state => state.user);
    const formData = {
      health_plan_id: user.healthPlan.id,
      name: params.planName,
      externalSubPlanId: params.planId,
      subPlanRestrictions: params.benefits.map(benefit => {
        return {
          restriction_type_id: benefit.restrictionTypeId,
          alert_after_limit: benefit.alertLimit,
          block_after_limit: benefit.blockLimit,
          book_after_block: benefit.bookAfterBlock
        };
      })
    };
    const config = {
      method: 'POST',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plan-and-plan-benefits`,
      headers: {
        Authorization: `Bearer ${token()}`
      },
      data: formData
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_CREATE_SUCCESS, data, params });

    // reload plans
    yield put({ type: PLANS_GET_LIST, data: {} });
  } catch (error) {
    yield put({ type: PLANS_CREATE_ERROR, error });
  }
}

/**
 * Update plan info
 * @param {Object} action Dispatcher action params
 * @returns {undefined}
 */
function* updatePlanFromApi(action = {}) {
  try {
    const params = action.data;
    const user = yield select(state => state.user);
    const formData = {
      health_plan_id: user.healthPlan.id,
      name: params.planName,
      external_sub_plan_id: params.planId,
      is_active: params.isEnabled,
      treatments: params.treatmentRestrictionEnabled ? 1 : 0,
      member_portal_treatments: params.treatmentsMemberPortalEnabled ? 1 : 0,
      locations: params.locationRestrictionEnabled ? 1 : 0,
      approved_providers: params.approvedProvidersEnabled ? 1 : 0,
      approved_providers_hard_block: params.approvedProvidersHardBlockEnabled ? 1 : 0,
      waive_cancellations: params.waiveCancellations ? 1 : 0,
      member_portal: params.memberPortal ? 1 : 0,
      claim_submission_type_id: params.claimSubmissionTypeId,
      d_snp: params.dsnpEnabled ? 1 : 0,
      supports_wrap: params.wrapEnabled ? 1 : 0,
      benefits_program: params.dsnpBenefitsProgram
    };
    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      },
      data: formData
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_UPDATE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_UPDATE_ERROR, error });
  }
}

/**
 * Generator function for deleting a plan
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* deletePlanFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'DELETE',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_DELETE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_DELETE_ERROR, error });
  }
}

/**
 * Generator function for getting subplan benefits
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* getBenefitsFromApi(action = {}) {
  try {
    const params = action.data;
    const page = params.page || 1;
    const pageSize = params.pageSize || 100;
    const queryParams = {
      page,
      pageSize,
      sortBy: 'id',
      sortDirection: 'asc',
      mode: 'advanced'
    };
    const config = {
      method: 'GET',
      url: `//${
        process.env.REACT_APP_ANALYTICS_API_HOST
      }/api/v1/health-plans/sub-plans/${params.id}/benefits?${stringify(queryParams)}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    const results = yield axios(config);
    const data = parseBenefits(_.get(results.data.data, 'subPlans', {}));
    yield put({ type: PLANS_BENEFITS_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFITS_GET_ERROR, error });
  }
}

/**
 * Generator function for creating a benefit
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* createBenefitFromApi(action = {}) {
  try {
    const params = action.data;
    const formData = {
      restrictionTypeId: params.restrictionTypeId,
      alertAfterLimit: params.alertLimit,
      blockAfterLimit: params.blockLimit,
      bookAfterBlock: params.bookAfterBlock
    };
    const config = {
      method: 'POST',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/benefits`,
      headers: {
        Authorization: `Bearer ${token()}`
      },
      data: formData
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_BENEFITS_CREATE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFITS_CREATE_ERROR, error });
  }
}

/**
 * Generator function for updating a benefit
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* updateBenefitFromApi(action = {}) {
  try {
    const params = action.data;
    const formData = {
      restriction_type_id: params.restrictionTypeId,
      alert_after_limit: params.alertLimit,
      block_after_limit: params.blockLimit,
      book_after_block: params.bookAfterBlock
    };
    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/benefits/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      },
      data: formData
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_BENEFITS_UPDATE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFITS_UPDATE_ERROR, error });
  }
}

/**
 * Generator function for deleting a benefit
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* deleteBenefitFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'DELETE',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/benefits/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_BENEFITS_DELETE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFITS_DELETE_ERROR, error });
  }
}

/**
 * Generator function for getting subplan benefits
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* getBenefitCategoriesFromApi(action = {}) {
  try {
    const params = action.data;
    const page = params.page || 1;
    const pageSize = params.pageSize || 100;
    const queryParams = {
      page,
      pageSize,
      sortBy: 'id',
      sortDirection: 'asc',
      mode: 'advanced'
    };
    const config = {
      method: 'GET',
      url: `//${
        process.env.REACT_APP_ANALYTICS_API_HOST
      }/api/v1/health-plans/sub-plans/${params.id}/benefitCategory?${stringify(
        queryParams
      )}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    const results = yield axios(config);
    const data = parseBenefitCategory(_.get(results.data.data, 'subPlans', {}));
    yield put({ type: PLANS_BENEFIT_CATEGORY_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFIT_CATEGORY_GET_ERROR, error });
  }
}

/**
 * Generator function for creating a benefit
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* createBenefitCategoryFromApi(action = {}) {
  try {
    const params = action.data;
    const formData = {
      restrictionTypeId: params.restrictionTypeId,
      benefitCategoryTypeId: params.benefitCategoryTypeId,
      alertAfterLimit: params.alertLimit,
      blockAfterLimit: params.blockLimit,
      bookAfterBlock: params.bookAfterBlock
    };
    const config = {
      method: 'POST',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/benefitCategory`,
      headers: {
        Authorization: `Bearer ${token()}`
      },
      data: formData
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_BENEFIT_CATEGORY_CREATE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFITS_CREATE_ERROR, error });
  }
}

/**
 * Generator function for updating a benefit
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* updateBenefitCategoryFromApi(action = {}) {
  try {
    const params = action.data;
    const formData = {
      restriction_type_id: params.restrictionTypeId,
      benefit_category_id: params.benefitCategoryTypeId,
      alert_after_limit: params.alertLimit,
      block_after_limit: params.blockLimit,
      book_after_block: params.bookAfterBlock
    };
    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/benefitCategory/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      },
      data: formData
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_BENEFIT_CATEGORY_UPDATE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFIT_CATEGORY_UPDATE_ERROR, error });
  }
}

/**
 * Generator function for deleting a benefit
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* deleteBenefitCategoryFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'DELETE',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/benefitCategory/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: PLANS_BENEFIT_CATEGORY_DELETE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_BENEFITS_DELETE_ERROR, error });
  }
}

/**
 * Generator function for getting treatments from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* getTreatmentsFromApi(action = {}) {
  try {
    const params = action.data;
    const page = params.page || 1;
    const pageSize = params.pageSize || 100;
    const queryParams = {
      page,
      pageSize,
      sortBy: 'id',
      sortDirection: 'asc',
      mode: 'advanced'
    };
    const config = {
      method: 'GET',
      url: `//${
        process.env.REACT_APP_ANALYTICS_API_HOST
      }/api/v1/health-plans/sub-plans/${params.id}/treatments?${stringify(queryParams)}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    // reset treatments data
    yield put({ type: PLANS_TREATMENTS_RESET, data: params });

    const results = yield axios(config);
    const data = parseTreatments(_.get(results.data.data, 'subPlans', {}));

    yield put({ type: PLANS_TREATMENTS_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_TREATMENTS_GET_ERROR, error });
  }
}

/**
 * Generator function for getting locations from an API
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* getLocationsFromApi(action = {}) {
  try {
    const params = action.data;
    const page = params.page || 1;
    const pageSize = params.pageSize || 100;
    const queryParams = {
      page,
      pageSize,
      sortBy: 'id',
      sortDirection: 'asc',
      mode: 'advanced'
    };
    const config = {
      method: 'GET',
      url: `//${
        process.env.REACT_APP_ANALYTICS_API_HOST
      }/api/v1/health-plans/sub-plans/${params.id}/locations?${stringify(queryParams)}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    // reset locations data
    yield put({ type: PLANS_LOCATIONS_RESET, data: params });

    const results = yield axios(config);
    const data = parseLocations(_.get(results.data.data, 'subPlans', {}));

    yield put({ type: PLANS_LOCATIONS_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_LOCATIONS_GET_ERROR, error });
  }
}

/**
 * Gets custom fields per sub-plan
 * @param {*} action - Contains sub plan id to get custom fields for
 * @returns {Generator} - yields
 */
function* getCustomFieldsFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/custom-fields`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    const results = yield axios(config);
    const data = results.data.data.map(record => {
      const newRecord = _.cloneDeep(record);
      newRecord.loadedAt = Date.now();
      return newRecord;
    });

    yield put({ type: PLANS_CUSTOMFIELDS_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: PLANS_CUSTOMFIELDS_GET_ERROR, error });
  }
}

/**
 * Updates a custom field in the db via api
 * @param {Object} action - Action with params to update custom field
 * @returns {Generator} - yields
 */
function* putCustomFieldFromApi(action = {}) {
  const params = _.pick(action.data, [
    'id',
    'label',
    'field_type',
    'selectors',
    'subPlanId'
  ]);
  try {
    let config = {};
    if (_.get(action, 'data.id', false) === false) {
      config = {
        method: 'POST',
        url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/custom-fields`,
        data: params,
        headers: {
          Authorization: `Bearer ${token()}`
        }
      };
    } else {
      config = {
        method: 'PATCH',
        url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/custom-fields/${params.id}`,
        data: params,
        headers: {
          Authorization: `Bearer ${token()}`
        }
      };
    }

    const results = yield axios(config);
    const data = results.data.data;

    yield put({ type: PLANS_CUSTOMFIELDS_PUT_SUCCESS, data, params });
    yield put({
      type: PLANS_CUSTOMFIELDS_GET,
      data: { subPlanId: params.subPlanId }
    }); //Reload custom fields
  } catch (error) {
    yield put({ type: PLANS_CUSTOMFIELDS_PUT_ERROR, error });
    yield put({
      type: PLANS_CUSTOMFIELDS_GET,
      data: { subPlanId: params.subPlanId }
    }); //Reload custom fields
  }
}

/**
 * Calls API to remove a custom field
 * @param {Object} action - Contains information on which Custom field to delete
 * @returns {Generator} - yields
 */
function* deleteCustomFieldFromApi(action = {}) {
  const params = _.get(action, 'data', {});
  try {
    const config = {
      method: 'DELETE',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/custom-fields/${params.id}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    yield axios(config);
    yield put({ type: PLANS_CUSTOMFIELDS_DELETE_SUCCESS, data: {}, params });
    yield put({
      type: PLANS_CUSTOMFIELDS_GET,
      data: { subPlanId: params.subPlanId }
    }); //Reload custom fields
  } catch (error) {
    yield put({ type: PLANS_CUSTOMFIELDS_DELETE_ERROR, error });
  }
}

/**
 * Calls API to update the member portal enabled network for subplan
 * @param {Object} action - Contains information on which hospital
 * network to update and parameters for getting updated hospital
 * network restrictions
 * @returns {Generator} - yields
 */
function* updateMemberPortalConfigOfPlanFromApi(action = {}) {
  try {
    const params = _.get(action, 'data', {});
    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${params.subPlanId}/network-permissions/${params.id}`,
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      },
      data: {
        permission: params.permission,
        member_portal_network: params.member_portal_network
      }
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({
      type: PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE_ERROR,
      error
    });
  }
}

/**
 * Saga generator function for all plans related API calls
 * @returns {undefined}
 */
export function* plansSaga() {
  yield takeEvery(PLANS_GET_LIST, getPlansFromApi);
  yield takeEvery(PLANS_GET_BENEFIT_CATEGORY_LIST, getBenefitCategoryListFromApi);
  yield takeEvery(PLAN_GET, getPlanFromApi);
  yield takeEvery(PLANS_CREATE, createPlanFromApi);
  yield takeEvery(PLANS_UPDATE, updatePlanFromApi);
  yield takeEvery(PLANS_DELETE, deletePlanFromApi);
  yield takeEvery(PLANS_BENEFITS_GET, getBenefitsFromApi);
  yield takeEvery(PLANS_BENEFITS_CREATE, createBenefitFromApi);
  yield takeEvery(PLANS_BENEFITS_UPDATE, updateBenefitFromApi);
  yield takeEvery(PLANS_BENEFITS_DELETE, deleteBenefitFromApi);

  yield takeEvery(PLANS_BENEFIT_CATEGORY_GET, getBenefitCategoriesFromApi);
  yield takeEvery(PLANS_BENEFIT_CATEGORY_CREATE, createBenefitCategoryFromApi);
  yield takeEvery(PLANS_BENEFIT_CATEGORY_UPDATE, updateBenefitCategoryFromApi);
  yield takeEvery(PLANS_BENEFIT_CATEGORY_DELETE, deleteBenefitCategoryFromApi);

  yield takeEvery(PLANS_TREATMENTS_GET, getTreatmentsFromApi);
  yield takeEvery(PLANS_LOCATIONS_GET, getLocationsFromApi);
  yield takeEvery(PLANS_RESTRICTIONS_GET, getAccessRestrictionsFromApi);
  yield takeEvery(PLANS_RESTRICTIONS_CREATE, createAccessRestrictionsFromApi);
  yield takeEvery(PLANS_RESTRICTIONS_PLAN_GET, getAccessRestrictionPlanFromApi);
  yield takeEvery(PLANS_RESTRICTIONS_PLAN_UPDATE, updateAccessRestrictionPlanFromApi);
  yield takeEvery(PLANS_RESTRICTIONS_PLAN_PATCH, patchAccessRestrictionPlanFromApi);
  yield takeEvery(PLANS_RESTRICTIONS_PLAN_DELETE, deleteAccessRestrictionPlanFromApi);
  yield takeEvery(PLANS_CUSTOMFIELDS_GET, getCustomFieldsFromApi);
  yield takeEvery(PLANS_CUSTOMFIELDS_PUT, putCustomFieldFromApi);
  yield takeEvery(PLANS_CUSTOMFIELDS_DELETE, deleteCustomFieldFromApi);
  yield takeEvery(
    PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE,
    updateMemberPortalConfigOfPlanFromApi
  );
}

/**
 * Reducer function for dispatched actions
 * @param   {Object}    state Redux state
 * @param   {Object}    action Redux action
 * @returns {undefined}
 */
export const plansReducer = (state = initialState, action = {}) => {
  const newState = _.cloneDeep(state);

  // just in case currentPage doesn't exist
  if (!newState.currentPage) {
    newState.currentPage = 1;
  }

  switch (action.type) {
    case PLANS_GET_LIST_SUCCESS: {
      newState.status = true;
      if (action.params.incrementPage) {
        newState.currentPage += 1;
      }
      newState.totalPlanCount = action.totalPlanCount;
      newState.data = { ...newState.data, ...action.data };
      return newState;
    }
    case PLANS_GET_BENEFIT_CATEGORY_LIST_SUCCESS: {
      newState.availableBenefitCategories = action.data;
      return newState;
    }
    case PLAN_GET_SUCCESS: {
      newState.data[action.params] = Object.assign(
        newState.data[action.params],
        action.data.data.subPlans.rows[0]
      );
      return newState;
    }
    case PLANS_UPDATE_SUCCESS: {
      newState.data[action.params.id] = Object.assign(newState.data[action.params.id], {
        planName: action.params.planName,
        planId: action.params.planId,
        isEnabled: action.params.isEnabled,
        treatmentRestrictionEnabled: action.params.treatmentRestrictionEnabled,
        treatmentsMemberPortalEnabled: action.params.treatmentsMemberPortalEnabled,
        locationRestrictionEnabled: action.params.locationRestrictionEnabled,
        approvedProvidersEnabled: action.params.approvedProvidersEnabled,
        approvedProvidersHardBlockEnabled:
          action.params.approvedProvidersHardBlockEnabled,
        waiveCancellations: action.params.waiveCancellations,
        claim_submission_types: _.get(
          action.data,
          'subPlans[0].claim_submission_types',
          ''
        ),
        updatedAt: _.get(action.data, 'subPlans[0].updated_at', '')
      });
      return newState;
    }
    case PLANS_DELETE_SUCCESS: {
      delete newState.data[action.params.id];
      return newState;
    }
    case PLANS_BENEFITS_GET_SUCCESS: {
      newState.data[action.params.id].benefits = action.data;
      return newState;
    }
    case PLANS_BENEFITS_CREATE_SUCCESS: {
      const benefitsDataRef = newState.data[action.params.subPlanId].benefits;
      const newBenefit = {
        id: action.data.healthSubPlanBenefitIds[0],
        ...action.params
      };

      // check if subplan already has benefits
      if (benefitsDataRef.rows) {
        benefitsDataRef.rows.push(newBenefit);
      } else {
        benefitsDataRef.rows = [newBenefit];
        benefitsDataRef.meta = { totalCount: 1 };
      }

      return newState;
    }
    case PLANS_BENEFIT_CATEGORY_CREATE_SUCCESS: {
      const benefitsDataRef = newState.data[action.params.subPlanId].benefitCategories;
      const newBenefit = {
        id: action.data.healthSubPlanBenefitIds[0],
        ...action.params
      };

      // check if subplan already has benefits
      if (benefitsDataRef.rows) {
        benefitsDataRef.rows.push(newBenefit);
      } else {
        benefitsDataRef.rows = [newBenefit];
        benefitsDataRef.meta = { totalCount: 1 };
      }
      return newState;
    }
    case PLANS_BENEFITS_UPDATE_SUCCESS: {
      const benefitsDataRef = newState.data[action.params.subPlanId].benefits;
      let benefit = benefitsDataRef.rows.find(benefit => benefit.id === action.params.id);
      if (benefit) {
        benefit = Object.assign(benefit, action.params);
      }
      return newState;
    }
    case PLANS_BENEFIT_CATEGORY_UPDATE_SUCCESS: {
      const benefitsDataRef = newState.data[action.params.subPlanId].benefitCategories;
      let benefit = benefitsDataRef.rows.find(benefit => benefit.id === action.params.id);
      if (benefit) {
        benefit = Object.assign(benefit, action.params);
      }
      return newState;
    }
    case PLANS_BENEFITS_DELETE_SUCCESS: {
      const benefitsDataRef = newState.data[action.params.subPlanId].benefits;
      benefitsDataRef.rows = benefitsDataRef.rows.filter(
        benefit => benefit.id !== action.params.id
      );
      return newState;
    }
    case PLANS_BENEFIT_CATEGORY_DELETE_SUCCESS: {
      const benefitsDataRef = newState.data[action.params.subPlanId].benefitCategories;
      benefitsDataRef.rows = benefitsDataRef.rows.filter(
        benefit => benefit.id !== action.params.id
      );
      return newState;
    }
    case PLANS_BENEFIT_CATEGORY_GET_SUCCESS: {
      newState.data[action.params.id].benefitCategories = action.data;
      return newState;
    }
    case PLANS_TREATMENTS_GET_SUCCESS: {
      newState.data[action.params.id].health_sub_plan_treatment = action.data;
      return newState;
    }
    case PLANS_TREATMENTS_RESET: {
      delete newState.data[action.data.id].health_sub_plan_treatment;
      return newState;
    }
    case PLANS_LOCATIONS_GET_SUCCESS: {
      newState.data[action.params.id].health_sub_plan_location = action.data;
      return newState;
    }
    case PLANS_LOCATIONS_RESET: {
      delete newState.data[action.data.id].health_sub_plan_location;
      return newState;
    }
    case PLANS_RESTRICTIONS_GET_SUCCESS: {
      newState.restrictions = action.data;
      newState.params = action.params;
      newState.type = action.type;
      newState.totalCount = action.totalCount;
      newState.timestamp = Date.now();
      return newState;
    }
    case PLANS_RESTRICTIONS_CREATE_SUCCESS: {
      return newState;
    }
    case PLANS_RESTRICTIONS_PLAN_GET_SUCCESS: {
      newState.healthNetwork = {
        subPlanId: action.data.health_sub_plan_id,
        hospitalGroupId: action.data.hospital_group_id,
        permission: action.data.permission
      };
      newState.type = action.type;
      newState.timestamp = Date.now();
      return newState;
    }
    case PLANS_RESTRICTIONS_PLAN_UPDATE_SUCCESS: {
      newState.restrictions = newState.restrictions.map(restriction => {
        if (restriction.id === action.data.hospital_group_id) {
          restriction.permission = action.data.permission_type;
        }
        return restriction;
      });
      newState.healthNetwork.permission = action.data.permission_type;
      newState.type = action.type;
      newState.timestamp = Date.now();
      return newState;
    }
    case PLANS_RESTRICTIONS_PLAN_DELETE_SUCCESS: {
      return newState;
    }
    case PLANS_RESTRICTIONS_PLAN_PATCH_SUCCESS: {
      return newState;
    }
    case PLANS_RESTRICTIONS_GET_ERROR:
    case PLANS_RESTRICTIONS_CREATE_ERROR:
    case PLANS_RESTRICTIONS_PLAN_GET_ERROR:
    case PLANS_RESTRICTIONS_PLAN_UPDATE_ERROR:
    case PLANS_RESTRICTIONS_PLAN_DELETE_ERROR:
    case PLANS_RESTRICTIONS_PLAN_PATCH_ERROR:
    case PLANS_BENEFIT_CATEGORY_GET_ERROR:
    case PLANS_BENEFIT_CATEGORY_CREATE_ERROR:
    case PLANS_BENEFIT_CATEGORY_DELETE_ERROR:
    case PLANS_GET_BENEFIT_CATEGORY_LIST_FAILURE:
    case PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE_ERROR: {
      newState.type = action.type;
      newState.timestamp = Date.now();
      return newState;
    }
    case PLANS_RESTRICTIONS_PLAN_CLEAR: {
      newState.type = action.type;
      _.unset(newState, 'healthNetwork');
      return newState;
    }
    case PLANS_CUSTOMFIELDS_GET: {
      newState.customFields = [];
      return newState;
    }
    case PLANS_CUSTOMFIELDS_GET_SUCCESS: {
      newState.customFields = action.data;
      newState.customFieldsLastLoad = Date.now();
      return newState;
    }
    case PLANS_RESTRICTIONS_MEMBER_PORTAL_CONFIG_UPDATE_SUCCESS:
      newState.restrictions = newState.restrictions.map(restriction => {
        if (restriction.id === action.data.hospital_group_id) {
          restriction.member_portal_network = 1;
        } else {
          restriction.member_portal_network = 0;
        }
        return restriction;
      });
      newState.type = action.type;
      newState.timestamp = Date.now();
      return newState;
    default: {
      return state;
    }
  }
};
