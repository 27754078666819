import type { RideBookingSection } from './RideBooking.types';

/** Controls the ordering of the RBF sections. */
export const RIDE_SECTION_ORDER: Readonly<Record<RideBookingSection, number>> = {
  ['passenger-info']: 0,
  ['funding-source']: 1,
  ['date']: 2,
  ['rides']: 3
} as const;

/** Contains all standardized messaging for the RideBooking domain. */
export const TXT = Object.freeze({
  /** Messaging for health sub plans that are improperly configured to use treatments but
   * which don't have any. */
  SUB_PLAN_MISSING_TREATMENTS:
    'It looks like this health sub plan doesn\'t have any treatments enabled. Please ' +
    'contact your administrator for more information.',
  /** Messsaging for passengers soft blocked on ride benefits. */
  BENEFIT_SOFT_BLOCK_WARNING:
    'Please be advised that the member has' +
    ' used all their benefits for this treatment before proceeding.',
  /** Messaging for passengers hard blocked on ride benefits. */
  BENEFIT_HARD_BLOCK_WARNING:
    'This member has used all their benefits for this treatment.' +
    ' If applicable, please choose another treatment type.',
  /** Messaging for passengers that are currently ineligible but can
   * book a past ride. */
  BENEFIT_PAST_BLOCK_WARNING:
    'This member is no longer eligible for this treatment but may still' +
    ' schedule a ride for a past date.'
} as const);
