import type { AddressMapboxModel } from '~/models';
import type { AddressOption, MapboxFeature } from '../Rides.types';

/** Generates the default address options for the ride address dropdowns. */
export function genDefaultAddressOptions(addressModel: AddressMapboxModel) {
  return addressModel.value
    ? [
      {
        label: addressModel.value,
        value: {
          value: addressModel.value,
          latitude: addressModel.latitude,
          longitude: addressModel.longitude,
          zip: addressModel.zip,
          type: addressModel.type
        }
      }
    ]
    : [];
}

/**
 * Generates the address options from the Mapbox auto complete data.
 */
export function genAddressOptions(
  mapboxAutoCompleteData: MapboxFeature[]
): AddressOption[] {
  return mapboxAutoCompleteData.map(feature => ({
    label: feature.place_name,
    value: {
      value: feature.place_name,
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
      zip:
        feature.context?.find(context => context.id.startsWith('postcode'))?.text ?? '',
      type: 'mapbox'
    }
  }));
}
