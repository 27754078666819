import React, { useState, useEffect } from 'react';
import { Input, useModel } from '@SRHealth/frontend-lib';
import {
  type AddressMapbox,
  type AddressMapboxModel,
  type AddressModel,
  ADDRESS_MAPBOX_DEFAULT,
  addressFactory
} from '~/models';
import type { AddressFieldType, AddressOption } from '../Rides.types';
import { genAddressOptions, genDefaultAddressOptions } from './Address.utils';
import { getMapboxAddressOptions } from '~/services/mapbox.service';
import { useAppSelector } from '~/Modules';
import { selectHospitalCoords } from '~/Modules/user';

export type AddressInputProps = {
  type: AddressFieldType;
  address: AddressModel;
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
};

export default function Address({ type, address, onAddressChange }: AddressInputProps) {
  const { model: addressModel } = useModel(address as AddressMapboxModel);
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);
  const hospitalCoords = useAppSelector(state => selectHospitalCoords(state));

  const label = type === 'depart' ? 'Depart' : 'Arrive';

  const currentAddressValue = addressOptions.find(
    ({ value }) => value.value === addressModel.value
  )?.value;

  /** Get the mapbox address options from API and set them as the address options. */
  const getAddressOpts = async (addressLookupString: string) => {
    if (addressLookupString.length < 3) return;

    return getMapboxAddressOptions(addressLookupString, hospitalCoords).then(
      ({ features }) => features && setAddressOptions(genAddressOptions(features))
    );
  };

  useEffect(() => {
    setAddressOptions(genDefaultAddressOptions(addressModel));
  }, []);

  return (
    <Input
      inputId={0}
      type="search-dropdown"
      name={type}
      label={label}
      options={addressOptions}
      loading={false}
      placeholder="Search for an address"
      value={currentAddressValue}
      debounce={650}
      onSearch={(_, addressLookupString: string) => getAddressOpts(addressLookupString)}
      onInput={(_, addressLookupString: string) => getAddressOpts(addressLookupString)}
      onChange={(_, addressObj: AddressMapbox) =>
        onAddressChange(type, addressFactory(addressObj || ADDRESS_MAPBOX_DEFAULT()))
      }
      error={!!Object.keys(addressModel.ruleErrors).length}
      required
    />
  );
}
