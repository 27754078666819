import React, { useContext } from 'react';
import { Icon, TOKENS, Map } from '@SRHealth/frontend-lib';
import { MapContext, type MapMarker } from './RideBookingMap.context';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN ?? '';

const RideBookingMap = () => {
  const [markers] = useContext(MapContext);

  // Default type to pickup if not provided
  const [departMarkers, arriveMarkers] = markers.reduce(
    (p, c) => {
      p[c.type === 'arrive' ? 0 : 1].push(c);
      return p;
    },
    [[] as MapMarker[], [] as MapMarker[]]
  );

  if (!departMarkers.length && !arriveMarkers.length) {
    return (
      <div
        className={`flex flex-col items-center justify-center h-full w-full bg-${TOKENS.SURFACE.PRIMARY.XLT}`}
        style={{ boxShadow: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.25)' }}
      >
        <Icon
          type="Location"
          className={`text-${TOKENS.ICON.ACTIVE} fill-current`}
          style={{ height: '80px', width: '80px', paddingBottom: '32px' }}
        />
        <span className={`text-${TOKENS.FONT.PRIMARY} text-center text-xl`}>
          Please enter an address to
          <br />
          view map
        </span>
      </div>
    );
  }

  return (
    <div
      className="relative w-full"
      style={{
        height: '760px',
        position: 'sticky',
        top: '60px'
      }}
    >
      <Map
        mapboxAccessToken={MAPBOX_TOKEN}
        pickupMarkers={departMarkers}
        dropoffMarkers={arriveMarkers}
        scale={false}
        navigation={false}
      />
      <div
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        style={{ boxShadow: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.25)' }}
      />
    </div>
  );
};

export default RideBookingMap;
export * from './RideBookingMap.context';
