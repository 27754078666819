import React, { useEffect, useReducer } from 'react';
import { Button, DatePicker, Section, Card } from '@SRHealth/frontend-lib';
import { store } from '~/store';
import useAnalytics from '~/hooks/useAnalytics';
import useModelSelector from '~/hooks/useModelSelector';
import type { DuplicateRides } from './Date.types';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  completeSection,
  editDate,
  getTimeRestrictionsThunk,
  RIDE_SECTION_ORDER,
  selectIsActive
} from '~/Modules/rideBooking';
import { getDuplicateRides as _getDuplicateRides } from '~/services/rideBooking.service';
import DateSummary from './subcomponents/DateSummary';
import Loader from '~/Pages/RideBooking/Loader';

type DateState = {
  time: number;
  totalTime: number;
  isFocused: boolean;
  isLoading: boolean;
  softBlock: boolean;
  isCompleted: boolean;
  duplicateRides: DuplicateRides;
};

type ReducerAction =
  | { type: 'focusin' }
  | { type: 'focusout' }
  | { type: 'resetTime' }
  | { type: 'edit' }
  | { type: 'complete' }
  | { type: 'loading'; isLoading: boolean }
  | { type: 'setIsCompleted'; isCompleted: boolean }
  | { type: 'setDuplicateRides'; duplicateRides: DuplicateRides };

const createInitialState = (): DateState => ({
  time: 0,
  totalTime: 0,
  isFocused: false,
  isLoading: false,
  softBlock: false,
  isCompleted: false,
  duplicateRides: []
});

const reducer: React.Reducer<DateState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'complete':
      return { ...state, isCompleted: true, isLoading: false };
    case 'edit':
      store.dispatch(editDate());
      return { ...state, isCompleted: false, softBlock: false, isLoading: false };
    case 'focusin':
      return state.isFocused ? state : { ...state, time: Date.now(), isFocused: true };
    case 'focusout':
      return !state.isFocused
        ? state
        : {
          ...state,
          isFocused: false,
          totalTime: state.totalTime + (Date.now() - state.time)
        };
    case 'resetTime':
      return { ...state, time: 0, totalTime: 0 };
    case 'loading':
      return { ...state, isLoading: action.isLoading };
    case 'setIsCompleted':
      return { ...state, isCompleted: action.isCompleted };
    case 'setDuplicateRides':
      return {
        ...state,
        duplicateRides: action.duplicateRides,
        softBlock: action.duplicateRides.length > 0
      };
  }
};

const DateSection = () => {
  const { trackEvent } = useAnalytics();
  const appDispatch = useAppDispatch();
  const { model } = useModelSelector(state => state.rideBooking.date);
  const isActive = useAppSelector(state => selectIsActive(state, 'date'));
  const passengerId = useAppSelector(s => s.rideBooking.passengerInfo.passengerId);

  const [state, localDispatch] = useReducer(
    reducer,
    createInitialState(),
    createInitialState
  );

  /** Fetch duplicate rides for the selected date. */
  const getDuplicateRides = (selectedDate: IsoDate) => {
    if (!passengerId || !selectedDate) {
      throw Error('passengerId and selectedDate are required');
    }

    localDispatch({ type: 'loading', isLoading: true });

    return _getDuplicateRides(selectedDate, passengerId)
      .then(({ data: duplicateRides }) => {
        localDispatch({ type: 'setDuplicateRides', duplicateRides });
        return duplicateRides.length > 0;
      })
      .finally(() => localDispatch({ type: 'loading', isLoading: false }));
  };

  /** Track the start of the date section. */
  const handleStart = () => {
    trackEvent('RBF Date', {
      EditStatus: 'Started',
      TimeOnSection: 0
    });
  };

  /** Validate and commit any outstanding changes to the model. */
  const handleSave = async () => {
    if (!model.isDirty && Object.keys(model.ruleErrors).length) return;

    // Always check for duplicates if the date has changed and the soft block is not active.
    if (model.date && model.propertyIsDirty('date') && !state.softBlock) {
      if (await getDuplicateRides(model.date!)) return;
    }

    localDispatch({ type: 'loading', isLoading: true });

    model
      .commit()
      .then(() => {
        trackEvent('RBF Date', {
          EditStatus: 'Completed',
          DateSelected: model.date as IsoDate,
          TimeOnSection: state.totalTime + (Date.now() - state.time),
          DuplicateFound: state.softBlock
        });

        localDispatch({ type: 'complete' });
        appDispatch(getTimeRestrictionsThunk());
        appDispatch(completeSection('date'));
        document.querySelector(`[data-testid="section-date"]`)?.scrollIntoView();
      })
      .catch(() => {})
      .finally(() => localDispatch({ type: 'loading', isLoading: false }));
  };

  /** Cancel trip, dispatch mixpanel event, and return to Member Profile. */
  const handleCancel = () => {
    if (model.isDirty) {
      trackEvent('RBF Date', {
        EditStatus: 'Abandoned',
        DateSelected: model.date as IsoDate,
        TimeOnSection: state.totalTime + (Date.now() - state.time),
        DuplicateFound: state.softBlock // should always be true for now, but keeping this boolean based off .softBlock for future proofing
      });
    }
    document.getElementById('to-member-profile')!.click();
  };

  /**
   * The event handler attached to the RBF parent element. It detects if the user
   * clicks anywhere inside of the RBF element and then determines if the target is
   * within the Date section. If it is, it triggers the focusin event. Otherwise it
   * triggers the focusout event.
   *
   * **Note:** This handler is only refreshed when the formRef changes. Logging state
   * will display data at the time that the handler was created. It will potentially be
   * outdated.
   */
  const handleFocus = ({ target }: PointerEvent) => {
    const oldTarget = document.activeElement;
    const dateElement = document.getElementById('date')!;

    if (target === oldTarget) return;

    if (dateElement?.contains(target as Node)) {
      localDispatch({ type: 'focusin' });
    } else if (dateElement?.contains(oldTarget as Node)) {
      localDispatch({ type: 'focusout' });
    }
  };

  useEffect(() => {
    const rbfElement = document.querySelector('[data-testid="rbf"]');

    if (!rbfElement) {
      throw Error(
        'The data-testid attribute has been altered or removed on the RideBooking component.'
      );
    }

    rbfElement.addEventListener('pointerdown', handleFocus);
    return () => rbfElement.removeEventListener('pointerdown', handleFocus);
  }, []);

  /** When the element has focus for the first time then send off a Mixpanel
   * event indicating that the user has started editing the section. */
  useEffect(() => {
    if (state.isFocused && state.totalTime === 0) handleStart();
  }, [state.isFocused, state.totalTime]);

  useEffect(() => {
    if (!isActive && state.isFocused) {
      trackEvent('RBF Date', {
        EditStatus: 'Abandoned',
        DateSelected: model.date as IsoDate,
        TimeOnSection: state.totalTime + (Date.now() - state.time),
        DuplicateFound: state.softBlock
      });
    }
  }, [isActive]);

  return (
    <Section id="date" icon="DateRange" label="Date">
      <Loader isLoading={state.isLoading} />

      {state.softBlock || state.isCompleted ? (
        <DateSummary
          duplicateRides={state.duplicateRides}
          selectedDate={model.date as IsoDate}
          onEdit={() => localDispatch({ type: 'edit' })}
        />
      ) : (
        <Card label="Select A Date" border={false}>
          <div style={{ marginTop: '32px' }}>
            <DatePicker
              date={model.date}
              earliestDate={model.dateRestrictions?.earliestDate}
              latestDate={model.dateRestrictions?.latestDate}
              excludedDates={model.dateRestrictions?.restrictedDates}
              onChange={(selectedDate: IsoDate) => {
                model.date = selectedDate;
              }}
            />
          </div>
        </Card>
      )}

      {!state.isCompleted && (
        <Section.Footer>
          <div className="flex flex-row items-center justify-center">
            {state.softBlock && (
              <Button alt label="Cancel Trip" onClick={handleCancel} minWidth="160px" />
            )}
            <Button
              label={state.softBlock ? 'Continue' : 'Save & Continue'}
              onClick={handleSave}
              disabled={!model.date}
              minWidth="160px"
            />
          </div>
        </Section.Footer>
      )}
    </Section>
  );
};

DateSection.sectionIndex = RIDE_SECTION_ORDER.date;

export { DateSection };
