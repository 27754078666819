import { Is } from '@SRHealth/frontend-lib';
import type { PassengerInfoModel } from '~/models';
import type { PassengerInfoSection } from './PassengerInfo.types';

/** Logic to determine if the SMS Opt Out modal should trigger
 * after passenger info updates have been committed. */
export function shouldTriggerOptOutModal(
  section: PassengerInfoSection,
  model: PassengerInfoModel
) {
  return (
    section === 'TripPhoneNumber' &&
    model.phoneNumber &&
    model.phoneNumberType === 'mobile' &&
    (Is.Null(model.phoneNumberOptOutStatus) || model.phoneNumberOptOutStatus === 0)
  );
}
