import { createAppAsyncThunk } from '~/Modules';
import {
  getApprovedProviders,
  type ApiSchemaApprovedProvider
} from '~/services/approvedProvider.service';

export const fetchApprovedProviders = createAppAsyncThunk(
  'search/fetchApprovedProviders',
  async (
    {
      search,
      hospitalId,
      offset = 0,
      limit = 20
    }: ApiSchemaApprovedProvider['Read']['Request'],
    { rejectWithValue }
  ) => {
    if (!search) return;

    return await getApprovedProviders(search, hospitalId, offset, limit)
      .then(res => ({ ...res, offset, limit }))
      .catch(rejectWithValue);
  },
  { condition: ({ search }) => !!search }
);
