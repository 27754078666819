import { capitalize, TOKENS, Icon } from '@SRHealth/frontend-lib';
import React from 'react';
import type { AddressModel } from '~/models';

export type RideCardSummaryAddressProps = {
  type: 'depart' | 'arrive';
  address: AddressModel;
};

export default function RideCardSummaryAddress({
  type,
  address
}: RideCardSummaryAddressProps) {
  const pinSrc = type === 'depart' ? 'DepartPin' : 'ArrivePin';
  const pinAlt = type === 'depart' ? 'Departure Pin' : 'Arrival Pin';

  switch (address.type) {
    case 'mapbox':
      return (
        <div className="reset-div flex flex-row gap-[12px] items-start">
          <Icon type={pinSrc} title={pinAlt} style={{ zIndex: 10 }} />
          <div
            className={
              'reset-div flex-1 flex flex-col py-[4px] gap-[4px] ' +
              `border-b border-${TOKENS.BORDER.NEUTRAL_MD}`
            }
          >
            <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>
              {capitalize(type)}
            </span>
            <div className="text-md">{address.value}</div>
            <div className="text-md">{address.zip}</div>
          </div>
        </div>
      );
  }
}
