import { createTypeGuard } from '@SRHealth/frontend-lib';
import {
  type AddressApprovedProvider,
  type AddressApprovedProviderModel,
  addressApprovedProviderFactory
} from './AddressApprovedProvider.model';
import {
  type AddressApprovedProviderNotFound,
  type AddressApprovedProviderNotFoundModel,
  addressApprovedProviderNotFoundFactory
} from './AddressApprovedProviderNotFound.model';
import {
  type AddressMapbox,
  type AddressMapboxModel,
  addressMapboxFactory
} from './AddressMapbox.model';
import {
  type AddressSaved,
  type AddressSavedModel,
  addressSavedFactory
} from './AddressSaved.model';
import {
  type AddressVenue,
  type AddressVenueModel,
  addressVenueFactory
} from './AddressVenue.model';

export type AddressModel =
  | AddressApprovedProviderModel
  | AddressApprovedProviderNotFoundModel
  | AddressMapboxModel
  | AddressSavedModel
  | AddressVenueModel;

export type AddressProps =
  | AddressApprovedProvider
  | AddressApprovedProviderNotFound
  | AddressMapbox
  | AddressSaved
  | AddressVenue;

export const isAddressModel = createTypeGuard((t): AddressModel | null => {
  if (
    addressMapboxFactory.createdModel(t) ||
    addressApprovedProviderFactory.createdModel(t) ||
    addressApprovedProviderNotFoundFactory.createdModel(t) ||
    addressSavedFactory.createdModel(t) ||
    addressVenueFactory.createdModel(t)
  ) {
    return t;
  }

  return null;
});

/** Factory to create an address model. This isn't strictly a model factory but
 * acts as a factory for multiple models. It routes the creation of the model
 * based on the "type" property of the initial properties. If no initial properties
 * are provided, it will default to creating a mapbox address. */
function addressFactory(initialProperties?: AddressProps): AddressModel {
  switch (initialProperties?.type) {
    case 'approved-provider':
      return addressApprovedProviderFactory(initialProperties);
    case 'provider-not-found':
      return addressApprovedProviderNotFoundFactory(initialProperties);
    case 'saved':
      return addressSavedFactory(initialProperties);
    case 'venue':
      return addressVenueFactory(initialProperties);
    case 'mapbox':
    default:
      return addressMapboxFactory(initialProperties);
  }
}

addressFactory.createdModel = isAddressModel;

export { addressFactory };
