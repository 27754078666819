import _ from 'lodash-es';
import moment from 'moment';
import { LYFT_VEHICLE_ID, UBER_VEHICLE_ID } from '~/constants';
import { isNetworkRole } from './helperFunctions';
import type { CUSTOM_FIELD_TYPES, RideCustomFields } from '~/types';

/**
 * ride request object creation for lyft on demand ride request only
 * @param {object} bookingData - booking data from booking form
 * @param {string} userRole - HO, HNM, HA, CM, etc
 * @return {object} send back submission data for laravel on deamnd endpoint
 */

export const mapTempLyftOnDemandBooking = (bookingData, userRole) => {
  const submittedData: Partial<BookingDataStore> = {
    hospitalId: bookingData.hospitalId,
    filterby: 'time',
    confirm_booking: '0',
    filterOrder: 'asc',
    vehicleId: '',
    localDefaultTimezone: '',
    rideType: 'ondemand',
    benbotRequestId: '0',
    purpose: '',
    currentMode: '',
    estimatedTimeSavings: '',
    dateOfBirth: bookingData.dateOfBirth,
    pickupLatitude: bookingData.pickupLatitude,
    pickupLongitude: bookingData.pickupLongitude,
    pickupZipcode: bookingData.pickupZipcode,
    dropoffLongitude: bookingData.dropoffLongitude,
    dropoffLatitude: bookingData.dropoffLatitude,
    dropoffZipcode: bookingData.dropoffZipcode,
    patientName: bookingData.firstName,
    patientLastName: bookingData.lastName,
    internalNotes: bookingData.internalNotes,
    phoneNo: bookingData.phone,
    phone2: bookingData.phone2,
    medicalId: bookingData.medicalId,
    pickupAddress: bookingData.pickupAddress,
    dropoffAddress: bookingData.dropoffAddress,
    apptTime: bookingData.apptTime,
    // time_ends: bookingData.apptEnds,
    transport: bookingData.transportType,
    additionalNotes: bookingData.additionalNotes,
    payWithCard: bookingData.payWithCard,
    selectedDate: bookingData.selectedDate,
    pastRide: bookingData.pastRide || false,
    additionalPassengers: bookingData.additionalPassengers,
    pickupVenueId: _.get(bookingData, 'pickupVenueId', null),
    dropoffVenueId: _.get(bookingData, 'dropoffVenueId', null),
    pickupProviderId: _.get(bookingData, 'pickupProviderId', 0),
    dropoffProviderId: _.get(bookingData, 'dropoffProviderId', 0),
    bookingType: 'fromcare',
    rideCustomFields: bookingData.rideCustomFields,
    timeCustomFields: bookingData.timeCustomFields,
    patientCustomFields: bookingData.patientCustomFields,
    compliance: bookingData.compliance,
    healthPlanId: bookingData.healthPlanId,
    healthSubPlanId: bookingData.healthSubPlanId
  };

  if (_.has(bookingData, 'treatmentId')) {
    submittedData.treatmentId = bookingData.treatmentId;
  }

  if (!_.isEmpty(_.get(bookingData, 'assistanceNeeds', {}))) {
    submittedData.assistanceNeeds = bookingData.assistanceNeeds;
  }

  if (isNetworkRole(userRole)) {
    submittedData.hospitalId = bookingData.hospitalId;
  }

  return submittedData;
};

/**
 * ride request object creation for all other scenarios
 * (non on demand lyft, nemt, single ride, round trip and multiple leg)
 * @param {object} bookingData - booking data from booking form
 * @param {string} userRole - HO, HNM, HA, CM, etc
 * @return {object} send back submission data for lumen endpoint
 */
export const mapMultiLegBooking = (bookingData, userRole) => {
  const submittedData: Partial<BookingDataStore> = {
    hospitalId: bookingData.hospitalId,
    filterby: 'time',
    confirmBooking: '0',
    filterOrder: 'asc',
    vehicleId: '',
    localDefaultTimezone: '',
    rideType: 'scheduled',
    benbotRequestId: '0',
    purpose: '',
    currentMode: '',
    estimatedTimeSavings: '',
    pastRide: bookingData.pastRide,
    additionalPassengers: bookingData.additionalPassengers,
    dateOfBirth: bookingData.dateOfBirth,
    patientName: bookingData.firstName,
    patientLastName: bookingData.lastName,
    internalNotes: bookingData.internalNotes,
    phoneNo: bookingData.phone,
    phone2: bookingData.phone2,
    medicalId: bookingData.medicalId,
    bookingType: bookingData.bookingType,
    transport: bookingData.transportType,
    additionalNotes: bookingData.additionalNotes,
    healthPlanId: bookingData.healthPlanId,
    healthSubPlanId: bookingData.healthSubPlanId,
    transportSubType: bookingData.transportSubType
  };

  if (_.has(bookingData, 'payWithCard')) {
    submittedData.payWithCard = bookingData.payWithCard;
  }

  if (_.has(bookingData, 'treatmentId')) {
    submittedData.treatmentId = bookingData.treatmentId;
  }

  submittedData.patientCustomFields = bookingData.patientCustomFields;
  submittedData.compliance = bookingData.compliance;

  if (isNetworkRole(userRole)) {
    submittedData.hospitalId = bookingData.hospitalId;
  }

  return submittedData;
};

export const mapPublicBooking = (bookingData, userRole) => {
  const submittedData: Partial<BookingDataStore> = {
    hospitalId: bookingData.hospitalId,
    filterby: 'time',
    confirmBooking: '0',
    filterOrder: 'asc',
    vehicleId: '',
    localDefaultTimezone: '',
    rideType: 'scheduled',
    benbotRequestId: '0',
    purpose: '',
    currentMode: '',
    mode: bookingData.transportMode,
    transitMode: _.get(bookingData, 'publicTransitPreferences', []).map(tp =>
      tp.toLowerCase()
    ),
    transitRoutingPreference: _.get(bookingData, 'publicTransitRoutePreferences', []).map(
      trp => trp.toLowerCase()
    ),
    estimatedTimeSavings: '',
    dateOfBirth: bookingData.dateOfBirth,
    patientName: bookingData.firstName,
    patientLastName: bookingData.lastName,
    internalNotes: bookingData.internalNotes,
    phoneNo: bookingData.phone,
    phone2: bookingData.phone2,
    medicalId: bookingData.medicalId,
    bookingType: bookingData.bookingType,
    transport: bookingData.transportType,
    additionalNotes: bookingData.additionalNotes,
    healthPlanId: bookingData.healthPlanId,
    healthSubPlanId: bookingData.healthSubPlanId,
    transportSubType: bookingData.transportSubType,
    pastRide: bookingData.pastRide,
    additionalPassengers: bookingData.additionalPassengers || []
  };

  if (_.has(bookingData, 'payWithCard')) {
    submittedData.payWithCard = bookingData.payWithCard;
  }

  if (_.has(bookingData, 'treatmentId')) {
    submittedData.treatmentId = bookingData.treatmentId;
  }

  submittedData.patientCustomFields = bookingData.patientCustomFields;
  submittedData.compliance = bookingData.compliance;

  if (isNetworkRole(userRole)) {
    submittedData.hospitalId = bookingData.hospitalId;
  }

  return submittedData;
};

/**
 * format appointment data
 * @param {object} apptData - repeat appointment data
 * @return {object} formatted appointment data
 */
export const repeatApptBookingHelper = apptData => {
  return {
    repeatFrequency: apptData.dateRepeat,
    repeatInterval: apptData.interval,
    endsOn: 'date',
    repeatEndsOnCountInput: apptData.occurrences,
    repeatOn: apptData.days,
    recurringSummary: apptData.repeatApptText,
    repeatEndsOnUntilInput: apptData.selectedDate,
    repeatAppointment: 'repeat'
  };
};

/**
 * create submission for lyft on demand ride submission
 * @param {object} ride - ride object
 * @param {object} passenger - passenger data
 * @return {object} lyft on demand submission object
 */
export const mapTempLyftOnDemandSubmit = (ride, passenger) => {
  const { submittedData } = ride;

  const confirmationSubmit: Partial<BookingDataStore> = {
    hospitalId: submittedData.hospitalId,
    filterby: submittedData.filterby,
    confirm_booking: submittedData.confirm_booking,
    filterOrder: submittedData.filterOrder,
    vehicleId: submittedData.vehicleId,
    localDefaultTimezone: submittedData.localDefaultTimezone,
    rideType: submittedData.rideType,
    driver: submittedData.driver,
    benbotRequestId: submittedData.benbotRequestId,
    dateOfBirth: submittedData.dateOfBirth,
    estimatedTimeSavings: submittedData.estimatedTimeSavings,
    medicalId: ride.medicalId,
    phoneNo: ride.phone,
    phone2: submittedData.phone2,
    patientName: submittedData.patientName,
    patientLastName: submittedData.patientLastName,
    internalNotes: submittedData.internalNotes,
    additionalNotes: ride.additionalNotes,
    bookingType: ride.bookingType,
    apptTime: submittedData.apptTime,
    pastRide: submittedData.pastRide || false,
    additionalPassengers: submittedData.additionalPassengers || [],
    selectedDate: submittedData.datepick,
    pickupAddress: ride.pickupLoc,
    pickupLatitude: ride.pickupLatitude,
    pickupLongitude: ride.pickupLongitude,
    pickupZipcode: '',
    dropoffAddress: ride.dropoffLoc,
    dropoffLatitude: ride.dropoffLatitude,
    dropoffLongitude: ride.dropoffLongitude,
    dropoffZipcode: '',
    transport: submittedData.transport,
    estimatedCost: ride.cost,
    duration: ride.duration,
    distance: ride.distance,
    eta: ride.eta,
    passengerId: _.get(passenger, 'id', ''),
    passengerTz: _.get(passenger, 'timezone_format', ''),
    healthPlanId: _.get(passenger, 'health_plan_id', ''),
    healthSubPlanId: _.get(passenger, 'health_sub_plan_id', ''),
    localStartTime: ride.pickupTimeStamp,
    localStartFormatted: ride.pickupTime,
    rideUTC: ride.rideTimeUTC,
    rideTimeUTC: ride.rideTimeUTC,
    localBlegPickupTime: ride.blegPickupTimeStamp || 0,
    localBlegFormatted: ride.blegPickupTime || '',
    payWithCard: submittedData.payWithCard,
    pickupVenueId: submittedData.pickupVenueId,
    dropoffVenueId: submittedData.dropoffVenueId,
    pickupProviderId: _.get(submittedData, 'pickupProviderId', 0),
    dropoffProviderId: _.get(submittedData, 'dropoffProviderId', 0)
  };
  if (_.has(submittedData, 'lyftRideType')) {
    confirmationSubmit.lyftRideType = submittedData.lyftRideType;
  }
  if (_.has(submittedData, 'rideCustomFields')) {
    confirmationSubmit.rideCustomFields = submittedData.rideCustomFields;
  }
  if (_.has(submittedData, 'timeCustomFields')) {
    confirmationSubmit.timeCustomFields = submittedData.timeCustomFields;
  }
  if (_.has(submittedData, 'patientCustomFields')) {
    confirmationSubmit.patientCustomFields = submittedData.patientCustomFields;
  }
  if (_.has(submittedData, 'compliance')) {
    confirmationSubmit.compliance = submittedData.compliance;
  }
  if (_.has(submittedData, 'originalRideId')) {
    confirmationSubmit.originalRideId = submittedData.originalRideId;
  }
  if (_.has(submittedData, 'assistanceNeeds')) {
    confirmationSubmit.assistanceNeeds = submittedData.assistanceNeeds;
  }
  return confirmationSubmit;
};

/**
 * create submission for rideshare ondemand ride booking
 * @param {object} ride - ride data
 * @return {object} return rideshare ondemand submission object
 */
export const mapRideShareOnDemandSubmit = ride => {
  const { submittedData } = ride;
  const confirmationSubmit = submittedData;

  return confirmationSubmit;
};

/**
 * submit trip for multi leg ride booking
 * @param {object} trip - trip data
 * @return {object} return trip
 */
export const mapMultiLegSubmit = trip => {
  const { rides, submittedData = {} } = trip;

  submittedData.rides ??= [];

  submittedData.rides = submittedData?.rides.map((ride, key) => {
    /**
     * TODO:UBER
     */
    if (
      ride.transportType === LYFT_VEHICLE_ID ||
      ride.transportType === UBER_VEHICLE_ID
    ) {
      const lyftProps = {
        eta: rides[key]?.eta,
        duration: rides[key]?.duration,
        distance: rides[key]?.distance,
        estimatedCost: rides[key]?.cost,
        pickupTimeStamp: rides[key]?.pickupTimeStamp,
        pickupTime: rides[key]?.pickupTime,
        blegPickupTimeStamp: rides[key]?.blegPickupTimeStamp,
        rideTimeUTC: rides[key]?.rideTimeUTC,
        ...(rides[key].product_id && { product_id: rides[key].product_id }),
        ...(rides[key].cost_token && { cost_token: rides[key].cost_token }),
        ...(rides[key].fare_id && { fare_id: rides[key].fare_id }),
        ...(rides[key].run_id && { run_id: rides[key].run_id })
      };

      ride = Object.assign(ride, lyftProps);
    }
    return ride;
  });

  const confirmationSubmit = submittedData;

  if (confirmationSubmit.rideType === 'benbot') {
    confirmationSubmit.startBenbot = 1;
  }
  return confirmationSubmit;
};

/**
 * function for displaying transportation options page
 * @param {object} props - props
 * @param {object} prevProps - previous props
 * @param {array} preAuthReducers - array of reducers applicable to transportation page
 * @param {boolean} validConfirmationCardData - do we have the property confirmationCardData and is it valid
 * @return {boolean} do we show transportation page?
 */
export const showTransportationPage = (
  props,
  prevProps,
  preAuthReducers,
  validConfirmationCardData = false
) => {
  const reducer = _.get(props, 'error.type', '');
  if (preAuthReducers.indexOf(reducer) > -1 && props.timestamp !== prevProps.timestamp) {
    return true;
  }
  if (validConfirmationCardData && props.timestamp !== prevProps.timestamp) {
    return true;
  }
  return false;
};

/**
 * cases for showing authorizatin/benefits page
 * for booking confirmation process and vehicle reassign process
 * @param {object} props - props object
 * @param {object} prevProps - previous props
 * @param {array} allowedStatus - status allowed (allow, alert, block, stop)
 * @param {string} preAuthReducer - reducer for preatuh
 * @return {boolean} - return true if benefts page, false if not
 */
export const showBenefitsPage = (props, prevProps, allowedStatus, preAuthReducer) => {
  // auth for AVAILABLE_CARDS
  const status = _.get(props, 'auth.status', '');
  const prevStatus = _.get(prevProps, 'auth.status', '');

  if (status !== prevStatus && allowedStatus.indexOf(status) > -1) {
    return true;
  }

  // auth for PREAUTHORIZE_RIDES
  const nemtStatus = _.get(props, 'auth.nemtAuthStatus', '');

  if (props.timestamp !== prevProps.timestamp && allowedStatus.indexOf(nemtStatus) > -1) {
    return true;
  }

  // authorization page, collection is an array of authorization for multi leg trips
  const collection = _.get(props, 'auth.collection', []);
  const prevCollection = _.get(prevProps, 'auth.collection', []);

  if (
    _.isEmpty(prevCollection) &&
    !_.isEqual(collection, prevCollection) &&
    props.timestamp !== prevProps.timestamp
  ) {
    return true;
  }

  if (
    _.get(props, 'error.type', '') === preAuthReducer &&
    props.timestamp !== prevProps.timestamp
  ) {
    return true;
  }

  // lyft authorization issues
  if (
    _.get(props, 'auth.bookingAlert', false) === true &&
    props.timestamp !== prevProps.timestamp
  ) {
    return true;
  }

  return false;
};

/**
 * function for displaying confirmation page
 * @param {object} props - props
 * @param {object} prevProps - previous props
 * @param {array} preAuthReducers - array of reducers applicable to transportation page
 * @return {boolean} do we show transportation page?
 */
export const showConfirmationPage = (props, prevProps, preAuthReducers) => {
  const reducer = _.get(props, 'error.type', '');

  if (preAuthReducers.indexOf(reducer) > -1 && props.timestamp !== prevProps.timestamp) {
    return true;
  }

  const rides = _.get(props, 'trip.rides', []);
  if (rides.length > 0 && props.timestamp !== prevProps.timestamp) {
    return true;
  }

  return false;
};

/**
 * validate data
 * @param {object} confirmationCardData - could be object or array
 * @return {boolean} - returns true or false
 */
export const validateConfirmationData = confirmationCardData => {
  // object, laravel app
  if (_.has(confirmationCardData, 'rides')) {
    return true;
  }
  if (Array.isArray(confirmationCardData) && confirmationCardData.length > 0) {
    return true;
  }
  return false;
};

/**
 * Maps payload for for ride share flex rides
 * @param {object} rideDetails
 */
export const mapRideBookingPayload = rideDetails => {
  const { lyft_flex_ride: lyftFlexRide, uber_flex_ride: uberFlexRide } = rideDetails;
  const isFlexRide = Boolean(lyftFlexRide || uberFlexRide);
  return {
    hospitalId: rideDetails.hospitalId,
    filterby: 'time',
    confirmBooking: '0',
    filterOrder: 'asc',
    vehicleId: rideDetails.vehicleId,
    localDefaultTimezone: '',
    // rideType: rideDetails.rideType,
    rideType: isFlexRide ? 'flex' : 'ondemand',
    benbotRequestId: '0',
    purpose: '',
    currentMode: '',
    estimatedTimeSavings: '',
    additionalPassengers: rideDetails.additionalPassengers,
    dateOfBirth: rideDetails.dateOfBirth
      ? moment(rideDetails.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')
      : '',
    patientName: rideDetails.passengerFirstname,
    patientLastName: rideDetails.passengerLastname,
    internalNotes: rideDetails.internalNotes,
    phoneNo: rideDetails.pmobileNo,
    phone2: null,
    medicalId: rideDetails.medicalId,
    bookingType: 'ondemand', // ?
    transport: rideDetails.reqVehicleType, // ?
    additionalNotes: rideDetails.additionalNotes,
    healthPlanId: rideDetails.health_plan_id,
    healthSubPlanId: rideDetails.health_sub_plan_id,
    transportSubType: 0, // ?
    rides: [
      {
        pickupAddress: rideDetails.fromAddress,
        pickupLatitude: rideDetails.fromLatitude,
        pickupLongitude: rideDetails.fromLongitude,
        pickupZipcode: rideDetails.fromZipcode,
        dropoffAddress: rideDetails.toAddress,
        dropoffLatitude: rideDetails.toLatitude,
        dropoffLongitude: rideDetails.toLongitude,
        dropoffZipcode: rideDetails.toZipcode,
        transportType: rideDetails?.reqVehicleType,
        additionalNotes: '',
        transportTypeName: rideDetails.vehicleTypeName,
        apptTime: 'Will Call', // rideDetails.appointmentTime === null in ride details
        selectedDate: moment().format('MM/DD/YYYY'),
        transportSubType: 0,
        transportSubTypeName: '',
        assistanceNeeds: {},
        additionalPassengers: rideDetails.additionalPassengers || [],
        pickupProviderId: rideDetails.dropoff_provider_id || 0,
        dropoffProviderId: rideDetails.pickup_provider_id || 0,
        bookingType: 'fromcare'
      }
    ]
  };
};

/**
 * Creates an object with empty/unset values for all
 * address fields
 * @param type
 * @returns
 */
export const generateCleanAddress = (type: 'pickup' | 'dropoff') => {
  const cleanAddress = {
    address: '',
    addressName: '',
    providerId: null,
    providerName: '',
    providerPhone: '',
    providerNpi: null,
    facilityName: '',
    additionalNotes: '',
    memberSavedAddressId: null,
    latitude: null,
    longitude: null,
    zipcode: ''
  };

  const cleanedEntries = Object.entries(cleanAddress).map(([key, val]) => {
    if (key === 'memberSavedAddressId') {
      const altType = type === 'pickup' ? 'from' : 'to';
      key = altType + key[0].toUpperCase() + key.substring(1);
    } else {
      key = type + key[0].toUpperCase() + key.substring(1);
    }

    return [key, val];
  });

  return Object.fromEntries(cleanedEntries);
};

/**
 * Indicates if the current ride in the RBF is a public
 * ride or not. Returns false if called outside of RBF.
 * @returns
 */
export const isPublicRide = () => {
  const state = global.store.getState();

  const { bookingData, rideDetails, scheduledRidesRedux } = state;

  const isPublicRoutelessRides = scheduledRidesRedux?.auth?.isPublicRoutelessRides;

  if (isPublicRoutelessRides) {
    return false;
  }

  if (bookingData?.transportMode === 'Public') {
    return true;
  }

  if (bookingData?.mode === 'Public' && rideDetails?.data?.ride?.publicData) {
    return true;
  }

  return false;
};

export const getBookingTypeLabel = bookingType => {
  switch (bookingType) {
    case 'fromcare':
      return 'Depart At';
    case 'tocare':
      return 'Arrive By';
    case 'ondemand':
      return 'Depart Now';
    default:
      return '';
  }
};

/**
 * Formats custom fields from the ride report ride object based on the specified type.
 */
export const formatCustomFieldsFromRideReport = (
  customFields: RideCustomFields,
  type: CUSTOM_FIELD_TYPES
) => {
  if (!customFields) {
    return null;
  }

  const formattedCustomFields = {};

  for (const [customFieldId, customField] of Object.entries(customFields)) {
    if (customField.field.related_to === type) {
      formattedCustomFields[customFieldId] = customField?.value?.field_value;
    }
  }

  return formattedCustomFields;
};

/**
 *  mapping a scheduled ride's data into the bookingData redux store's format
 *  (the keys are slightly different between the bookingData redux store and a scheduled ride)
 *
 * This returns only the data needed for rebooking a ride (no more, no less)
 */
export const getReBookingDataFromAPreviousRide = ride => ({
  // main ride values
  hospitalId: ride.hospitalId,
  treatmentId: ride.treatment_id,
  treatmentName: ride.treatment_name,
  patientCustomFields:
    ride.patientCustomFieldValues ??
    formatCustomFieldsFromRideReport(ride?.customFields, 'patient'),
  compliance: ride.additional_columns?.[0]?.additionalFields ?? ride?.complianceValues,
  transportMode: ride.mode,
  transportType: ride.reqVehicleType,
  transportSubType: ride.ride_options?.vehicle_sub_type,
  assistanceNeeds: ride.ride_options?.assistance_needs,
  comments: ride.additionalNotes,
  pickupAddress: ride.fromAddress,
  dropoffAddress: ride.toAddress,
  pickupLatitude: ride.fromLatitude,
  pickupLongitude: ride.fromLongitude,
  dropoffLatitude: ride.toLatitude,
  dropoffLongitude: ride.toLongitude,
  rideCustomFields:
    ride.rideCustomFieldValues ??
    formatCustomFieldsFromRideReport(ride?.customFields, 'ride'),
  // pickup approved provider values
  pickupProviderId: ride.pickupProviderId,
  pickupProviderName: ride.pickupProviderName,
  pickupProviderAddr: ride.pickupProviderAddress,
  pickupProviderPhone: ride.pickupProviderPhone,
  pickupProviderNpi: ride.pickupProviderNpi,
  pickupProviderNotFound: !!ride.pnfPickupProviderAddress,
  pickupFacilityName: ride.pickupFacilityName,
  pickupAdditionalNotes: ride.pnfPickupAdditionalNotes,
  // dropoff approved provider values
  dropoffProviderId: ride.dropoffProviderId,
  dropoffProviderName: ride.dropoffProviderName,
  dropoffProviderAddr: ride.dropoffProviderAddress,
  dropoffProviderPhone: ride.dropoffProviderPhone,
  dropoffProviderNpi: ride.dropoffProviderNpi,
  dropoffProviderNotFound: !!ride.pnfDropoffProviderAddress,
  dropoffFacilityName: ride.dropoffFacilityName,
  dropoffAdditionalNotes: ride.pnfDropoffAdditionalNotes
});
