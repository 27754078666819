import type { AppThunk } from '~/Modules';
import { addressFactory, rideFactory } from '~/models';
import { addReturnRide } from '../RideBooking.slice';

/** A thunk used to add a return ride leg on the current ride
 * in the RBF. This is necessary because we need to access the
 * memberProfile store to get the member's notes and don't have
 * access to that inside of the rideBooking slice. */
export const addReturnRideThunk = (): AppThunk => {
  return function (dispatch, getState) {
    const rideOne = getState().rideBooking.rides[0];
    const memberNotes = getState().memberProfile.formData?.personalInfo?.otherDetails;

    const rideTwo = rideFactory({
      ...rideOne.toJSON(),
      departAddress: addressFactory(rideOne.arriveAddress.toJSON()),
      arriveAddress: addressFactory(rideOne.departAddress.toJSON()),
      notes: memberNotes || '',
      type: 'departAt',
      time: undefined,
      distance: undefined
    });

    dispatch(addReturnRide(rideTwo));
  };
};
