import React from 'react';
import { Card, Icon, Input, TOKENS } from '@SRHealth/frontend-lib';
import type { RideProps } from '~/models';
import { NOOP } from '~/utilities/helperFunctions';
import RidesSummarySectionHeader from './RidesSummarySectionHeader';

interface RidesSummaryProps {
  rides: RideProps[];
  onEditRide: (rideIndex: number) => Promise<void>;
}

export default function RidesSummary({ rides, onEditRide }: RidesSummaryProps) {
  return (
    <div className="flex flex-col" style={{ paddingTop: '16px', gap: '24px' }}>
      {rides.map((ride, rideIndex) => {
        const hours = Math.floor(ride.duration! / 60) || 0;
        const minutes = Math.floor(ride.duration! % 60);

        const duration = `${hours}:${minutes} ${hours ? 'hours' : 'minutes'}`;

        const rideLabel = (
          <div className="flex flex-row items-center gap-[8px]">
            <Icon type="TransportCar" className="w-[32px] h-[32px] fill-current" />
            <div className="flex-1">{rideIndex ? 'Return Ride' : 'Initial Ride'}</div>
            <div className={`text-sm-italic text-${TOKENS.FONT.NEUTRAL}`}>
              {rideIndex ? '' : 'Editing the initial ride will remove the return ride'}
            </div>
          </div>
        );
        return (
          <Card
            label={rideLabel}
            editable={false}
            key={rideIndex}
            onEdit={() => onEditRide(rideIndex)}
            onSave={NOOP}
          >
            <div className="flex flex-col gap-[16px]">
              <div
                data-testid={`row-1-ride-summary-${rideIndex}`}
                className="flex flex-col gap-[8px]"
              >
                <RidesSummarySectionHeader label="Locations" icon="Location" />
                <div className="reset-div grid gap-x-[32px] gap-y-[16px] overflow-x-clip grid-cols-3">
                  <Card.Content label="Pick Up">
                    <div className="text-base">{ride.departAddress.value}</div>
                  </Card.Content>
                  <Card.Content label="Drop Off">
                    <div className="text-base">{ride.arriveAddress.value}</div>
                  </Card.Content>
                  <Card.Content label="Distance">
                    <div className="text-base">{ride.distance} Miles</div>
                  </Card.Content>
                </div>
              </div>

              <div
                data-testid={`row-2-ride-summary-${rideIndex}`}
                className="flex flex-col gap-[8px]"
              >
                <RidesSummarySectionHeader label="Times" icon="Time" />
                <div className="reset-div grid gap-x-[32px] gap-y-[16px] overflow-x-clip grid-cols-3">
                  <Card.Content label="Pick Up Time">
                    <div className="text-base">
                      {ride.type === 'departAt' && ride.time}
                    </div>
                  </Card.Content>
                  <Card.Content label="Appointment Time">
                    {!rideIndex && (
                      <div className="text-base">
                        {ride.type === 'arriveBy' && ride.time}
                      </div>
                    )}
                  </Card.Content>
                  <Card.Content label="Travel Time">
                    <div className="text-base">{duration}</div>
                  </Card.Content>
                </div>
              </div>

              <div
                data-testid={`row-3-ride-summary-${rideIndex}`}
                className="flex flex-col"
                style={{ gap: '10px' }}
              >
                <RidesSummarySectionHeader label="Notes" icon="EditText" />
                <div className="reset-div grid gap-x-[32px] gap-y-[16px] overflow-x-clip grid-cols-1">
                  <Card.Content label="Ride Note">
                    <div
                      className="text-base"
                      style={{ fontStyle: ride.notes ? 'normal' : 'italic' }}
                    >
                      {ride.notes || 'None'}
                    </div>
                  </Card.Content>
                </div>
              </div>
            </div>
          </Card>
        );
      })}

      <Card id="recurring-trip" label="Recurring Trip" border={false}>
        <Input
          type="toggle"
          inputId={0}
          name="recurring-trip-toggle"
          options={[{ label: 'Make Recurring', value: false }]}
          size="md"
          value={false}
          error={false}
          onChange={NOOP}
        />
      </Card>
    </div>
  );
}
