import { Is, createModelFactory, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';
import { isStringOrNumber } from '~/utilities/guards';

export type AddressApprovedProvider = {
  type: 'approved-provider';
  value: string;
  id: number;
  name: string;
  npi: number | string;
  phone: string;
  latitude: number;
  longitude: number;
  zip: number | string;
};

export type AddressApprovedProviderModel = ModelType<AddressApprovedProvider>;

export type AddressApprovedProviderPropertyRule =
  PropertyRuleSignature<AddressApprovedProvider>;

export const ADDRESS_APPROVED_PROVIDER_DEFAULT = (): AddressApprovedProvider => ({
  type: 'approved-provider',
  value: '',
  id: 0,
  name: '',
  npi: '',
  phone: '',
  latitude: 0,
  longitude: 0,
  zip: ''
});

export const addressApprovedProviderFactory =
  createModelFactory<AddressApprovedProviderModel>(ADDRESS_APPROVED_PROVIDER_DEFAULT(), {
    properties: [
      ['is-address-type', 'type', v => v === 'saved'],
      ['is-string', 'value', Is.String.strict],
      [
        'is-not-empty',
        'value',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Address cannot be empty.');

          return true;
        }
      ],
      ['is-number', 'id', Is.Number.strict],
      [
        'is-not-empty',
        'id',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Id cannot be empty.');

          return true;
        }
      ],
      ['is-string', 'name', Is.String.strict],
      [
        'is-not-empty',
        'name',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Name cannot be empty.');

          return true;
        }
      ],
      ['is-string', 'npi', Is.String.strict],
      [
        'is-not-empty',
        'npi',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('NPI cannot be empty.');

          return true;
        }
      ],
      ['is-string', 'phone', Is.String.strict],
      [
        'is-not-empty',
        'phone',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Phone cannot be empty.');

          return true;
        }
      ],
      ['is-number', 'latitude', Is.Number.strict],
      [
        'is-not-empty',
        'latitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Latitude cannot be empty.');

          return true;
        }
      ],
      ['is-number', 'longitude', Is.Number.strict],
      [
        'is-not-empty',
        'longitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Longitude cannot be empty.');

          return true;
        }
      ],
      ['is-string-or-number', 'zip', isStringOrNumber.strict],
      [
        'is-not-empty',
        'zip',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Zipcode cannot be empty.');

          return true;
        }
      ]
    ],
    model: []
  });
