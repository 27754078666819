/**
 * This module exports the configuration for the Analytics service with Mixpanel plugin.
 * It is responsible for initializing Mixpanel with the correct project token and
 * defining the properties that can be tracked with each event.
 *
 * IMPORTANT: This file should not include any sensitive information directly,
 * such as the Mixpanel project token. Such data should be fetched from a secure
 * environment variable or similar.
 *
 * For more information on how to use Mixpanel, please
 * refer to Mixpanel's official documentation at https://developer.mixpanel.com/.
 *
 */
import type { PageData } from 'analytics';
import AnalyticsInit from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

const analytics = AnalyticsInit({
  app: 'care',
  plugins: [mixpanelPlugin({ token: MIXPANEL_TOKEN })]
});

// /* Track a page view */
// analyticsService.page();

// /* Track a custom event */
// analyticsService.track('cartCheckout', {
//   item: 'pink socks',
//   price: 20
// });

// /* Identify a visitor */
// analyticsService.identify('user-id-xyz', {
//   firstName: 'bill',
//   lastName: 'murray'
// });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EventProperties extends Record<string | number | symbol, unknown> {}

export const analyticsService = {
  trackEvent: (event: string, eventProperties: EventProperties): Promise<void> => {
    return MIXPANEL_TOKEN ? analytics.track(event, eventProperties) : Promise.resolve();
  },
  trackPage: (pageData: PageData): Promise<void> => {
    return MIXPANEL_TOKEN ? analytics.page(pageData) : Promise.resolve();
  },
  identify: (userId: string, userTraits): Promise<void> => {
    return MIXPANEL_TOKEN ? analytics.identify(userId, userTraits) : Promise.resolve();
  }
};
