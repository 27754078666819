/**
 * The Ride Booking page makes use of the following Redux Modules:
 *
 * user
 * patients
 * memberProfile
 * rideBooking
 */
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { useParams } from 'react-router-dom';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import { initializeRbfNewThunk, reset } from '~/Modules/rideBooking';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import SubHeader from './Components/SubHeader';
import Notes from './Components/Notes';
import { PassengerInfo } from './Components/PassengerInfo';
import { FundingSource } from './Components/FundingSource';
import { DateSection } from './Components/Date';
import { Rides } from './Components/Rides';

const inlineCSS = `
  #rbf {
    scroll-behavior: smooth;
    scrollbar-width: thin;

    [data-testid^="section-"][data-testid$="-header"] {
      position: sticky;
      top: 0;
      z-index: 11;
    }

    [data-testid^="section-"][data-testid$="-content"] {
      position: relative;
    }

    [data-testid^="card"] {
      max-width: 960px;
    }
  }
`;

export type RideBookingMode = 'new' | 'edit';

interface RideBookingPropsBase {
  mode: RideBookingMode;
}

interface NewRideBookingProps extends RideBookingPropsBase {
  mode: 'new';
}

interface EditRideBookingProps extends RideBookingPropsBase {
  mode: 'edit';
  rideId: number;
}

type RideBookingURLParams = {
  id: string;
};

export const RideBooking = ({ mode }: NewRideBookingProps | EditRideBookingProps) => {
  const memberProfileStore = useAppSelector(store => store.memberProfile);
  const activeSection = useAppSelector(store => store.rideBooking.meta.activeSection);
  const isInitialized = useAppSelector(store => store.rideBooking.meta.isInitialized);

  const dispatch = useAppDispatch();
  const { id } = useParams<RideBookingURLParams>();

  const firstName = memberProfileStore.formData.personalInfo.firstName;
  const lastName = memberProfileStore.formData.personalInfo.lastName;

  // Reset the store when the component dismounts
  useEffect(() => () => void dispatch(reset()), []);

  // Fetch initial RBF Data
  useEffect(() => {
    if (mode === 'new' && id) dispatch(initializeRbfNewThunk(id));
  }, [id]);

  return (
    <PageFrame>
      <style>{inlineCSS}</style>
      <div id="rbf" data-testid="rbf" className="flex flex-row w-full">
        {!isInitialized ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="flex flex-col flex-1 h-full">
              <SubHeader mode={mode} id={id} firstName={firstName} lastName={lastName} />

              <div className="overflow-auto flex-1">
                {activeSection >= PassengerInfo.sectionIndex && <PassengerInfo />}
                {activeSection >= FundingSource.sectionIndex && <FundingSource />}
                {activeSection >= DateSection.sectionIndex && <DateSection />}
                {activeSection >= Rides.sectionIndex && <Rides />}
              </div>
            </div>
            <Notes />
          </>
        )}
      </div>
    </PageFrame>
  );
};
