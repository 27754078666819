import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { getHospitalCoords, isNetworkRole } from '~/utilities/helperFunctions';

const selectUserRole = (state: AppStore) => state.user.userData.role;
const selectHospitalId = (state: AppStore) => state.rideBooking.fundingSource.facilityId;
const selectHospitalData = (state: AppStore) => state.user.hospitalData;

/** Select the hospital coordinates based on the user's role and the hospital ID. */
export const selectHospitalCoords = createSelector(
  [selectUserRole, selectHospitalId, selectHospitalData],
  (role, hospitalId, hospitalData) => {
    const isHospitalNetworkRole = isNetworkRole(role);
    return getHospitalCoords(hospitalId, hospitalData, isHospitalNetworkRole);
  }
);
