import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { isMemberEligible } from '~/utilities/memberProfile';
import { selectEligibilityDates } from './selectEligibilityDates';

const selectIsEligible = (state: AppStore) =>
  state.memberProfile.formData.personalInfo.isEligible;

export const selectEligibility = createSelector(
  [selectIsEligible, selectEligibilityDates],
  (isEligible, { endDate }) => {
    return isMemberEligible(isEligible, endDate);
  }
);
