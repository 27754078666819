import React, { useEffect, useReducer, useRef } from 'react';
import { Button, Input, Is, Section } from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { selectEligibility, selectEligibilityDates } from '~/Modules/memberProfile';
import {
  completeSection,
  RIDE_SECTION_ORDER,
  selectIsActive,
  selectIsBlocked
} from '~/Modules/rideBooking';
import useAnalytics from '~/hooks/useAnalytics';
import useModelSelector from '~/hooks/useModelSelector';
import Loader from '~/Pages/RideBooking/Loader';
import { NOOP } from '~/utilities/helperFunctions';

import {
  getFacilities,
  getHospitalCustomFields,
  getRideBenefits,
  getTreatments,
  scrollToFooter
} from './FundingSource.utils';
import HealthPlan from './subcomponents/HealthPlan';
import TripQuestions from './subcomponents/TripQuestions';
import Summary from './subcomponents/Summary';

type FundingSourceState = {
  time: number;
  totalTime: number;
  isFocused: boolean;
  isLoading: boolean;
};

type ReducerAction =
  | { type: 'focusin' }
  | { type: 'focusout' }
  | { type: 'resetTime' }
  | { type: 'loading'; isLoading: boolean };

const createInitialState = (): FundingSourceState => ({
  time: 0,
  totalTime: 0,
  isFocused: false,
  isLoading: false
});

const reducer: React.Reducer<FundingSourceState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'focusin':
      return state.isFocused ? state : { ...state, time: Date.now(), isFocused: true };
    case 'focusout':
      return !state.isFocused
        ? state
        : {
          ...state,
          isFocused: false,
          totalTime: state.totalTime + (Date.now() - state.time)
        };
    case 'resetTime':
      return { ...state, time: 0, totalTime: 0 };
    case 'loading':
      return { ...state, isLoading: action.isLoading };
  }
};

const FundingSource = () => {
  const appDispatch = useAppDispatch();
  const { trackEvent } = useAnalytics();

  const footerRef = useRef<HTMLDivElement>(null);
  const { model } = useModelSelector(state => state.rideBooking.fundingSource);
  const [state, localDispatch] = useReducer(
    reducer,
    createInitialState(),
    createInitialState
  );

  /** Retrieve data from the Redux Store. Note for Firefox users:
   * The React dev tools currently have a bug preventing component lookup
   * if you are reading from the Redux store more than once per component.
   * The fix is released for Chrome but has not been for Firefox.
   * See [Link](https://github.com/facebook/react/issues/28960) */
  const userStore = useAppSelector(state => state.user);
  const patientDetails = useAppSelector(state => state.patients.patientDetails);
  const memberProfileStore = useAppSelector(state => state.memberProfile);
  const isActive = useAppSelector(state => selectIsActive(state, 'funding-source'));

  /** Determine eligibility status and pass on to <HealthPlan/> component. */
  const isEligible = useAppSelector(s => selectEligibility(s));
  const eligibilityDates = useAppSelector(s => selectEligibilityDates(s));

  /** Retrieve specific data for the components and format it. */
  const facilities = getFacilities(userStore);
  const treatments = getTreatments(patientDetails);
  const benefitsRecord = getRideBenefits(userStore, memberProfileStore);
  const hospitalCustomFields = getHospitalCustomFields(userStore, model.facilityId);
  const activeTreatment = treatments?.find(t => t.id === model.treatmentId);

  const [isBlocked, blockType] = useAppSelector(s =>
    selectIsBlocked(s, activeTreatment, benefitsRecord)
  );

  /** Dispatch mixpanel event. */
  const handleStart = () =>
    trackEvent('RBF Funding Source', {
      EditStatus: 'Started',
      BlockType: 'None',
      TimeOnSection: 0
    });

  /** Dispatch mixpanel event and return to Member Profile. */
  const handleCancel = () => {
    trackEvent('RBF Funding Source', {
      EditStatus: 'Abandoned',
      BlockType: blockType ?? 'None',
      TreatmentSelected: activeTreatment?.treatment_name,
      TimeOnSection: state.totalTime + (Date.now() - state.time)
    }).then(() => document.getElementById('to-member-profile')!.click());
  };

  /** Validate and commit any outstanding changes to the model. */
  const handleSave = () => {
    if (!model.isDirty && Object.keys(model.ruleErrors).length) return;

    model
      .commit()
      .then(success => {
        if (!success) return;

        trackEvent('RBF Funding Source', {
          EditStatus: 'Completed',
          BlockType: blockType ?? 'None',
          TreatmentSelected: activeTreatment?.treatment_name,
          TimeOnSection: state.totalTime + (Date.now() - state.time)
        });

        appDispatch(completeSection('funding-source'));
        document
          .querySelector(`[data-testid="section-funding-source"]`)
          ?.scrollIntoView();
      })
      .catch(NOOP);
  };

  /**
   * The event handler attached to the RBF parent element. It detects if the user
   * clicks anywhere inside of the RBF element and then determines if the target is
   * within Funding Source. If it is, it triggers the focusin event. Otherwise it
   * triggers the focusout event.
   *
   * **Note:** This handler is only refreshed when the formRef changes. Logging state
   * will display data at the time that the handler was created. It will potentially be
   * outdated.
   */
  const handleFocus = (e: PointerEvent) => {
    const newTarget = e.target;
    const oldTarget = document.activeElement;
    const fundingSrcElement = document.getElementById('funding-source')!;

    if (newTarget === oldTarget) return;

    if (fundingSrcElement.contains(newTarget as Node)) {
      localDispatch({ type: 'focusin' });
    } else if (fundingSrcElement.contains(oldTarget as Node)) {
      localDispatch({ type: 'focusout' });
    }
  };

  /** Attach the focus event listener to the RBF element so that we can determine
   * when a user is actively editing the section. */
  useEffect(() => {
    const rbfElement = document.querySelector('[data-testid="rbf"]');

    if (!rbfElement) {
      throw Error(
        'The data-testid attribute has been altered or removed on the RideBooking component.'
      );
    }

    rbfElement.addEventListener('pointerdown', handleFocus);
    return () => rbfElement.removeEventListener('pointerdown', handleFocus);
  }, []);

  /** Scroll to the footer if facility has been selected. */
  useEffect(() => {
    if (model.facilityId && state.isFocused) scrollToFooter(footerRef);
  }, [model.facilityId]);

  /** Scroll to the footer if treatment has been selected. */
  useEffect(() => {
    if (model.treatmentId && state.isFocused) scrollToFooter(footerRef);
  }, [model.treatmentId]);

  /** When the element has focus for the first time then send off a Mixpanel
   * event indicating that the user has started editing the section. */
  useEffect(() => {
    if (state.isFocused && state.totalTime === 0) handleStart();
  }, [state.isFocused, state.totalTime]);

  return (
    <Section id="funding-source" icon="Healthplan" label="Funding Source">
      <HealthPlan
        benefitsRecord={benefitsRecord}
        memberProfileStore={memberProfileStore}
        eligbilityDates={eligibilityDates}
        isEligible={isEligible}
      />

      <Loader isLoading={state.isLoading} />
      {isActive ? (
        <>
          {facilities.length > 1 && (
            <div
              className="flex flex-col justify-center items-center m-auto"
              style={{ width: '320px', marginBottom: '-18px' }}
            >
              <Input
                inputId={0}
                type="select"
                name="facility"
                label="Facility"
                placeholder="Select One"
                className="w-full p-[8px]"
                required
                options={facilities}
                value={model.facilityId}
                error={'facilityId' in model.ruleErrors}
                onChange={(_id, v: (typeof model)['facilityId']) => {
                  model.facilityId = v;
                }}
              />
            </div>
          )}

          {!Is.Undefined(model.facilityId) && (
            <TripQuestions
              model={model}
              treatments={treatments}
              complianceFields={userStore.complianceInfo}
              dependentFields={userStore.dependentFields}
              hospitalCustomFields={hospitalCustomFields}
              isBlocked={isBlocked}
              blockType={blockType}
            />
          )}

          <Section.Footer>
            <div ref={footerRef} className="flex flex-row items-center justify-center">
              {isBlocked && <Button alt label="Cancel Booking" onClick={handleCancel} />}
              {blockType !== 'Hard' && (
                <Button
                  label={blockType === 'Soft' ? 'Save & Allow' : 'Save & Continue'}
                  onClick={handleSave}
                />
              )}
            </div>
          </Section.Footer>
        </>
      ) : (
        <Summary
          modelData={model.toJSON()}
          treatments={treatments}
          complianceFields={userStore.complianceInfo}
          dependentFields={userStore.dependentFields}
          hospitalCustomFields={hospitalCustomFields}
        />
      )}
    </Section>
  );
};

FundingSource.sectionIndex = RIDE_SECTION_ORDER['funding-source'];

export { FundingSource };
