import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InputText from '~/Shared/Components/InputText';
import LoadingModal from '~/Shared/Components/LoadingModal';
import Calendar from '~/Shared/Components/Calendar';
import { putMemberProfile, clearData } from '~/Modules/memberProfile/actions';
import moment from 'moment';
import { REGEX_DATE, ELIGIBILITY_API } from '~/constants';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import { Container } from '@material-ui/core';

class Benefits extends Component {
  constructor(props) {
    super(props);
    this.today = moment().toDate();
    this.state = {
      editBenefits: false,
      isDirty: false,
      editedDates: false,
      eligibleStartDate: '',
      eligibleStartDateError: false,
      eligibleStartDateText: 'Start date must before end date.',
      eligibleEndDate: '',
      eligibleEndDateError: false,
      eligibleEndDateText: 'End date must be after start date.',
      success: '',
      error: '',
      showLoader: false,
      showCalendar: false,
      openCalendar: false,
      eligibleSelected: '' // for selecting either eligibleStartDate or eligibleEndDate
    };
  }

  componentDidMount() {
    const state = this.initialize();

    this.setState(state, () => {
      this.props.setEditButton(
        <BenefitsEditButton
          permission={this.props.permission}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    });
  }

  componentDidUpdate(prevProps) {
    const state = {};

    if (
      !_.isEqual(prevProps.benefits, this.props.benefits) &&
      _.isEmpty(prevProps.benefits)
    ) {
      Object.assign(state, this.initialize());

      // We don't have permissions until the member is loaded which is not
      // guaranteed to be true at first render
      this.props.setEditButton(
        <BenefitsEditButton
          permission={this.props.permission}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    }

    if (!_.isEmpty(this.props.success) && _.isEmpty(prevProps.success)) {
      state.success =
        this.props.success.formType === 'benefits' ? this.props.success.message : '';
      state.showLoader = false;
      state.isDirty = false;
      state.editBenefits = false;

      this.props.toggleEnableRedirect(true);
    }

    if (!_.isEmpty(this.props.error) && _.isEmpty(prevProps.error)) {
      state.error = this.props.error;
      state.showLoader = false;

      // if it's an array then there are field validation
      if (Array.isArray(this.props.error)) {
        for (let i = 0; i < this.props.error.length; i++) {
          const errorObj = this.props.error[i];
          const key = Object.keys(errorObj)[0];
          state[`${key}Error`] = true;
          state[`${key}ErrorText`] = errorObj[key];
        }
      }
    }

    if (Object.keys(state).length) {
      this.setState(state);
    }
  }

  componentWillUnmount() {
    this.props.clearData();
    this.handleCancel();
  }

  /**
   * cancel handler for form
   * @return {undefined}
   */
  handleCancel = () => {
    const { eligibleStartDate, eligibleEndDate } = this.props.benefits;
    const startDate = moment(eligibleStartDate).format('MM/DD/YYYY');
    const endDate = moment(eligibleEndDate).format('MM/DD/YYYY');
    const updatedStartDate = this.state.eligibleStartDate;
    const updatedEndDate = this.state.eligibleEndDate;

    if (
      ((startDate !== 'Invalid date' && startDate !== updatedStartDate) ||
        (endDate !== 'Invalid date' && endDate !== updatedEndDate)) &&
      !confirm('Are you sure you want to leave without saving?')
    ) {
      return false;
    }

    const state = this.initialize();

    this.setState(state, () => {
      this.props.clearData();
      this.props.toggleEnableRedirect(true);

      if (this.state.error !== state.error) {
        this.props.setErrorMsg(state.error);
      }
    });

    return true;
  };

  /**
   * edit handler for form
   * @return {undefined}
   */
  handleEdit = () => {
    this.setState({ editBenefits: true, success: '' }, () => {
      this.props.toggleEnableRedirect(false);
    });
  };

  /**
   * general textbox handler
   * @param {event} e - event
   * @param {string} id - string for id value which will be used for modfying state
   * @return {undefined}
   */
  handleText = ({ target }, id) => {
    const errorProp = `${id}Error`;
    let errorValue = this.state.errorProp;
    if (this.state[errorProp] && target.value.length > 0) {
      errorValue = false;
    }
    this.setState({
      [id]: target.value,
      [errorProp]: errorValue,
      isDirty: true,
      editedDates: true
    });
  };

  /**
   * save handler for benefits form
   * @return {undefined}
   */
  handleSave = () => {
    const validate = this.validate();
    if (!validate) {
      return false;
    }
    const { selectedId } = this.props;
    const memberProfile = {
      eligibleStartDate: moment(this.state.eligibleStartDate).format('YYYY-MM-DD'),
      eligibleEndDate: moment(this.state.eligibleEndDate).format('YYYY-MM-DD')
    };
    const submitted = {
      memberProfile,
      passengerId: selectedId,
      formType: 'benefits'
    };

    const state = { success: '', error: '', showLoader: true };

    this.setState(state, () => {
      this.props.clearData();
      this.props.putMemberProfile(submitted);

      if (this.state.error !== state.error) {
        this.props.setErrorMsg(state.error);
      }
    });
  };

  /**
   * create object that will be initialized into component state
   * @return {object} - initialized state
   */
  initialize() {
    const benefits = this.props.benefits;

    const eligibleStartDate = !_.isNil(benefits.eligibleStartDate)
      ? moment(benefits.eligibleStartDate).format('MM/DD/YYYY')
      : '';

    const eligibleEndDate = !_.isNil(benefits.eligibleEndDate)
      ? moment(benefits.eligibleEndDate).format('MM/DD/YYYY')
      : '';

    return {
      eligibleStartDate,
      eligibleEndDate,
      isDirty: false,
      success: '',
      error: '',
      editBenefits: false,
      showLoader: false,
      eligibleStartDateError: false,
      eligibleEndDateError: false,
      eligibleStartDateText: '',
      eligibleEndDateText: ''
    };
  }

  /**
   * validate form data b4 submission
   * @return {boolean} - returns true if validated, false if not
   */
  validate() {
    let validate = true;

    const state = {};
    state.eligibleStartDateError = false;
    state.eligibleEndDateError = false;
    state.eligibleStartDateText = '';
    state.eligibleEndDateText = '';

    // nothing to submit if date is not updated
    if (!this.state.editedDates) {
      this.setState(state);
      return false;
    }

    if (this.state.eligibleStartDate === '') {
      state.eligibleStartDateError = true;
      state.eligibleStartDateText = 'Please enter a date for eligiblity start date.';
      validate = false;
    }

    if (this.state.eligibleEndDate === '') {
      state.eligibleEndDateError = true;
      state.eligibleEndDateText = 'Please enter a date for eligiblity end date.';
      validate = false;
    }

    // if one field is empty
    if (!validate) {
      this.setState(state);
      return validate;
    }

    REGEX_DATE.lastIndex = 0;
    if (!REGEX_DATE.test(this.state.eligibleStartDate)) {
      state.eligibleStartDateError = true;
      state.eligibleStartDateText =
        'Please enter a valid date for eligibility start date.';
      validate = false;
    }

    REGEX_DATE.lastIndex = 0;
    if (!REGEX_DATE.test(this.state.eligibleEndDate)) {
      state.eligibleEndDateError = true;
      state.eligibleEndDateText = 'Please enter a valid date for eligibility end date.';
      validate = false;
    }

    const eligibleStartDate = moment(this.state.eligibleStartDate);
    const eligibleEndDate = moment(this.state.eligibleEndDate);

    if (eligibleEndDate.isBefore(eligibleStartDate)) {
      state.eligibleEndDateError = true;
      state.eligibleEndDateText = 'End date must be after start date.';
      validate = false;
    }

    this.setState(state);
    return validate;
  }

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const { benefits, user, children } = this.props;
    const {
      showCalendar,
      showLoader,
      editBenefits,
      eligibleStartDate,
      eligibleStartDateError,
      eligibleStartDateText,
      eligibleEndDate,
      eligibleEndDateError,
      eligibleEndDateText,
      eligibleSelected
    } = this.state;

    const startDate = eligibleStartDate !== '' ? eligibleStartDate : '';
    const endDate = eligibleEndDate !== '' ? eligibleEndDate : '';

    return (
      <>
        {children}
        <Container className="parentTab benefits">
          {showLoader ? (
            <LoadingModal
              label="Saving Member Benefits..."
              isOpen={this.state.showLoader}
            />
          ) : null}

          {showCalendar ? (
            <Calendar
              closeCalendar={this.closeModal}
              openCalendar={showCalendar}
              startDate={
                this.state[eligibleSelected] === ''
                  ? this.today
                  : this.state[eligibleSelected]
              }
              submitModal={this.submitModal}
              disabledDays={[]}
              fromMonth=""
            />
          ) : null}

          <div className="memberRow">
            <InputText
              customClassName="twoColumn"
              placeholder="##/##/####"
              mask="_"
              fieldName="eligibleStartDate"
              label="Eligibility Start Date"
              showLabel={true}
              error={eligibleStartDateError}
              errorText={eligibleStartDateText}
              onChange={e => this.handleText(e, 'eligibleStartDate')}
              value={startDate}
              disabled={!editBenefits || user.features[ELIGIBILITY_API]}
              // onClick={() => this.openModal('eligibleStartDate')}
              format="##/##/####"
            />
            <InputText
              customClassName="twoColumn"
              placeholder="##/##/####"
              mask="_"
              fieldName="eligibleEndDate"
              label="Eligibility End Date"
              showLabel={true}
              error={eligibleEndDateError}
              errorText={eligibleEndDateText}
              onChange={e => this.handleText(e, 'eligibleEndDate')}
              value={endDate}
              disabled={!editBenefits || user.features[ELIGIBILITY_API]}
              // onClick={() => this.openModal('eligibleEndDate')}
              format="##/##/####"
            />
          </div>
        </Container>
        <Container className="parentTab benefits">
          <div className="memberRow">
            <div className="CustomInputText twoColumn">
              <p className="benefitsHeading">Health Plan</p>
              <p className="benefitsText">{benefits.healthPlanName || '--'}</p>
            </div>
            <div className="CustomInputText twoColumn">
              <p className="benefitsHeading">Plan</p>
              <p className="benefitsText">{benefits.healthSubPlanName || '--'}</p>
            </div>
          </div>
        </Container>
        <Container className="parentTab benefits">
          <div className="memberRow">
            <div className="CustomInputText oneColumn">
              <p className="benefitsHeading">Member Ride Benefit</p>
              {_.has(benefits, 'rideBenefit')
                ? benefits.rideBenefit.map((benefit, k) => {
                  return (
                    <p className="benefitsText" key={k}>
                      {_.isNil(benefit.memberRideBenefit)
                        ? '--'
                        : benefit.memberRideBenefit}
                    </p>
                  );
                })
                : null}
            </div>
            <div className="CustomInputText halfColumn">
              <p className="benefitsHeading">Usage (MTD)</p>
              {_.has(benefits, 'rideBenefit')
                ? benefits.rideBenefit.map((benefit, k) => {
                  return (
                    <p className="benefitsText" key={k}>
                      {_.isNil(benefit.usageMonth) ? '--' : benefit.usageMonth}
                    </p>
                  );
                })
                : null}
            </div>
            <div className="CustomInputText halfColumn">
              <p className="benefitsHeading">Usage (YTD)</p>
              {_.has(benefits, 'rideBenefit')
                ? benefits.rideBenefit.map((benefit, k) => {
                  return (
                    <p className="benefitsText" key={k}>
                      {_.isNil(benefit.usageYear) ? '--' : benefit.usageYear}
                    </p>
                  );
                })
                : null}
            </div>
            <div className="CustomInputText halfColumn">
              <p className="benefitsHeading">Balance (MTD)</p>
              {_.has(benefits, 'rideBenefit')
                ? benefits.rideBenefit.map((benefit, k) => {
                  return (
                    <p className="benefitsText" key={k}>
                      {_.isNil(benefit.balanceMonth) ? '--' : benefit.balanceMonth}
                    </p>
                  );
                })
                : null}
            </div>
            <div className="CustomInputText halfColumn">
              <p className="benefitsHeading">Balance (YTD)</p>
              {_.has(benefits, 'rideBenefit')
                ? benefits.rideBenefit.map((benefit, k) => {
                  return (
                    <p className="benefitsText" key={k}>
                      {_.isNil(benefit.balanceYear) ? '--' : benefit.balanceYear}
                    </p>
                  );
                })
                : null}
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const BenefitsEditButton = ({ permission, handleEdit, handleSave, handleCancel }) => {
  const [isEditable, setIsEditable] = useState(false);

  const _handleEdit = e => {
    setIsEditable(true);
    return handleEdit(e);
  };

  const _handleSave = e => {
    setIsEditable(false);
    return handleSave(e);
  };

  const _handleCancel = e => {
    if (handleCancel(e) !== false) {
      return setIsEditable(false);
    }
  };

  return (
    <>
      {' '}
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <>
          {isEditable ? (
            <>
              <a onClick={_handleCancel}>Cancel</a>
              <a onClick={_handleSave}>Save</a>
            </>
          ) : (
            <a onClick={_handleEdit}>Edit</a>
          )}
        </>
      ) : null}
    </>
  );
};

Benefits.getNavOptions = () => [];

Benefits.propTypes = {
  user: PropTypes.object,
  editable: PropTypes.bool,
  selectedId: PropTypes.string,
  permission: PropTypes.string,
  benefits: PropTypes.object,
  putMemberProfile: PropTypes.func,
  clearData: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setEditButton: PropTypes.func,
  setErrorMsg: PropTypes.func,
  success: PropTypes.object,
  handleChildSelection: PropTypes.func,
  toggleEnableRedirect: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

Benefits.defaultProps = {
  user: {},
  editable: false,
  selectedId: null,
  permission: 'view',
  benefits: {},
  putMemberProfile: () => {},
  clearData: () => {},
  error: {},
  success: {},
  toggleEnableRedirect: () => {},
  children: null,
  setEditButton: () => {},
  setErrorMsg: () => {},
  handleChildSelection: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  benefits: _.get(state, 'memberProfile.formData.benefits', {}),
  error: state.memberProfile.error,
  success: state.memberProfile.success
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putMemberProfile: data => putMemberProfile(data),
      clearData: () => clearData()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
