import React from 'react';
import { TOKENS } from '@SRHealth/frontend-lib';
import { RIDES_PER_YEAR_LABEL } from '../FundingSource.utils';

export default function BenefitUnlimited() {
  return (
    <div
      id="benefits-remaining"
      data-testid="display-counter-benefits-remaining"
      className={
        `flex justify-between items-center py-[4px] px-0 gap-[12px]` +
        ` border-b-1 border-b-solid border-${TOKENS.BORDER.NEUTRAL_MD}`
      }
    >
      <div
        className={`flex-shrink text-wrap break-words overflow-hidden text-${TOKENS.FONT.PRIMARY}`}
      >
        {RIDES_PER_YEAR_LABEL}: Unlimited
      </div>
    </div>
  );
}
