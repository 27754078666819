import React, { useReducer } from 'react';
import { Section, useModal } from '@SRHealth/frontend-lib';
import Summary from './subcomponents/Summary';
import TripTypeComponent from './subcomponents/TripType';
import TripPhoneNumber from './subcomponents/TripPhoneNumber';
import AdditionalPassengers from './subcomponents/AdditionalPassengers';
import { putSmsPermissionsUpdate } from '~/services/member.service';
import OptOutModalContents from './subcomponents/OptOutModalContents';
import type { Binary } from '~/types';
import useAnalytics from '~/hooks/useAnalytics';
import useModelSelector from '~/hooks/useModelSelector';
import type { PassengerInfoSection } from './PassengerInfo.types';
import Loader from '~/Pages/RideBooking/Loader';
import { useAppDispatch, useAppSelector } from '~/Modules';

import {
  completeSection,
  editPassengerInfo,
  initializeDateThunk,
  RIDE_SECTION_ORDER,
  rollbackActiveSection,
  selectIsActive
} from '~/Modules/rideBooking';
import { shouldTriggerOptOutModal } from './PassengerInfo.utils';

const inlineCSS = `
#passenger-info {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  #passenger-info-questions {
    width: 968px;
    padding-left: 4px;
    padding-right: 4px;

    [data-testid^="card-"]:not(
        [data-testid$="-content"],
        [date-testid$="-header"]
      )
      > div:last-child:not(
        [data-testid$="-content"],
        [date-testid$="-header"]
      ) {
      width: calc(100% - 8px);
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}
`;

type PassengerInfoState = {
  section: PassengerInfoSection;
  isLoading: boolean;
  sectionTime: number;
  subSectionTime: number;
};

type ReducerAction =
  | { type: 'edit'; section: PassengerInfoSection }
  | { type: 'save' }
  | { type: 'cancel' }
  | { type: 'loading'; isLoading: boolean };

const createInitialState = (): PassengerInfoState => ({
  section: 'none',
  isLoading: false,
  sectionTime: Date.now(),
  subSectionTime: 0
});

const reducer: React.Reducer<PassengerInfoState, ReducerAction> = (
  state: PassengerInfoState,
  action: ReducerAction
) => {
  switch (action.type) {
    case 'edit':
      return { ...state, section: action.section, subSectionTime: Date.now() };

    case 'save':
      return { ...state, section: 'none', subSectionTime: 0 };

    case 'cancel':
      return { ...state, section: 'none', subSectionTime: 0 };

    case 'loading':
      return { ...state, isLoading: action.isLoading };

    default:
      return state;
  }
};

const PassengerInfo = () => {
  const [, ModalActions] = useModal();
  const { trackEvent } = useAnalytics();
  const appDispatch = useAppDispatch();
  const isActive = useAppSelector(state => selectIsActive(state, 'passenger-info'));
  const { model } = useModelSelector(state => state.rideBooking.passengerInfo);
  const [state, localDispatch] = useReducer(
    reducer,
    createInitialState(),
    createInitialState
  );

  /** This callback handles the logic when the user selects to either
   * opt in or opt out of SMS messages. */
  const optOutCallback = (optOut: Binary) => () => {
    if (model.phoneNumberOptOutStatus !== optOut) {
      localDispatch({ type: 'loading', isLoading: true });
      putSmsPermissionsUpdate(
        model.passengerId,
        model.phoneNumber!,
        'mobile',
        !!optOut
      ).finally(() => localDispatch({ type: 'loading', isLoading: false }));
    }
    ModalActions.setIsOpen(false);
  };

  /** Enable editing a section of the Passenger Info. If the user
   * is currently editing a different section, revert outstanding
   * changes first. */
  const handleEdit = (section: PassengerInfoSection) => {
    if (section !== state.section && model.isDirty) {
      model.revert();

      if (state.section !== 'none') {
        trackEvent('RBF Passenger Info', {
          EditStatus: 'Abandoned',
          PassengerInfoSection: state.section,
          TimeOnSection: Date.now() - state.sectionTime,
          TimeOnSubSection: Date.now() - state.subSectionTime
        });
      }
    }

    trackEvent('RBF Passenger Info', {
      EditStatus: 'Started',
      PassengerInfoSection: section,
      TimeOnSection: Date.now() - state.sectionTime,
      TimeOnSubSection: 0
    }).then(() => {
      localDispatch({ type: 'edit', section });

      if (!isActive) appDispatch(editPassengerInfo());
    });
  };

  /** Cancel editing a section of the Passenger Info. Any pending
   * changes are rolled back and a mixpanel event is dispatched. */
  const handleCancel = () => {
    if (model.isDirty) {
      model.revert();
      trackEvent('RBF Passenger Info', {
        EditStatus: 'Abandoned',
        PassengerInfoSection: state.section,
        TimeOnSection: Date.now() - state.sectionTime,
        TimeOnSubSection: Date.now() - state.subSectionTime
      });
    }

    localDispatch({ type: 'cancel' });
    appDispatch(rollbackActiveSection());
  };

  /** Commit any changes to the model and adjust the component edit
   * state to 'none'. */
  const handleSave = () => {
    if (model.isDirty) {
      localDispatch({ type: 'loading', isLoading: true });

      const shouldUpdateDate =
        model.propertyIsDirty('tripType') || model.propertyIsDirty('transportType');

      model
        .commit()
        .then(() => {
          trackEvent('RBF Passenger Info', {
            EditStatus: 'Completed',
            PassengerInfoSection: state.section,
            TimeOnSection: Date.now() - state.sectionTime,
            TimeOnSubSection: Date.now() - state.subSectionTime
          });

          if (shouldUpdateDate) appDispatch(initializeDateThunk(model));

          if (shouldTriggerOptOutModal(state.section, model)) {
            ModalActions.set({
              isOpen: true,
              showBackdrop: true,
              content: (
                <OptOutModalContents
                  onOptOut={optOutCallback(1)}
                  onOptIn={optOutCallback(0)}
                />
              )
            });
          }

          localDispatch({ type: 'save' });
          appDispatch(completeSection('passenger-info'));
          document
            .querySelector(`[data-testid="section-passenger-info"]`)
            ?.scrollIntoView();
        })
        .catch(() => {})
        .finally(() => localDispatch({ type: 'loading', isLoading: false }));
    } else {
      localDispatch({ type: 'save' });
      appDispatch(completeSection('passenger-info'));
    }
  };

  return (
    <>
      <style>{inlineCSS}</style>
      <Section id="passenger-info" icon="Passenger" label="Passenger Info">
        <Summary
          editState={state.section}
          modelData={model.toJSON()}
          onEdit={handleEdit}
          onCancel={handleCancel}
        />

        <Loader isLoading={state.isLoading} />

        <div id="passenger-info-questions" className="reset-div">
          {
            {
              none: null,
              TripType: (
                <TripTypeComponent
                  model={model}
                  onCancel={handleCancel}
                  onSave={handleSave}
                />
              ),
              TripPhoneNumber: (
                <TripPhoneNumber
                  model={model}
                  onCancel={handleCancel}
                  onSave={handleSave}
                />
              ),
              AdditionalPassengers: (
                <AdditionalPassengers
                  model={model}
                  onCancel={handleCancel}
                  onSave={handleSave}
                />
              )
            }[state.section]
          }
        </div>
      </Section>
    </>
  );
};

PassengerInfo.sectionIndex = RIDE_SECTION_ORDER['passenger-info'];

export { PassengerInfo };
