import { DATE_API_FORMAT } from '~/constants';
import moment from 'moment-timezone';
import type { DateRange } from '~/Modules/memberProfile/types';
import { format } from 'date-fns';

/**
 * Generates a timestamp object.
 *
 * This function uses Moment.js to get the current date and time in UTC format.
 * The returned object includes the date in 'YYYY-MM-DD HH:mm:ss.SSSSSS' format,
 * the timezone ('UTC'), and a timezone type (3).
 *
 * @returns {Object} An object containing the timestamp data.
 * @returns {string} .date The current date and time in 'YYYY-MM-DD HH:mm:ss.SSSSSS' format.
 * @returns {string} .timezone The timezone, always 'UTC'.
 * @returns {number} .timezone_type The timezone type, always 3.
 */
export const generateTimestamp = () => {
  return {
    date: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
    timezone: 'UTC',
    timezone_type: 3
  };
};

/**
 * Get the start and end time for a ride.
 * @return {object} start and end time
 */
export const getStartEndTime = ride => {
  // get timezone, is in different location based on ride type
  const timeZone = ride?.passengerTimezone ?? '';

  let startTime: string | moment.Moment = '';
  let endTime = '';

  if (!('rideStartTime' in ride)) {
    return { startTime, endTime };
  }

  const rideStartTime = fixUTCDate(
    ride.rideStartTime,
    timeZone,
    'h:mm A',
    DATE_API_FORMAT,
    true
  );

  if (typeof rideStartTime === 'string') {
    return { startTime, endTime };
  }

  startTime = (rideStartTime as moment.Moment).format('h:mm A');
  endTime = '';
  if (ride.appointmentTime === null) {
    // b leg
    const minutesToAdd = moment(ride.estimatedFinishTime, 'HH:mm:ss').format('m');
    endTime = (rideStartTime as moment.Moment).add(minutesToAdd, 'm').format('h:mm A');
  } else {
    // a leg
    endTime = fixUTCDate(
      ride.appointmentTime,
      ride.passengerTimezone,
      'h:mm A',
      DATE_API_FORMAT
    );
  }

  return { startTime, endTime };
};

export function fixUTCDate(
  date: moment.MomentInput,
  zone: string,
  format: string,
  inputtedTimeFormat: string,
  returnAsMoment: true
): moment.Moment;
export function fixUTCDate(
  date: moment.MomentInput,
  zone: string,
  format: string,
  inputtedTimeFormat?: string,
  returnAsMoment?: false
): string;
/**
 * take utc date and return a formatted date based on time zone
 */
export function fixUTCDate(
  date,
  zone,
  format,
  inputtedTimeFormat = '',
  returnAsMoment = false
) {
  if (!date) return '';

  const timeZone = !zone || zone === 'UTC' ? moment.tz.guess() : zone;

  const utcTime = inputtedTimeFormat
    ? moment.utc(date, inputtedTimeFormat)
    : moment.utc(date);

  try {
    return returnAsMoment
      ? moment(utcTime).tz(timeZone)
      : moment(utcTime).tz(timeZone).format(format);
  } catch (e) {
    return '';
  }
}

/**
 * Converts an array of ISO date ranges into a semicolon-separated, formatted string 'M/d/yy'.
 */
export function formatDateRanges(dates: DateRange[]) {
  if (!dates.length) return '';

  return dates
    .map(
      ({ startDate, endDate }) =>
        `${moment.utc(startDate).format('M/D/YY')} - ${moment
          .utc(endDate)
          .format('M/D/YY')}`
    )
    .join('; ');
}

/** Convert the string, number or Date object to the display format of
 * `MM/DD/YYYY`. */
export function convertToDisplayDate(date: string | number | Date): DisplayDate {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  return format(date, 'MM/dd/yyyy') as DisplayDate;
}

/** Converts the ISO 8601 date format to North American `MM/DD/YYYY` or `MM/DD/YY` */
export function convertISOToDisplayDate(date: IsoDate, yearDigits = 4): DisplayDate {
  const [year, month, day] = date.split('-');

  const _year = yearDigits === 2 ? year.slice(2) : year;

  return `${month}/${day}/${_year}` as DisplayDate;
}

/** Converts the long form timezone to it's three letter counter part.
 * E.g. America/New_York => EST */
export function convertLongTimezoneToShort(timeZone: string): string | undefined {
  return new Intl.DateTimeFormat('en-us', {
    timeZone,
    timeZoneName: 'short'
  })
    .formatToParts(new Date())
    .find(({ type }) => type === 'timeZoneName')?.value;
}

/** Return the minute offset for a given timezone. */
export function timezoneOffset(timeZone: string): number {
  const date = new Date();

  const offset = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'longOffset'
  }).format(date);

  const multiplier = offset.includes('-') ? 1 : -1;
  const hoursOffset = parseInt(offset.slice(-4, -2));
  const minutesOffset = parseInt(offset.slice(-2));

  if (isNaN(hoursOffset) || isNaN(minutesOffset)) return 0;

  return (hoursOffset * 60 + minutesOffset) * multiplier;
}

/** Return the offset difference between the current user and
 * a target timezone, in minutes. E.g if the user is on the west coast
 * and the target is on the east coast, the difference will be
 * -180 minutes. */
export function timezoneOffsetFromUser(timezone: string): number {
  return new Date().getTimezoneOffset() - timezoneOffset(timezone);
}

/** Return the amount of minutes from now until the given date. */
export function minutesUntilDate(date: IsoDate): number {
  const minutes = Math.floor(
    (new Date(`${date} 00:00:00`).valueOf() - Date.now()) / 60000
  );

  return minutes > 0 ? minutes : 0;
}

/** Return the amount of minutes from now to midnight. */
export function minutesToMidnight(): number {
  const now = new Date();

  const hours = 23 - now.getHours();
  const minutes = 59 - now.getMinutes();

  return minutes + hours * 60;
}
