import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import type { Address } from '~/types';
import ApprovedProviderFoundBox from '../ApprovedProviderFoundBox/ApprovedProviderFoundBox';
import ApprovedProviderNotes from '../ApprovedProviderNotes/ApprovedProviderNotes';
import ApprovedProvidersSearch from '../ApprovedProvidersSearch/ApprovedProvidersSearch';
import AutoComplete from '../AutoComplete/AutoComplete';
import SavedAddressSelect from '../SavedAddressSelect/SavedAddressSelect';
import { AddressAutoComplete } from './AddressAutoComplete';
import { useSavedAddress } from '../SavedAddressSelect/useSavedAddress';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { fetchApprovedProviders } from '~/Modules/search';
import { clearAddressData } from '~/Modules/bookingData';
import type { AutoCompleteSource } from '../TransportType/TransportType';
import { Switch, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export type ApprovedProvidersProps = {
  type: 'pickup' | 'dropoff';
  address: Address;
  rideDetails: BookingDataStore | RideData;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAutoComplete: (
    row: Record<string, unknown> | null,
    type: ApprovedProvidersProps['type'],
    source?: AutoCompleteSource
  ) => void;
  onResetAutoComplete: (showOrHide, location) => void;
  toggleLoading: (toggle: boolean, text: string, timeout?: number | undefined) => void;
  showSavedAddress?: boolean;
  missingFields?: unknown;
  updateRideDetails: (data: Record<string, unknown>) => void;
};

const ApprovedProviders: React.FC<ApprovedProvidersProps> = ({
  type,
  address,
  rideDetails,
  showSavedAddress,
  onChange,
  onSelectAutoComplete,
  onResetAutoComplete,
  updateRideDetails
}) => {
  const dispatch = useDispatch();
  const [localAddress, setLocalAddress] = useState<string>('');
  const [savedAddressState, savedAddressActions] = useSavedAddress();
  const [showAutoComplete, setShowAutoComplete] = useState<[boolean, boolean]>([
    false, // Default AutoComplete
    false // Approved Providers AutoComplete
  ]);

  const altType = type === 'pickup' ? 'from' : 'to';
  const typeUpper = type[0].toUpperCase() + type.substring(1);

  const label = type === 'pickup' ? 'Depart' : 'Arrive';

  const isApprovedProvider = !!(rideDetails[`show${typeUpper}ApprovedProvider`] ?? false);
  const providerNotFound = !!(rideDetails[`${type}ProviderNotFound`] ?? false);

  /**
   * Display initial suggestions from ApprovedProviderRideHistory
   * if available
   */
  const _onProviderSearchFocus = () => setShowAutoComplete([false, true]);

  /**
   * Automatically disable the provider not found button
   * on approved provider toggle
   */
  const onToggleApprovedProviders = useCallback(() => {
    const newData: Partial<RideData> = {
      [`show${typeUpper}ApprovedProvider`]: !isApprovedProvider
    };

    if (!isApprovedProvider) {
      newData[`${type}ProviderNotFound`] = false;
    } else {
      // Clear out the approved provider fields when toggling off
      // so you can't evade approved provider hard block
      dispatch(clearAddressData(type));
    }

    updateRideDetails(newData);
  }, [isApprovedProvider]);

  /**
   * Callback to toggle provider not found feature on and off
   */
  const onToggleProviderNotFound = useCallback(() => {
    const newData: Partial<RideData> = { [`${type}ProviderNotFound`]: !providerNotFound };

    if (!providerNotFound) {
      newData[`${type}ProviderId`] = undefined;
      newData[`${type}ProviderNpi`] = undefined;
    }

    updateRideDetails(newData);
  }, [providerNotFound]);

  /**
   * Middleware to control the display of Autocomplete elements
   */
  const _onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const inputType = e.target.getAttribute('data-autocomplete-type');

      setLocalAddress(e.target.value);

      if (val.length >= 3) {
        // Input Type is from generic address search
        if (inputType === 'default') {
          setShowAutoComplete([true, false]);
        } else if (!providerNotFound) {
          // Input type is from approved provider
          setShowAutoComplete([!isApprovedProvider, isApprovedProvider]);
        }
      } else if (showAutoComplete[0] || showAutoComplete[1]) {
        setShowAutoComplete([false, false]);
      }

      // If we are getting autocomplete data for approved providers
      // it's controlled in the approved provider search component.
      // Don't bubble the event up
      if (inputType === 'default' || providerNotFound) {
        onChange(e);
      }
    },
    [providerNotFound, isApprovedProvider, showAutoComplete]
  );

  /**
   * Change the local address to empty string so that selection can
   * filter back down after updating app store. Also set show
   * autocomplete to false so we don't get duplicate autocomplete windows
   */
  const _onSelectAutoComplete = useCallback(
    (
      row: Record<string, unknown>,
      type: 'pickup' | 'dropoff',
      source?: 'approvedProvider' | 'venue' | 'savedAddress'
    ) => {
      setLocalAddress('');
      setShowAutoComplete([false, false]);
      onSelectAutoComplete(row, type, source);
    },
    [localAddress, setLocalAddress]
  );

  // Prevent enabling with Saved Address
  useEffect(() => {
    if (isApprovedProvider && savedAddressState[type]) {
      onToggleApprovedProviders();
    }
  }, [savedAddressState]);

  /**
   * If the props.address has a member saved address ID then automatically toggle on
   */
  useEffect(() => {
    if (address?.memberSavedAddressId && !savedAddressState[type]) {
      savedAddressActions.Toggle(type, true);
    }
  }, [address.memberSavedAddressId, savedAddressState[type]]);

  /**
   * The Provider Not Found flag must be added to BookingData store
   */
  useEffect(() => {
    updateRideDetails({ [`${type}ProviderNotFound`]: providerNotFound });
  }, [providerNotFound]);

  /**
   * The showPickupApprovedProvider flag must be added to BookingData store
   * and any Member Saved Address id's must be removed
   */
  useEffect(() => {
    const newRideData: Partial<BookingDataStore> = {
      [`show${typeUpper}ApprovedProvider`]: isApprovedProvider
    };

    if (isApprovedProvider) {
      newRideData[`${altType}MemberSavedAddressId`] = undefined;
    }

    updateRideDetails(newRideData);
  }, [isApprovedProvider]);

  /**
   * Prevent ApprovedProviderAutoComplete modal from popping up
   * and reset the state so that it DOES pop up if later toggled
   * back on
   */
  useEffect(() => {
    if (!isApprovedProvider && showAutoComplete[1]) {
      setShowAutoComplete([showAutoComplete[0], false]);
    }
  }, [isApprovedProvider, showAutoComplete]);

  /**
   * If an address value is passed down then prioritize that
   * over the local component state.
   */
  useEffect(() => {
    if (address.address !== localAddress) {
      setLocalAddress(address.address);
    }
  }, [address.address]);

  /**
   * React likes to complain about the input in the form
   * changing from controlled to uncontrolled. Ignore it. This
   * pattern implements a partial control because there are three
   * potential sources of values. User entry, initial props, and
   * then a value update after choosing from the Autocomplete dropdown.
   */
  return (
    <>
      <div className="row venues">
        <div className="venueLabel">
          <label htmlFor={type}>{label}</label>
        </div>

        <form
          className="address-container"
          action=""
          autoCorrect="off"
          spellCheck="false"
          autoComplete="off"
        >
          {showSavedAddress && savedAddressState[type] ? (
            <SavedAddressSelect
              type={type}
              value={address?.memberSavedAddressId}
              onChange={row => onSelectAutoComplete(row, type, 'savedAddress')}
              onReset={() => onSelectAutoComplete(null, type, 'reset')}
            />
          ) : (
            <div className="address-input-container">
              <input
                type="text"
                name={type}
                id={type}
                autoComplete="off"
                placeholder={`Type ${type} address...`}
                onChange={_onChange}
                value={localAddress}
                disabled={providerNotFound}
                data-autocomplete-type="default"
              />
              <IconButton onClick={() => dispatch(clearAddressData(type))} type="button">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </form>
      </div>

      <AddressAutoComplete
        type={type}
        showAutoComplete={showAutoComplete[0]}
        onSelect={_onSelectAutoComplete}
        onReset={onResetAutoComplete}
        value={localAddress}
      />

      <div className="row venues">
        <div className="selectSvg" onClick={onToggleApprovedProviders}>
          <Switch size="small" checked={isApprovedProvider} />
          <span className="svgText">Approved Provider</span>
        </div>

        {isApprovedProvider ? (
          <ApprovedProvidersSearch
            disabled={providerNotFound}
            onChange={_onChange}
            onFocus={_onProviderSearchFocus}
            value={(address?.providerName as string) ?? ''}
          />
        ) : (
          <div className="spacer" />
        )}
      </div>

      <ApprovedProviderAutoComplete
        type={type}
        showAutoComplete={showAutoComplete[1]}
        onSelect={_onSelectAutoComplete}
        onReset={onResetAutoComplete}
        value={localAddress}
      />

      <div className="row venues">
        <ApprovedProviderFoundBox
          value={providerNotFound}
          display={isApprovedProvider}
          onClick={onToggleProviderNotFound}
        />
      </div>

      <ApprovedProviderNotes
        display={isApprovedProvider && providerNotFound}
        type={type}
        address={address}
        onChange={_onChange}
        onSelect={onSelectAutoComplete}
        onReset={onResetAutoComplete}
        updateRideDetails={updateRideDetails}
      />
    </>
  );
};

type ApprovedProviderAutoCompleteProps = {
  type: ApprovedProvidersProps['type'];
  showAutoComplete: boolean;
  onSelect: (row, type: ApprovedProvidersProps['type'], source?: string) => void;
  onReset: ApprovedProvidersProps['onResetAutoComplete'];
  value?: string;
};

const ApprovedProviderAutoComplete: React.FC<ApprovedProviderAutoCompleteProps> = ({
  type,
  showAutoComplete,
  onSelect,
  onReset,
  value
}) => {
  const dispatch = useAppDispatch();

  const bookingData = useAppSelector(state => state?.bookingData);
  const approvedProviders = useAppSelector(({ search }) => search?.approvedProviders);
  const approvedProvidersHistory = useAppSelector(
    state => state.patients.approvedProviderRideHistory
  );

  /** Alternates between using the provider history as
   * autocomplete options, versus the returned search results */
  const autoCompleteData = useMemo(() => {
    if ((!value || value.length < 3) && approvedProvidersHistory) {
      return approvedProvidersHistory;
    }

    return approvedProviders?.data ?? [];
  }, [approvedProvidersHistory, approvedProviders, value]);

  /**
   * Should we display the auto complete modal?
   */
  const showModal = useMemo(
    () => autoCompleteData.length > 0 && showAutoComplete,
    [autoCompleteData, showAutoComplete]
  );

  /** Handler logic for lazy loading next page of approved providers */
  const onLoadMore = useCallback(
    ({ value, hospitalId, offset }) =>
      dispatch(fetchApprovedProviders({ search: value, hospitalId, offset })),
    [dispatch, fetchApprovedProviders]
  );
  return (
    <AutoComplete
      data={autoCompleteData}
      select={row => onSelect(row, type, 'approvedProvider')}
      showModal={showModal}
      right="0"
      location={type}
      search={approvedProviders}
      isApprovedProviders={true}
      resetParentProps={onReset}
      onLoadMore={onLoadMore}
      searchTerm={value}
      bookingData={bookingData}
    />
  );
};

export default ApprovedProviders;
