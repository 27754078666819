import React from 'react';
import PropTypes from 'prop-types';
import Scroller from '~/Shared/Components/Scroller/Scroller';
import { NOOP, formatPhoneNumber } from '~/utilities/helperFunctions';

class AutoComplete extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.node = null;
  }

  /**
   * since showModal can get updated inside the component, need to set showModal to component state
   * @return {undefined}
   */
  componentDidMount() {
    const { showModal } = this.props;

    this.setState({ showModal }, () => {
      window.addEventListener('mousedown', this.handleOutsideClick, false);
    });
  }

  /**
   * since showModal can get updated inside the component, need to set showModal to component state
   * @param {object} prevProps - previous props
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    const { showModal } = this.props;

    if (showModal !== prevProps.showModal) {
      this.setState({ showModal });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleOutsideClick);
  }
  /**
   * open and close calendar modal
   * @return {undefined} returns nothing.
   */
  handleClick = () => {
    const {
      resetParentProps,
      location,
      isValueEmpty,
      resetEmptyState,
      isApprovedProviders,
      showEmptyResults
    } = this.props;

    if (!this.state.showModal) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }

    if (!(isApprovedProviders && isValueEmpty && showEmptyResults)) {
      this.setState(
        prevState => ({
          showModal: !prevState.showModal
        }),
        () => {
          resetParentProps(this.state.showModal, location);
        }
      );
    }

    if (isApprovedProviders && isValueEmpty && showEmptyResults) {
      resetEmptyState({ showEmptyResults: false });
    }
  };

  /**
   * close modal by clicking outside of modal
   * @param {object} e - event
   * @return {undefined} returns nothing.
   */
  handleOutsideClick = e => {
    if (!this.node || this.node?.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  /**
   * @param {object} row - row of autocomplete data
   * @returns {void}
   */
  select = row => {
    this.props.select(row);
    this.handleClick();
  };

  /**
   * Checks the top, right, bottom and left props to dynamically
   * create style rules for the dropdown
   * @returns {object} Style rules in object notation
   */
  dropdownStyling = () => {
    const { top, right, bottom, left } = this.props;

    const style = {};

    if (top) {
      style.top = top;
    }

    if (right) {
      style.right = right;
    }

    if (bottom) {
      style.bottom = bottom;
    }

    if (left) {
      style.left = left;
    }

    return style;
  };

  handleLazyLoading = () => {
    const { onLoadMore, searchTerm, bookingData, search } = this.props;

    onLoadMore &&
      searchTerm &&
      onLoadMore({
        search: searchTerm,
        hospitalId: bookingData?.hospitalId,
        offset: (search.page + 1) * search.limit
      });
  };

  render() {
    const { location, data, isApprovedProviders, search, dataKey } = this.props;
    const { showModal } = this.state;

    if (!showModal) return null;

    const places = (
      <ul className="clearfix">
        {data.map((row, i) => {
          if (isApprovedProviders) {
            return (
              <li key={i}>
                <a onClick={() => this.select(row, i)} style={{ fontSize: 12 }}>
                  <div>{row.name}</div>
                  <div>{row.address}</div>
                  <div>
                    <span>
                      {formatPhoneNumber(row.phone)} | NPI: {row.npiId}
                    </span>
                  </div>
                </a>
              </li>
            );
          } else {
            return (
              <li key={i}>
                <a onClick={() => this.select(row, i)}>
                  {row[dataKey] ?? row.place_name}
                </a>
              </li>
            );
          }
        })}
      </ul>
    );

    return (
      <div className={`CustomDropDown AutoCompleteContainer ${location}`}>
        <div
          className="dropDownModal"
          ref={node => (this.node = node)}
          onBlur={this.handleOutsideClick}
          style={this.dropdownStyling()}
        >
          {isApprovedProviders ? (
            <Scroller
              loadMore={this.handleLazyLoading}
              hasMore={search?.hasMore ?? false}
              initialLoad={false}
              useWindow={false}
            >
              {places}
            </Scroller>
          ) : (
            places
          )}
        </div>
      </div>
    );
  }
}

AutoComplete.defaultProps = {
  data: [],
  dataKey: 'matching_place_name',
  select: NOOP,
  showModal: false,
  customClassName: '',
  location: 'dropoff',
  resetParentProps: NOOP,
  onLoadMore: NOOP,
  top: null,
  right: null,
  bottom: null,
  left: null
};

AutoComplete.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string,
  select: PropTypes.func,
  showModal: PropTypes.bool,
  customClassName: PropTypes.string,
  location: PropTypes.string,
  resetParentProps: PropTypes.func,
  onLoadMore: PropTypes.func,
  top: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string
};

export default AutoComplete;
