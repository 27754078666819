import {
  Is,
  createModelFactory,
  createTypeGuard,
  type ModelType
} from '@SRHealth/frontend-lib';
import {
  addressMapboxFactory,
  isAddressModel,
  type AddressModel,
  type PropertyRuleSignature
} from '~/models';

export type RideType = 'arriveBy' | 'departAt';

export const isRideType = createTypeGuard((t: unknown): RideType | null => {
  return t === 'arriveBy' || t === 'departAt' ? t : null;
});

export type RideTimeNow = 'Now';
export type RideTimeFlex = 'Flex Time';
export type RideTimeString = `${number}:${number} ${'am' | 'pm'}`;

export const isRideTimeString = createTypeGuard((t: unknown): RideTimeString | null => {
  if (Is.String(t) && /\d{1,2}:\d{2} (am)|(pm)/i.test(t)) {
    const [hours, minutes] = t.split(':', 2);

    if (hours && minutes) {
      const h = parseInt(hours);
      const m = parseInt(minutes);

      if (h >= 1 && h <= 12 && m >= 0 && m <= 59) {
        return t as RideTimeString;
      }
    }
  }

  return null;
});

export type RideTime = RideTimeString | RideTimeNow | RideTimeFlex;

export const isRideTime = createTypeGuard((t: unknown): RideTime | null => {
  if (t === 'Now' || t === 'Flex Time' || isRideTimeString(t)) return t;

  return null;
});

export type RideProps = {
  departAddress: AddressModel;
  arriveAddress: AddressModel;
  notes: string;
  type: RideType;
  time?: RideTime;
  timezone?: string;
  distance?: string | number;
  duration?: number;
};

export type RideModel = ModelType<RideProps>;

export type RidePropertyRule = PropertyRuleSignature<RideProps>;

export const RIDE_DEFAULT = (): RideProps => ({
  departAddress: addressMapboxFactory(),
  arriveAddress: addressMapboxFactory(),
  notes: '',
  type: 'arriveBy',
  distance: undefined,
  time: undefined,
  timezone: undefined,
  duration: undefined
});

export const rideFactory = createModelFactory<RideModel>(RIDE_DEFAULT(), {
  properties: [
    ['is-address-model', 'departAddress', isAddressModel.strict],
    ['is-address-model', 'arriveAddress', isAddressModel.strict],
    ['is-string', 'notes', Is.String.strict],
    ['is-ride-type', 'type', isRideType.strict],
    ['is-numeric', 'distance', (v, _m, isCommit) => !isCommit || Is.Numeric.strict(v)],
    [
      'is-greater-than-0',
      'distance',
      (v: number | string, _m, isCommit) => {
        // Only check if it's commit. We set to -1 when editing the ride.
        if (isCommit && Is.Numeric(v) && v <= 0) {
          throw Error('Distance must be greater than 0.');
        }

        return true;
      }
    ],
    ['is-ride-time', 'time', (v, _m, isCommit) => !isCommit || isRideTime.strict(v)],
    ['is-string', 'timezone', (v, _m, isCommit) => !isCommit || Is.String.strict(v)],
    [
      // If we are changing the timezone then we need to reset the selected time.
      'resets-time',
      'timezone',
      (v, m, isCommit) => {
        if (!isCommit && v !== m.timezone) {
          m.time = undefined;
        }

        return true;
      }
    ],
    ['is-numeric', 'duration', (v, _m, isCommit) => !isCommit || Is.Numeric.strict(v)]
  ],
  model: []
});
