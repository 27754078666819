import React from 'react';
import type { RideProps } from '~/models';
import Divider from './Divider';
import { Button, Icon, TOKENS } from '@SRHealth/frontend-lib';
import RideCardSummaryAddress from './RideCardSummaryAddress';

export type RideCardSummaryProps = {
  /** The ride to render. */
  ride: RideProps;
  onEdit: () => void;
};

export default function RideCardSummary({ ride, onEdit }: RideCardSummaryProps) {
  return (
    <>
      <article
        className="reset-div relative py-[24px] px-[8px] flex flex-col gap-[8px] self-center"
        style={{ width: '436px' }}
      >
        <RideCardSummaryAddress type="depart" address={ride.departAddress} />
        <RideCardSummaryAddress type="arrive" address={ride.arriveAddress} />
        <Icon type="PinLine" className="absolute" style={{ left: '19px', top: '62px' }} />
        <div
          className={`reset-div flex flex-col py-[8px] border-t border-${TOKENS.BORDER.NEUTRAL_MD}`}
        >
          <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>Distance</span>
          <div>{ride.distance} Miles</div>
        </div>
        <div
          className={`reset-div flex flex-row py-[8px] border-t border-${TOKENS.BORDER.NEUTRAL_MD}`}
        >
          <div className="flex-1 flex flex-col gap-[4px]">
            <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>Pickup Time</span>
            <div className="reset-div text-md">
              {ride.type === 'departAt' && ride.time}
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-[4px]">
            <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>
              Appointment Time
            </span>
            <div className="reset-div text-md">
              {ride.type === 'arriveBy' && ride.time}
            </div>
          </div>
        </div>
        <div
          className={`reset-div flex flex-col py-[8px] border-t border-${TOKENS.BORDER.NEUTRAL_MD}`}
        >
          <span className={`heading-sm text-${TOKENS.FONT.PRIMARY}`}>
            Note for Driver
          </span>
          <div className="reset-div text-md py-[4px]">{ride.notes || 'None'}</div>
        </div>
      </article>

      <footer
        className="flex flex-col px-[8px] items-center gap-[8px] self-stretch"
        style={{ paddingBottom: '24px' }}
      >
        <Divider />
        <div className={`text-sm-italic text-center text-${TOKENS.FONT.NEUTRAL}`}>
          Editing the initial ride will remove the return ride
        </div>
        <Button
          id="edit-ride"
          label="Edit Ride"
          iconLeft="Edit"
          size="sm"
          onClick={onEdit}
          alt
        />
      </footer>
    </>
  );
}
