import { combineSlices, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '~/Modules/reducer';
import rootSaga from '~/Modules/sagas';
import history from '~/history';
import locationChangeMiddleware from '~/locationChange';
import { throttledMiddleware } from '~/middleware/throttleMiddleware';
import type { AppStore } from './types';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore<AppStore>({
  // @ts-expect-error Ignore
  reducer: connectRouter(history)(combineSlices({ ...rootReducer })),
  // @ts-expect-error Ignore
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat([
      routerMiddleware(history),
      locationChangeMiddleware,
      sagaMiddleware,
      throttledMiddleware
    ])
});

sagaMiddleware.run(rootSaga);

global.store = store;

export { store };
