/* eslint-disable no-unused-vars */
export * from './events';
import React from 'react';
import type { EventProperties } from '~/services/analytics.service';
import { analyticsService } from '~/services/analytics.service';
import { useAppSelector } from '~/Modules';
import type { UserStore } from '~/Modules/user';
import {
  type LEGACY__RideBookingFlowEventProperties,
  type AnalyticEvent,
  type MobilityAssessmentEventProperties,
  type PageVisitEventProperties,
  type PageNavigationEventProperties,
  type UserIdleEventProperties,
  type UserLogoutEventProperties,
  type RbfPassengerInfoEventProperties,
  type MemberDeeplinkActivatedProperties,
  type RbfFundingSourceEventProperties,
  type RbfDateEventProperties,
  type RbfRidesEvent
} from './events';

/**
 * Maps user details from the Redux store to a format compatible with the analytics service.
 *
 * @param user - The user object fetched from the Redux store.
 * @returns An object containing the user details, formatted for the analytics service.
 */
export const mapUserEventProperties = (user: Partial<UserStore>) => ({
  userId: user?.userData?.id,
  role: user?.userData?.role,
  hospitalGroupName: user?.userData?.hospitalGroupName,
  userTimezone: user?.userData?.timezone_format
});

/**
 * Defines the type for the useAnalytics hook's return object,
 * detailing methods for event tracking, page tracking, etc..
 */
export type AnalyticsInterface = ReturnType<typeof useAnalytics>;

/**
 * Custom React hook for interacting with the analytics service.
 * The hook automatically includes user details from the Redux store in every event.
 *
 * @returns An object with functions for tracking events and pages, and for identifying users.
 */
const useAnalytics = () => {
  const user = useAppSelector(state => state.user);
  const userEventProperties = mapUserEventProperties(user);

  /**
   * Tracks an event in the analytics service.
   * Includes user details from the Redux store in the event data.
   *
   * @param eventName - The name of the event.
   * @param eventProperties - Additional properties of the event.
   */
  function trackEvent(
    eventName: 'RBF Funding Source',
    eventProperties: RbfFundingSourceEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'RBF Passenger Info',
    eventProperties: RbfPassengerInfoEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'RBF Date',
    eventProperties: RbfDateEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'RBF Rides',
    eventProperties: RbfRidesEvent
  ): Promise<void>;
  function trackEvent(
    eventName: 'User Logout',
    eventProperties: UserLogoutEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'User Idle',
    eventProperties: UserIdleEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'Ride Booking Flow',
    eventProperties: LEGACY__RideBookingFlowEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'Mobility Assessment Time',
    eventProperties: MobilityAssessmentEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'Page Visit',
    eventProperties: PageVisitEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'Page Navigation',
    eventProperties: PageNavigationEventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'Button Clicked',
    eventProperties: EventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'Member Deeplink Activated',
    eventProperties: MemberDeeplinkActivatedProperties
  ): Promise<void>;
  function trackEvent(
    eventName: AnalyticEvent,
    eventProperties: EventProperties
  ): Promise<void> {
    return analyticsService.trackEvent(eventName, {
      ...eventProperties,
      ...userEventProperties
    });
  }

  /**
   * Tracks a page view in the analytics service.
   * This function does not include user details.
   *
   * @param pageData - Data about the page that was viewed.
   */

  function trackPage(pageData) {
    analyticsService.trackPage(pageData);
  }

  /**
   * Identifies a user in the analytics service.
   *
   * @param userId - The ID of the user.
   * @param userTraits - Traits of the user.
   */

  function identify(userId, userTraits) {
    analyticsService.identify(userId, userTraits);
  }

  // Return the tracking functions so they can be used elsewhere.
  return {
    trackEvent,
    trackPage,
    identify
  };
};

/**
 * useAnalytics HOC:
 * This higher-order component is designed to wrap another component and enhance it
 * with the useAnalytics hook. It's a reusable way of adding analytics functionality
 * to any class-based component without modifying its internal logic and refactoring class-based components to FCs.
 *
 * @param WrappedComponent - The component to be wrapped and enhanced.
 * @returns {React.Component} - The enhanced component with analytics capabilities.
 */
useAnalytics.HOC = Component => {
  return function WrappedComponent(props) {
    const analyticsTools = useAnalytics();
    return <Component {...props} analytics={analyticsTools} />;
  };
};

export default useAnalytics;
