import { isEmpty } from '@SRHealth/frontend-lib';
import axios from 'axios';
import type { MapboxFeature } from '~/Pages/RideBooking/Components/Rides/Rides.types';
import type { LatLng } from '~/types';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
const MAPBOX_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
const DIRECTIONS_URL = 'https://api.mapbox.com/directions/v5/mapbox/driving/';
const TILE_URL = 'https://api.mapbox.com/v4/whit-schrader.927rombv/tilequery/';

/**
 * Fetches forward geocoding coordinates from mapbox
 */
export async function fetchForwardGeocodingCoordinates({ address }) {
  try {
    const geocodeUrl = `${MAPBOX_BASE_URL}${encodeURIComponent(address)}.json?access_token=${MAPBOX_TOKEN}`;
    const response = await axios.get(geocodeUrl);
    return response;
  } catch (e) {
    return;
  }
}

export interface MapboxAddressAutoCompleteResponse {
  type: string;
  query: string[];
  features: MapboxFeature[];
  attribution: string;
}

/**
 * Get mapbox autocomplete address options for a given address search string
 */
export const getMapboxAddressOptions = async (
  addressLookupString: string,
  proximityCoords: LatLng | Record<string, unknown>
) => {
  const lng = proximityCoords?.lng;
  const lat = proximityCoords?.lat;
  let proximity = '';

  if (!isEmpty(lng) && !isEmpty(lat)) {
    proximity = `${lng},${lat}`;
  }

  const encodedAddress = encodeURIComponent(addressLookupString);

  return await fetch(
    `${MAPBOX_BASE_URL}${encodedAddress}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}&proximity=${proximity}&autocomplete=true&country=us&fuzzyMatch=true&routing=true`
  ).then(res => res.json() as Promise<MapboxAddressAutoCompleteResponse>);
};

/** Look up timezone for a set of lat/lng coordinates via mapbox service. */
export const getLatLngTimezone = async (lat: number, lng: number) =>
  fetch(`${TILE_URL}${lng},${lat},.json?limit=1&dedupe=true&access_token=${MAPBOX_TOKEN}`)
    .then(res => res.json())
    .then(res => res?.features?.[0]?.properties?.TZID);

/** Look up directions between two points. */
export const getDirections = async (
  pickupCoords: LatLng,
  dropoffCoords: LatLng,
  optionalParams: Record<string, string | number> = {}
) => {
  const urlParams = new URLSearchParams({
    ...optionalParams,
    access_token: MAPBOX_TOKEN || ''
  });

  return await fetch(
    `${DIRECTIONS_URL}${pickupCoords.lng},${pickupCoords.lat};${dropoffCoords.lng},${dropoffCoords.lat}?${urlParams}`
  ).then(res => res.json());
};
