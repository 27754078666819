import type { MemberProfileStore } from '~/types';
import keyMirror from '~/utilities/keyMirror';

export const ActionTypes = keyMirror({
  CLEAR_ALL_DATA: undefined,
  CLEAR_FORM_DATA: undefined,
  CLEAR_MEMBER_DATA: undefined,

  LOAD_MEMBER_FORM: undefined,
  LOAD_MEMBER_FORM_ERROR: undefined,
  LOAD_MEMBER_FORM_SUCCESS: undefined,

  UPDATE_MEMBER_FORM: undefined,
  UPDATE_MEMBER_FORM_ERROR: undefined,
  UPDATE_MEMBER_FORM_SUCCESS: undefined,

  LOAD_MEMBER_AUDIT_LOGS: undefined,
  LOAD_MEMBER_AUDIT_LOGS_ERROR: undefined,
  LOAD_MEMBER_AUDIT_LOGS_SUCCESS: undefined,

  CLEAR_MEMBER_AUDIT_LOGS: undefined,

  GET_MEMBER_RIDESHARE_PREFERENCES: undefined,
  SET_MEMBER_RIDESHARE_PREFERENCES: undefined,

  GET_MEMBER_MOBILITY_ASSESSMENT: undefined,
  SET_MEMBER_MOBILITY_ASSESSMENT: undefined,

  GET_MILEAGE_REIMBURSEMENT_DRIVERS_START: undefined,
  GET_MILEAGE_REIMBURSEMENT_DRIVERS_ERROR: undefined,
  GET_MILEAGE_REIMBURSEMENT_DRIVERS_SUCCESS: undefined,

  UPDATE_MEMBER_PORTAL_STATUS: undefined,

  GET_MEMBER_PORTAL_INFO_START: undefined,
  GET_MEMBER_PORTAL_INFO_SUCCESS: undefined,
  GET_MEMBER_PORTAL_INFO_ERROR: undefined,

  CREATE_MEMBER_PORTAL_STATUS_SUCCESS: undefined,
  CREATE_MEMBER_PORTAL_STATUS_ERROR: undefined,

  SEND_MEMBER_PORTAL_EMAIL_SUCCESS: undefined,
  SEND_MEMBER_PORTAL_EMAIL_ERROR: undefined
});

export const INITIAL_STATE: MemberProfileStore = {
  error: undefined,
  success: undefined,
  ridesInWindow: [],
  permission: 'view',
  inHttpRequest: false,
  formData: {
    notes: {},
    mobility: {
      attendant_default_mode: {
        id: 0,
        name: 'Attendant Default Mode',
        slug: 'attendant_default_mode',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_flag: {
        id: 0,
        name: 'Flag Member',
        slug: 'member_flag',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_manual_schedule: {
        id: 0,
        name: 'Manual Schedule',
        slug: 'member_manual_schedule',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_default_assistance_needs: {
        id: 0,
        name: 'Member Default Assistance Needs',
        slug: 'member_default_assistance_needs',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_default_attendant_count: {
        id: 0,
        name: 'Member Default Attendant Count',
        slug: 'member_default_attendant_count',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_default_mode: {
        id: 0,
        name: 'Member Default Mode',
        slug: 'member_default_mode',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_ride_alone: {
        id: 0,
        name: 'Ride Alone',
        slug: 'member_ride_alone',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_rideshare_preference: {
        name: 'Rideshare Preference',
        slug: 'member_rideshare_preference',
        values: [],
        default: undefined
      },
      vehicle_types: [],
      memberData: {
        flagged_nemts: [],
        vehicle_type: 0,
        vehicle_sub_type: 0,
        member_flag: 0,
        member_ride_alone: 0,
        member_default_mode: 0,
        member_manual_schedule: 0,
        member_rideshare_preference: undefined,
        member_default_assistance_needs: undefined,
        primary_preferred_nemts: [],
        secondary_preferred_nemts: []
      }
    },
    freeForm: {},
    benefits: {
      eligibleEndDate: '',
      eligibleStartDate: '',
      healthPlanName: '',
      healthSubPlanName: '',
      healthSubPlanId: 0,
      rideBenefit: [],
      disableRequestRide: 0,
      disableRequestRideReason: '',
      allowBenefitOverride: false,
      treatmentsEnabled: false,
      memberPortalSlug: '',
      healthPlanMemberPortal: 0,
      healthSubPlanMemberPortal: 0
    },
    validation: {},
    personalInfo: {
      dateOfBirth: '',
      firstName: '',
      lastName: '',
      email: '',
      homeAddress: '',
      isEligible: 0,
      medicalId: '',
      internalNotes: '',
      otherDetails: '',
      hospitalId: 0,
      mobileNo: '',
      street2: null,
      healthPlanId: 0,
      id: 0,
      client_unique_id: '',
      external_sub_plan_id: '',
      should_prompt_opt_in: null,
      defaultOptOutTextOrAll: false,
      member_ethnicityId: null,
      member_genderId: 0,
      member_primary_phone_typeId: null,
      member_secondary_phone_typeId: null,
      member_primary_phone_opt_outId: null,
      member_primary_phone_preferenceId: null,
      member_secondary_phone_opt_outId: null,
      member_secondary_phone_preferenceId: null,
      member_spoken_languageId: null,
      member_written_languageId: null,
      displayMemberPortal: 0,
      memberPortalActive: 0,
      isMemberPortalSetupComplete: false,
      member_ethnicity: {
        id: 0,
        name: 'Member Ethnicity',
        slug: 'member_ethnicity',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_gender: {
        id: 0,
        name: 'Member Gender',
        slug: 'member_gender',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_primary_phone_opt_out: {
        id: 0,
        name: 'Member Primary Phone Opt Out',
        slug: 'member_primary_phone_opt_out',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_primary_phone_preference: {
        id: 0,
        name: 'Member Primary Phone Preference',
        slug: 'member_primary_phone_preference',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_primary_phone_type: {
        id: 0,
        name: 'Member Primary Phone Type',
        slug: 'member_primary_phone_type',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_secondary_phone_opt_out: {
        id: 0,
        name: 'Member Secondary Phone Opt Out',
        slug: 'member_secondary_phone_opt_out',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_secondary_phone_preference: {
        id: 0,
        name: 'Member Secondary Phone Preference',
        slug: 'member_secondary_phone_preference',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_secondary_phone_type: {
        id: 0,
        name: 'Member Secondary Phone Type',
        slug: 'member_secondary_phone_type',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_spoken_language: {
        id: 0,
        name: 'Member Spoken Language',
        slug: 'member_spoken_language',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_written_language: {
        id: 0,
        name: 'Member Written Language',
        slug: 'member_written_language',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      memberPhoneNumbers: [],
      primaryOptOutValues: [],
      secondaryOptOutValues: [],
      dsnpMedicareAvailable: false
    },
    attendantInfo: {
      attendant_default_mode: {
        id: 0,
        name: 'Attendant Default Mode',
        slug: 'attendant_default_mode',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_flag: {
        id: 0,
        name: 'Flag Member',
        slug: 'member_flag',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_manual_schedule: {
        id: 0,
        name: 'Manual Schedule',
        slug: 'member_manual_schedule',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_default_assistance_needs: {
        id: 0,
        name: 'Member Default Assistance Needs',
        slug: 'member_default_assistance_needs',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_default_attendant_count: {
        id: 0,
        name: 'Member Default Attendant Count',
        slug: 'member_default_attendant_count',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      member_ride_alone: {
        id: 0,
        name: 'Ride Alone',
        slug: 'member_ride_alone',
        description: 0,
        created_at: null,
        updated_at: null,
        values: []
      },
      vehicle_types: [],
      memberData: {
        additionalPassengers: []
      }
    }
  },

  vehicleCompanies: [],
  mobilityAssessment: null,
  mileageReimbursementDrivers: [],
  isLoadingMileageReimbursementDrivers: false,

  memberPortalInfo: {
    id: 0,
    isActive: true,
    ssoEnabled: 0,
    portalDescription: '',
    userLogin: '',
    lastLoginTime: '',
    deactivatedAt: '',
    deactivationReason: ''
  },
  isLoadingMemberPortalInfo: false,

  transportTypes: {},
  serviceLevels: [],
  assistanceNeeds: [],
  mobilityDevices: [],
  driverProvisions: []
};

// Yes we are hardcoding this. No we don't like it. The existing
// logic doesn't distinguish between any types of assistance needs
// but we want the FE to be able to do so. Hence the hardcoded list.
// This __should__ eventually be replaced by changes in the BE.
export const MOBILITY_DEVICES = [
  'Cane',
  'Crutches',
  'Walker',
  'Folding Wheelchair',
  'Standard Manual Wheelchair',
  'XL Manual Wheelchair (>34 inches)',
  'Powered Wheelchair',
  'Scooter',
  'Stretcher/Gurney'
];
