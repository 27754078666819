import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { NOOP } from '~/utilities/helperFunctions';
import {
  dateFactory,
  fundingSourceFactory,
  passengerInfoFactory,
  rideFactory,
  type DateModel,
  type FundingSourceModel,
  type PassengerInfoModel,
  type RideModel
} from '~/models';
import {
  initializeRbfNewThunk,
  initializeDateThunk,
  getTimeRestrictionsThunk
} from './thunks';
import type { RideBookingSection, RideBookingStore } from './RideBooking.types';
import { RIDE_SECTION_ORDER } from './RideBooking.constants';

const INITIAL_STATE: () => RideBookingStore = () => ({
  // Meta data will not be passed into the ride booking request. It's
  // used to track the state of the ride booking form.
  meta: {
    isInitialized: false,
    activeSection: RIDE_SECTION_ORDER['funding-source'],
    previousActiveSection: RIDE_SECTION_ORDER['passenger-info'],
    timeRestrictions: undefined
  },
  passengerInfo: passengerInfoFactory(),
  fundingSource: fundingSourceFactory(),
  date: dateFactory(),
  rides: [rideFactory()]
});

const rideBookingSlice = createSlice({
  name: 'rideBooking',
  initialState: INITIAL_STATE(),
  reducers: {
    reset: () => INITIAL_STATE(),
    /** Mark the store as initialized. */
    initialized: state => {
      state.meta.isInitialized = true;
    },
    /** Set the passenger info model for the ride booking. */
    setPassengerInfo: (state, action: PayloadAction<PassengerInfoModel>) => {
      state.passengerInfo = action.payload;
    },
    /** Set the funding source model for the ride booking. */
    setFundingSource: (state, action: PayloadAction<FundingSourceModel>) => {
      state.fundingSource = action.payload;
    },
    /** Set the date model for the ride booking. */
    setDate: (state, action: PayloadAction<DateModel>) => {
      state.date = action.payload;
    },
    /** Set the rides model(s) for the ride booking. */
    setRides: (state, action: PayloadAction<RideModel[]>) => {
      state.rides = action.payload;
    },
    /** Return to the complete Passenger Info section and begin editing it. */
    editPassengerInfo: state => {
      state.meta.previousActiveSection = state.meta.activeSection;
      state.meta.activeSection = RIDE_SECTION_ORDER['passenger-info'];

      state.date.date = null;
    },
    /** Return to the completed Funding Source section and begin editing it. */
    editFundingSource: state => {
      state.meta.previousActiveSection = state.meta.activeSection;
      state.meta.activeSection = RIDE_SECTION_ORDER['funding-source'];

      state.date.date = null;
    },
    /** Return to the completed Date section and begin editing it. */
    editDate: state => {
      state.meta.previousActiveSection = state.meta.activeSection;
      state.meta.activeSection = RIDE_SECTION_ORDER.date;

      // Wipe out return leg if it exists.
      if (state.rides.length > 1) {
        state.rides = [state.rides[0]];
      }
    },
    editRides: state => {
      state.meta.previousActiveSection = state.meta.activeSection;
      state.meta.activeSection = RIDE_SECTION_ORDER.rides;
    },
    /** Adds and prepopulates a return ride. If the return ride seems
     * like it's missing data from the initial, you may have not
     * committed some changes to the ride model. */
    addReturnRide: (state, action: PayloadAction<RideModel>) => {
      state.rides[1] = action.payload;
    },
    /** Removes the return ride from the ride booking. */
    removeReturnRide: state => {
      state.rides = [state.rides[0]];
    },
    /** Add multiple rides to the ride booking. */
    addRides: (state, action: PayloadAction<RideModel[]>) => {
      state.rides.push(...action.payload);
    },
    /** Remove a ride, by index, from the rides array. */
    removeRide: (state, action: PayloadAction<number>) => {
      state.rides.splice(action.payload, 1);
    },
    /** Mark a section of the RBF as completed. */
    completeSection: (state, action: PayloadAction<RideBookingSection>) => {
      state.meta.previousActiveSection = state.meta.activeSection;
      state.meta.activeSection = RIDE_SECTION_ORDER[action.payload] + 1;
    },
    /** Roll back the active session to the previous session. */
    rollbackActiveSection: state => {
      state.meta.activeSection = state.meta.previousActiveSection;
    }
  },
  extraReducers: builder => {
    // Add extra reducers here
    builder
      .addCase(initializeRbfNewThunk.fulfilled, NOOP)
      .addCase(initializeDateThunk.fulfilled, (state, action) => {
        state.date.dateRestrictions = {
          earliestDate: action.payload.earliestDate,
          latestDate: action.payload.latestDate,
          restrictedDates: action.payload.restrictedDates
        };
      })
      .addCase(getTimeRestrictionsThunk.fulfilled, (state, action) => {
        state.meta.timeRestrictions = action.payload;
      });
  }
});

export const rideBookingReducer = rideBookingSlice.reducer;
export const {
  reset,
  initialized,
  setPassengerInfo,
  setFundingSource,
  setDate,
  setRides,
  editPassengerInfo,
  editFundingSource,
  editDate,
  editRides,
  addReturnRide,
  removeReturnRide,
  completeSection,
  rollbackActiveSection
} = rideBookingSlice.actions;
