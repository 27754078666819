import type { FC } from 'react';
import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@material-ui/core';
import { getMileageReimbursementDrivers } from '~/Modules/memberProfile/actions';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { formatDateRanges } from '~/utilities/timesAndDates';
import LoadingModal from '~/Shared/Components/LoadingModal/LoadingModal';
import { formatPhoneNumber } from '~/utilities/helperFunctions';

interface MileageReimbursementDriversProps {
  passengerId: number;
}

const MileageReimbursementDrivers: FC<MileageReimbursementDriversProps> = ({
  passengerId
}) => {
  const dispatch = useAppDispatch();
  const mileageReimbursementDrivers =
    useAppSelector(state => state.memberProfile.mileageReimbursementDrivers) ?? [];

  const isLoading = useAppSelector(
    state => state.memberProfile.isLoadingMileageReimbursementDrivers
  );

  useEffect(() => {
    dispatch(getMileageReimbursementDrivers(passengerId));
  }, [dispatch, passengerId]);

  if (isLoading) {
    return <LoadingModal label="Loading..." isOpen={isLoading} />;
  }

  return (
    <TableContainer component={Paper} className="parentTab">
      <Table aria-label="mileage reimbursement driver table">
        <TableHead>
          <TableRow>
            <TableCell>Saferide Driver ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Salesforce Driver ID</TableCell>
            <TableCell>Active Dates</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mileageReimbursementDrivers && mileageReimbursementDrivers.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="subtitle2">
                  No Mileage Reimbursement Drivers Found
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            mileageReimbursementDrivers.map(driver => (
              <TableRow key={driver.salesforceDriverId}>
                <TableCell>{driver.id}</TableCell>
                <TableCell
                  style={{
                    color: driver.driverAccountStatus === 'active' ? 'green' : 'red'
                  }}
                >
                  {driver.driverAccountStatus.toUpperCase()}
                </TableCell>
                <TableCell>{driver.firstName}</TableCell>
                <TableCell>{driver.lastName}</TableCell>
                <TableCell>{driver.salesforceDriverId}</TableCell>
                <TableCell style={{ lineHeight: '1.75em', minWidth: '10em' }}>
                  {formatDateRanges(driver.activeDates)}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  {formatPhoneNumber(driver.mobileNo)}
                </TableCell>
                <TableCell>{driver.email}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MileageReimbursementDrivers;
