import { Card, TOKENS } from '@SRHealth/frontend-lib';
import React from 'react';
import type { MemberProfileStore } from '~/types';
import TagEligibility from '~/Shared/Components/TagEligibility';
import type {
  BenefitType,
  RideBenefitsRecord,
  RideBenefitsUnlimitedRecord
} from '../FundingSource.types';
import BenefitDisplay from './BenefitDisplay';
import BenefitUnlimited from './BenefitUnlimited';

const inlineCSS = `
  #fundingSource {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
`;

type HealthPlanProps = {
  benefitsRecord: RideBenefitsRecord | RideBenefitsUnlimitedRecord | undefined;
  memberProfileStore: MemberProfileStore;
  isEligible: boolean;
  eligbilityDates: {
    startDate: string;
    endDate: string;
  };
};

export default function HealthPlan({
  benefitsRecord,
  memberProfileStore,
  isEligible,
  eligbilityDates
}: HealthPlanProps) {
  const benefitType = getBenefitType(benefitsRecord);

  const { startDate, endDate } = eligbilityDates;
  const healthPlanName = memberProfileStore.formData.benefits.healthPlanName;
  const healthSubPlanName = memberProfileStore.formData.benefits.healthSubPlanName;
  const eligibilityStatus = isEligible ? 'eligible' : 'ineligible';

  return (
    <>
      <style>{inlineCSS}</style>
      {healthPlanName ? (
        <Card label="Health Plan">
          <Card.Content>
            <div className="flex flex-col gap-[4px]">
              <div
                className={`reset-div text-base border-b-1 border-${TOKENS.BORDER.NEUTRAL_MD}`}
              >
                {healthPlanName} | {healthSubPlanName}
              </div>
              <TagEligibility status={eligibilityStatus} />
              <div className="flex-1"></div>
            </div>
          </Card.Content>
          <Card.Content label="Plan Dates">
            <div className="flex flex-row gap-[4px] py-[4px] items-center">
              <div className="heading-sm">Start Date:</div>
              <div className="text-base">{startDate}</div>
            </div>
            <div className="flex flex-row gap-[4px] py-[4px] items-center">
              <div className="heading-sm">End Date:</div>
              <div className="text-base">{endDate}</div>
            </div>
          </Card.Content>
          <Card.Content label="Plan Benefits">
            {
              {
                none: null,
                unlimited: <BenefitUnlimited />,
                limited: <BenefitDisplay record={benefitsRecord as RideBenefitsRecord} />
              }[benefitType]
            }
          </Card.Content>
        </Card>
      ) : (
        <Card label="Health Plan">This is a self-funded Health Plan.</Card>
      )}
    </>
  );
}

function getBenefitType(
  benefitsRecord?: RideBenefitsRecord | RideBenefitsUnlimitedRecord
): BenefitType {
  if (!benefitsRecord) return 'none';

  return benefitsRecord.limit === 'Unlimited' ? 'unlimited' : 'limited';
}
