/**
 * Web Vitals Reporting Module
 * https://www.npmjs.com/package/web-vitals
 * This module is designed to measure and report key web vitals to our analytics service.
 *
 * Functions:
 * 1. reportHandler: Captures individual web vitals metrics (like FCP, CLS, etc.) and reports them to the analytics service.
 *    - Metrics are reported with their names, values, and ratings.
 *    - Errors during reporting are logged to the console.
 *
 * 2. reportWebVitals: Sets up the reporting mechanism for web vitals. It takes an optional callback function (onPerfEntry).
 *    - If onPerfEntry is provided and is a function, the web vitals library is dynamically imported.
 *    - The library is then used to measure various web vitals like CLS, FID, FCP, LCP, and TTFB.
 *    - The onPerfEntry function is called with each metric for handling (e.g., logging or sending to analytics).
 *    - Errors during the setup or metric measurement are caught and logged to the console.
 *
 */

import type { ReportHandler, Metric } from 'web-vitals';
import { analyticsService } from '~/services/analytics.service';

/**
 * Function to handle reporting of web vitals.
 * @param {Metric} metric - The web vital metric to report.
 */
const reportHandler = (metric: Metric) => {
  try {
    analyticsService.trackEvent('Web Vitals', {
      webVitalName: metric.name,
      webVitalValue: metric.value,
      webVitalRating: metric.rating
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

/**
 * Function to set up reporting of web vitals.
 * @param {ReportHandler} onPerfEntry - Optional callback function for handling performance entries.
 */
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Dynamically import web vitals library and setup each web vital metric
    import('web-vitals')
      .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry); // Cumulative Layout Shift
        getFID(onPerfEntry); // First Input Delay
        getFCP(onPerfEntry); // First Contentful Paint
        getLCP(onPerfEntry); // Largest Contentful Paint
        getTTFB(onPerfEntry); // Time to First Byte
        // eslint-disable-next-line no-console
      })
      .catch(e => console.error(e)); // Catch and log any errors during import
  }
};

export { reportHandler, reportWebVitals };
