import { call, put, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash-es';
import { stringify } from 'querystring';
import moment from 'moment';
import axios from './safeAxios';
import { token } from '~/utilities/auth.helper';
import { subscribe, unsubscribe } from '~/utilities/pusherRedux';
import {
  CHANNEL_PREFIX_CHATS,
  CHANNEL_PREFIX_CHAT_REQUEST,
  CHANNEL_EVENT_TYPE,
  MAX_HOSPITALS_PUSHER_AUTH
} from '~/constants';
import { GET_USER } from './user';
import { rideCardActions } from './rideCards/rideCards.actions';
import immutable from 'immutability-helper';

export const CHATS_GET_LIST = 'CHATS/get';
export const CHATS_GET_LIST_SUCCESS = 'CHATS/get/success';
export const CHATS_GET_LIST_ERROR = 'CHATS/get/error';
export const CHATS_POST_MESSAGE = 'CHATS/message/post';
export const CHATS_POST_MESSAGE_SUCCESS = 'CHATS/message/post/success';
export const CHATS_POST_MESSAGE_ERROR = 'CHATS/message/post/error';
export const CHATS_PUT_BOOK_RIDE = 'CHATS/request/bookRide';
export const CHATS_PUT_BOOK_RIDE_SUCCESS = 'CHATS/request/bookRide/success';
export const CHATS_PUT_BOOK_RIDE_ERROR = 'CHATS/request/bookRide/error';
export const CHATS_PUT_CANCEL = 'CHATS/request/cancel';
export const CHATS_PUT_CANCEL_SUCCESS = 'CHATS/request/cancel/success';
export const CHATS_PUT_CANCEL_ERROR = 'CHATS/request/cancel/error';
export const CHATS_PUT_READ_MESSAGES = 'CHATS/request/read';
export const CHATS_PUT_READ_MESSAGES_SUCCESS = 'CHATS/request/read/success';
export const CHATS_PUT_READ_MESSAGES_ERROR = 'CHATS/request/read/error';
export const CHATS_PUT_REASSIGN_NEMT = 'CHATS/request/put/reassign';
export const CHATS_PUT_REASSIGN_NEMT_SUCCESS = 'CHATS/request/put/reassign/success';
export const CHATS_PUT_REASSIGN_NEMT_ERROR = 'CHATS/request/put/reassign/error';
export const CHATS_SET_SELECTED = 'CHATS/set/selected';
export const CHATS_EVENT_NEW_CHAT_REQUESTED = 'CHATS/event/request/new';
export const CHATS_EVENT_NEW_CHAT_REQUESTED_RECV = 'CHATS/event/request/new/recv';
export const CHATS_EVENT_ANSWER_CHAT_REQUEST = 'CHATS/event/request/answer';
export const CHATS_EVENT_CLAIM_CHAT_REQUEST = 'CHATS/event/request/claim';
export const CHATS_EVENT_BOOK_CHAT_REQUEST = 'CHATS/event/request/book';
export const CHATS_EVENT_READ_CHAT_REQUEST = 'CHATS/event/request/read';
export const CHATS_EVENT_UPDATE_CHAT_REQUEST = 'CHATS/event/request/update';
export const CHATS_EVENT_CANCEL_CHAT_REQUEST = 'CHATS/event/request/cancel';
export const CHATS_EVENT_REASSIGN_CHAT_REQUEST = 'CHATS/event/request/reassign';
export const CHATS_EVENT_NEW_CHAT_MESSAGE = 'CHATS/event/message/new';
export const CHAT_EVENT_CONFIRM_SUGGESTION = 'CHATS/events/confirmBooking';

/**
 * Wrapper for saga dispatch to get all chats
 * @param  {Object} [params={}] Parameters from react component
 * @return {Function}           Dispatch action function
 */
export const getChats = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHATS_GET_LIST,
      data: params
    });
  };
};

/**
 * Wrapper for saga dispatch to post message
 * @param  {Object} [params={}] Parameters from component
 * @return {Function}           Dispatch function
 */
export const postMessage = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHATS_POST_MESSAGE,
      data: params
    });
  };
};

/**
 * Wrapper for saga dispatch to book a ride
 * @param  {Object} [params={}] Parameters from component
 * @return {Function}           Dispatch function
 */
export const bookRide = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHATS_PUT_BOOK_RIDE,
      data: params
    });
  };
};

/**
 * Wrapper for saga dispatch to cancel a ride
 * @param  {Object} [params={}] Parameters from component
 * @return {Function}           Dispatch function
 */
export const cancelRequest = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHATS_PUT_CANCEL,
      data: params
    });
  };
};

/**
 * Reassign ride request to different NEMT company
 * @param {Object} params Parameters from component
 * @returns {Function} Dispatch action
 */
export const reassignNemt = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHATS_PUT_REASSIGN_NEMT,
      data: params
    });
  };
};

export const confirmSuggestion = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHAT_EVENT_CONFIRM_SUGGESTION,
      data: params
    });
  };
};
/**
 * Wrapper for saga dispatch to mark messages as read
 * @param  {Object} [params={}] Parameters from component
 * @return {Function}           Dispatch function
 */
export const readMessages = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHATS_PUT_READ_MESSAGES,
      data: params
    });
  };
};

/**
 * Select a particular chat request
 * @param {Object} params Parameters from component
 * @returns {Function} Dispatch action
 */
export const selectRequest = (params = {}) => {
  return dispatch => {
    dispatch({
      type: CHATS_SET_SELECTED,
      data: params
    });
  };
};

/**
 * Get list of chats from server
 * @param {Object} action action from user auth success
 * @return {Generator}    Generator function
 */
function* getChatsFromApi(action) {
  try {
    const user = action.data.data.user;
    const userId = user?.id ?? 0;
    const hospitalUsers = action?.data?.data?.hospitalUsers ?? [];

    if (hospitalUsers.length > MAX_HOSPITALS_PUSHER_AUTH) {
      yield put({
        type: CHATS_GET_LIST_ERROR,
        error: 'error',
        message: 'too many facilities'
      });
    } else {
      let results = {};
      // hospital groups may have multiple hospitals -- call lumen endpoint to get all hospitals
      if (user.role === 'HospitalOwner' || user.role === 'HospitalNetworkManager') {
        const config = {
          method: 'GET',
          url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/hospital-network/chat/requests`,
          headers: {
            Authorization: `Bearer ${token()}`
          }
        };
        const callResult = yield call(axios, config);
        _.forIn(callResult?.data?.data?.chats ?? {}, result => {
          results = Object.assign({}, results, result);
        });
      } else {
        // Not a hospital network, we can call liverides endpoint, and we assume we only have one hospital
        const hospital = hospitalUsers[0];

        const config = {
          method: 'GET',
          url: `//${process.env.REACT_APP_LIVERIDES_API}/care/${hospital.userId}/chat/requests`,
          headers: {
            'Accept-Version': process.env.REACT_APP_LIVERIDES_API_VERSION,
            'Authorization': `Bearer ${token()}`
          }
        };

        // append query params
        // return chats where the user initiated the chat and the last message sent
        // was less than 24 hours ago
        config.url += `?${stringify({
          careInitiatorId: userId,
          messageSentFrom: Date.now() - 24 * 60 * 60 * 1000 // 24 hours
        })}`;

        const callResult = yield call(axios, config);
        results = callResult?.data?.data ?? {};
      }

      yield put({ type: CHATS_GET_LIST_SUCCESS, data: results });

      // subscribe to pusher channels
      hospitalUsers.forEach(hospital => subscribeHospitalChannel(hospital.userId));

      // subscribe to request channels
      Object.keys(results).forEach(requestId => subscribeChatRequestChannels(requestId));
    }
  } catch (error) {
    yield put({ type: CHATS_GET_LIST_ERROR, error });
  }
}

/**
 * Post a chat message
 * @param  {Object}    [action={}] Parameters from component
 * @return {Generator}             Generator function
 */
function* postChatMessage(action = {}) {
  try {
    const config = {
      method: 'POST',
      url: `//${process.env.REACT_APP_LIVERIDES_API}/care/${action.data.hospitalUserId}/chat/requests/${action.data.requestId}/messages`,
      headers: {
        'Accept-Version': process.env.REACT_APP_LIVERIDES_API_VERSION,
        'Authorization': `Bearer ${token()}`
      },
      data: action.data
    };

    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: CHATS_POST_MESSAGE_SUCCESS, data });
  } catch (error) {
    yield put({ type: CHATS_POST_MESSAGE_ERROR, error });
  }
}

/**
 * Update chat request and book a ride
 * @param  {Object}    [action={}] Parameters from component
 * @return {Generator}             Generator function
 */
function* putChatRequestBookRide(action = {}) {
  try {
    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_LIVERIDES_API}/care/${action.data.hospitalUserId}/chat/requests/${action.data.requestId}/book`,
      headers: {
        'Accept-Version': process.env.REACT_APP_LIVERIDES_API_VERSION,
        'Authorization': `Bearer ${token()}`
      },
      withCredentials: true,
      data: action.data.confirmData
    };
    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: CHATS_PUT_BOOK_RIDE_SUCCESS, data });
  } catch (error) {
    // error sent back from laravel needs to be parsed for display
    const response = JSON.parse(error.request.response);
    const data = {
      requestId: action.data.requestId,
      error: new Error(response.error)
    };
    yield put({ type: CHATS_PUT_BOOK_RIDE_ERROR, data });
  }
}

/**
 * Cancel chat request
 * @param  {Object}    [action={}] Parameters from component
 * @return {Generator}             Generator function
 */
function* putCancelRequest(action = {}) {
  try {
    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_LIVERIDES_API}/care/${action.data.hospitalUserId}/chat/requests/${action.data.requestId}/cancel`,
      headers: {
        'Accept-Version': process.env.REACT_APP_LIVERIDES_API_VERSION,
        'Authorization': `Bearer ${token()}`
      },
      data: action.data ? action.data : {}
    };

    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: CHATS_PUT_CANCEL_SUCCESS, data });

    // unsubscribe from request
    unsubscribeChatRequestChannel(action.data.requestId);
  } catch (error) {
    yield put({ type: CHATS_PUT_CANCEL_ERROR, error });
  }
}

/**
 * Update chat request to mark messages as read
 * @param  {Object}    [action={}] Parameters from component
 * @return {Generator}             Generator function
 */
function* putReadChatMessages(action = {}) {
  if (_.isNil(action.data.requestId) || _.get(action, 'data.requestId', '') === '') {
    return;
  }
  try {
    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_LIVERIDES_API}/care/${action.data.hospitalUserId}/chat/requests/${action.data.requestId}/read`,
      headers: {
        'Accept-Version': process.env.REACT_APP_LIVERIDES_API_VERSION,
        'Authorization': `Bearer ${token()}`
      },
      data: action.data
    };

    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: CHATS_PUT_READ_MESSAGES_SUCCESS, data });
  } catch (error) {
    yield put({ type: CHATS_PUT_READ_MESSAGES_ERROR, error });
  }
}

/**
 * Intercept new chat request pusher event and filter user events
 * @param  {Object}    [action={}] Action from pusher event
 * @return {Generator}             Generator function
 */
function* receiveNewChatRequest(action = {}) {
  const user = yield select(state => state.user);
  const data = action.data;
  const userId = _.get(user, 'userData.id', 0);
  const careInitiatorId = _.get(data, 'careInitiatorId', -1);

  if (userId === careInitiatorId) {
    yield put({ type: CHATS_EVENT_NEW_CHAT_REQUESTED, data });
  }
}

/**
 * Update a chat request with new NEMT company info
 * @param {Object} action Dispatch action
 * @returns {undefined}
 */
function* putReassignNemt(action = {}) {
  try {
    const params = action.data;
    const postData = {
      newVehicleOwnerId: params.vehicleOwnerId
    };

    // if request is fromcare, adjust pickup time
    const chats = yield select(state => state.chats);
    const chatRequest = chats[params.requestId];
    const appointmentTime = moment(chatRequest.rideDetails.appointmentTime, 'HH:mm');
    const bookingType = chatRequest.rideDetails.booking_type;
    if (bookingType === 'fromcare' && appointmentTime.isBefore(moment())) {
      postData.newAppointmentTime = moment().add(5, 'minutes').format('HH:mm');
    }

    const config = {
      method: 'PUT',
      url: `//${process.env.REACT_APP_LIVERIDES_API}/care/${params.hospitalUserId}/chat/requests/${params.requestId}/reassign`,
      headers: {
        'Accept-Version': process.env.REACT_APP_LIVERIDES_API_VERSION,
        'Authorization': `Bearer ${token()}`
      },
      data: postData
    };

    const results = yield axios(config);
    const data = results.data.data;
    yield put({ type: CHATS_PUT_REASSIGN_NEMT_SUCCESS, data, params });

    // bypass live chat and book directly if listed company (e.g. gridworks holding company)
    const bypassCompanies =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? [77, 128, 133, 181]
        : process.env.REACT_APP_ENVIRONMENT === 'staging'
          ? [39, 44, 21, 65]
          : [];
    if (bypassCompanies.indexOf(params.vehicleOwnerId) !== -1) {
      const bookData = {
        requestId: params.requestId,
        hospitalUserId: params.hospitalUserId,
        confirmData: {
          acceptedAt: Date.now()
        }
      };
      yield put({ type: CHATS_PUT_BOOK_RIDE, data: bookData });
    }
  } catch (error) {
    yield put({ type: CHATS_PUT_REASSIGN_NEMT_ERROR, error });
  }
}

/**
 * Subscribe to hospital channels to listen for various status events
 * @param  {Number}     [hospitalId=0] Hospital ID
 * @return {undefined}
 */
const subscribeHospitalChannel = (hospitalId = 0) => {
  const hospitalChannel = `${CHANNEL_PREFIX_CHATS}-${hospitalId}`;
  subscribe(
    hospitalChannel,
    CHANNEL_EVENT_TYPE.NEW_CHAT_REQUEST,
    CHATS_EVENT_NEW_CHAT_REQUESTED_RECV
  );
  subscribe(
    hospitalChannel,
    CHANNEL_EVENT_TYPE.ANSWER_CHAT_REQUEST,
    CHATS_EVENT_ANSWER_CHAT_REQUEST
  );
  subscribe(
    hospitalChannel,
    CHANNEL_EVENT_TYPE.CLAIM_CHAT_REQUEST,
    CHATS_EVENT_CLAIM_CHAT_REQUEST
  );
  subscribe(
    hospitalChannel,
    CHANNEL_EVENT_TYPE.BOOK_CHAT_REQUEST,
    CHATS_EVENT_BOOK_CHAT_REQUEST
  );
  subscribe(
    hospitalChannel,
    CHANNEL_EVENT_TYPE.CANCEL_CHAT_REQUEST,
    CHATS_EVENT_CANCEL_CHAT_REQUEST
  );
  subscribe(
    hospitalChannel,
    CHANNEL_EVENT_TYPE.REASSIGN_CHAT_REQUEST,
    CHATS_EVENT_REASSIGN_CHAT_REQUEST
  );
  subscribe(hospitalChannel, 'pull', rideCardActions.PUSHER_PULL, null, hospitalChannel);
};

/**
 * Subscribe to chat request channels
 * @param  {String}     [requestId=''] Request ID
 * @return {undefined}
 */
const subscribeChatRequestChannels = (requestId = '') => {
  const requestChannel = `${CHANNEL_PREFIX_CHAT_REQUEST}-${requestId}`;
  subscribe(
    requestChannel,
    CHANNEL_EVENT_TYPE.READ_CHAT_REQUEST,
    CHATS_EVENT_READ_CHAT_REQUEST
  );
  subscribe(
    requestChannel,
    CHANNEL_EVENT_TYPE.UPDATE_CHAT_REQUEST,
    CHATS_EVENT_UPDATE_CHAT_REQUEST
  );
  subscribe(
    requestChannel,
    CHANNEL_EVENT_TYPE.NEW_CHAT_MESSAGE,
    CHATS_EVENT_NEW_CHAT_MESSAGE
  );
  subscribe(requestChannel, 'pull', rideCardActions.PUSHER_PULL, requestChannel);
};

/**
 * Unsubscribe to chat request channel
 * @param  {String}     [requestId=''] Request ID
 * @return {undefined}
 */
const unsubscribeChatRequestChannel = (requestId = '') => {
  const requestChannel = `${CHANNEL_PREFIX_CHAT_REQUEST}-${requestId}`;
  unsubscribe(requestChannel);
};

/**
 * Saga function for chats
 * @return {Generator} Generator function of functions
 */
export function* chatsSaga() {
  yield takeEvery(GET_USER, getChatsFromApi);
  yield takeEvery(CHATS_POST_MESSAGE, postChatMessage);
  yield takeEvery(CHATS_PUT_BOOK_RIDE, putChatRequestBookRide);
  yield takeEvery(CHATS_PUT_CANCEL, putCancelRequest);
  yield takeEvery(CHATS_PUT_READ_MESSAGES, putReadChatMessages);
  yield takeEvery(CHATS_EVENT_NEW_CHAT_REQUESTED_RECV, receiveNewChatRequest);
  yield takeEvery(CHATS_PUT_REASSIGN_NEMT, putReassignNemt);
}

/**
 * Reducer for all chats actions
 * @param  {Object} [state={}]  Existing state
 * @param  {Object} [action={}] Dispatcher action
 * @return {undefined}
 */
export const chatsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case CHATS_GET_LIST_SUCCESS: {
      return typeof action.data === 'object' ? action.data : state;
    }
    case CHATS_EVENT_NEW_CHAT_REQUESTED: {
      const chatRequest = state?.action?.data?.requestId ?? {};
      const isAutoScheduled = action?.data?.rideDetails?.isAutoScheduled ?? false;

      state = immutable(state, {
        [action.data.requestId]: {
          $set: Object.assign(chatRequest, action.data, {
            confirmed: false
          })
        }
      });

      // set as selected if not auto-scheduled
      if (!isAutoScheduled) {
        for (const requestId in state) {
          state = immutable(state, {
            [requestId]: { selected: { $set: requestId === action.data.requestId } }
          });
        }
      }

      // subscribe
      subscribeChatRequestChannels(action.data.requestId);
      return state;
    }
    case CHATS_EVENT_ANSWER_CHAT_REQUEST:
    case CHATS_EVENT_CLAIM_CHAT_REQUEST:
    case CHATS_EVENT_READ_CHAT_REQUEST:
    case CHATS_EVENT_UPDATE_CHAT_REQUEST:
    case CHATS_EVENT_BOOK_CHAT_REQUEST:
    case CHATS_EVENT_CANCEL_CHAT_REQUEST:
    case CHATS_EVENT_REASSIGN_CHAT_REQUEST:
    case CHATS_EVENT_NEW_CHAT_MESSAGE: {
      const chatRequest = state?.[action.data.requestId] ?? {};

      return immutable(state, {
        [action.data.requestId]: {
          $set: Object.assign(chatRequest, action.data)
        }
      });
    }
    case CHATS_SET_SELECTED: {
      for (const requestId in state) {
        state = immutable(state, {
          [requestId]: { selected: { $set: requestId === action.data.requestId } }
        });
      }

      return state;
    }
    case CHAT_EVENT_CONFIRM_SUGGESTION: {
      return immutable(state, {
        [action.data.requestId]: {
          $set: { ...state[action.data.requestId], confirmed: true }
        }
      });
    }
    default: {
      return state;
    }
  }
};
