import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingModal from '~/Shared/Components/LoadingModal';
import { getMemberAuditLogs } from '~/Modules/memberProfile/actions';
import { Container } from '@material-ui/core';

class MemberAudit extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      selectedId: passengerId,
      currentPassengerId,
      getMemberAuditLogs
    } = this.props;

    if (passengerId !== 0 && passengerId !== currentPassengerId) {
      getMemberAuditLogs({ passengerId });
    }
  }

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const { events, isLoading } = this.props;

    const rows = events.map(
      ({ userName = '', activityTime = '', message = '', organizationName = '' }, i) => {
        const orgName = organizationName === '0' ? 'SafeRide Health' : organizationName;

        return (
          <tr key={i}>
            <td width="190">{activityTime}</td>
            <td>{`${userName} at ${orgName} ${message}`}</td>
          </tr>
        );
      }
    );

    if (isLoading) {
      return <LoadingModal label="Loading member's Audit Log..." isOpen={isLoading} />;
    }

    return (
      <Container className="parentTab audit">
        <div className="childTab audit">
          <table>
            <thead>
              <tr>
                <td className="bold">Activity</td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </Container>
    );
  }
}

MemberAudit.getNavOptions = () => [];

MemberAudit.propTypes = {
  events: PropTypes.array,
  timezone_format: PropTypes.string,
  getMemberAuditLogs: PropTypes.func,
  setEditButton: PropTypes.func,
  setErrorMsg: PropTypes.func
};

MemberAudit.defaultProps = {
  events: [],
  timezone_format: '',
  isLoading: false,
  currentPassengerId: 0,
  setEditButton: () => {},
  setErrorMsg: () => {},
  getMemberAuditLogs: () => {}
};

const mapStateToProps = state => ({
  events: get(state, 'memberAuditLogs.activities', []),
  currentPassengerId: get(state, 'memberAuditLogs.currentPassengerId', 0),
  isLoading: get(state, 'memberAuditLogs.inHttpRequest', false),
  timezone_format: state.user.userData.timezone_format
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMemberAuditLogs: data => getMemberAuditLogs(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MemberAudit);
