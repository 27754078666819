import { isRideTimeString, type AddressProps, type RideModel } from '~/models';
import { getDirections } from '~/services/mapbox.service';
import type { MapMarker } from './subcomponents/RideBookingMap';
import { Is } from '@SRHealth/frontend-lib';
import { metersToMiles } from '~/utilities/distances';

/** Converts the values on an address model to a map marker. */
export function addressToMarker(
  address: AddressProps,
  type: 'depart' | 'arrive'
): MapMarker {
  return {
    lat: address.latitude,
    lng: address.longitude,
    type
  };
}

/** Converts the depart and arrive address models/props on a ride
 * to map markers */
export function rideToMarkers(ride: RideModel): { depart: MapMarker; arrive: MapMarker } {
  return {
    depart: addressToMarker(ride.departAddress, 'depart'),
    arrive: addressToMarker(ride.arriveAddress, 'arrive')
  };
}

const getAddressLatLng = (address: AddressProps) => ({
  lat: address?.latitude,
  lng: address?.longitude
});

/** Attempts to determine if we can use optional params when querying route
 * date from the mapbox service, for more accurate results. */
const getRideRouteOptions = (ride: RideModel, date: IsoDate | null) => {
  const options: Record<string, string | number> = {};

  if (date && ride.time && isRideTimeString(ride.time)) {
    const time: (string | number)[] = ride.time?.split(' ', 1)[0].split(':');

    const meridianOffset = time[0] === '12' || ride.time?.includes('am') ? 0 : 12;

    time[0] = +time[0] + meridianOffset;
    if (time[0] < 10) time[0] = `0${time[0]}`;

    const timestamp = `${date}T${time[0]}:${time[1]}`;

    if (ride.type === 'arriveBy') {
      options.arrive_by = timestamp;
    } else if (ride.type === 'departAt') {
      options.depart_at = timestamp;
    }
  }

  return options;
};

/** Obtain the driving distance between depart and arrive addresses. Returns
 * an array with the distance in miles and the duration in seconds. */
export async function getRideRouteData(
  ride: RideModel,
  date: IsoDate | null
): Promise<[string, number] | undefined> {
  if (!ride.departAddress || !ride.arriveAddress) return undefined;

  const depart = getAddressLatLng(ride.departAddress.toJSON());
  const arrive = getAddressLatLng(ride.arriveAddress.toJSON());

  if (!depart.lat || !depart.lng || !arrive.lat || !arrive.lng) return undefined;

  // Try to incorporate the optional route params for better route results.
  const options = getRideRouteOptions(ride, date);

  const directions = await getDirections(depart, arrive, options);

  if (!directions) return undefined;

  const distance = metersToMiles(directions?.routes?.[0]?.distance).toFixed(2);
  const duration = directions?.routes?.[0]?.duration / 60;

  return Is.Nil(directions?.routes?.[0]?.distance) ? undefined : [distance, duration];
}
