import React, { useCallback, useContext, useEffect } from 'react';
import { Button, Icon, Input, Link, TOKENS, useModel } from '@SRHealth/frontend-lib';
import type { AddressModel, RideModel, RideTime, RideType } from '~/models';
import Address from './Address';
import Divider from './Divider';
import type { AddressFieldType } from '../Rides.types';
import { MapContext } from './RideBookingMap';
import { addressToMarker } from '../Rides.utils';
import { getLatLngTimezone } from '~/services/mapbox.service';
import RidesTimePicker from './RidesTimePicker';

export type RideCardFormProps = {
  ride: RideModel;
  onFlipAddresses: () => void;
  onAddRide?: () => void;
  onRemoveRide?: () => void;
  onSaveEdit?: () => void;
  onCancelEdit?: () => void;
};

export default function RideCardForm({
  ride,
  onFlipAddresses,
  onAddRide,
  onRemoveRide,
  onSaveEdit,
  onCancelEdit
}: RideCardFormProps) {
  const { model } = useModel(ride);
  const [_, markerActions] = useContext(MapContext);

  const isEdit = !onAddRide && onSaveEdit && onCancelEdit;
  const isReturnRide = !isEdit && !onAddRide;
  const isNewRide = !isEdit && !isReturnRide;

  /** Event handler for updating a ride address. Necessary to let us grab
   * the timezone for the model. */
  const handleAddressChange = useCallback(
    (type: AddressFieldType, address: AddressModel) => {
      ride[`${type}Address`] = address;

      if (address.latitude && address.longitude) {
        markerActions.setMarkers([addressToMarker(address, type)]);
      }
    },
    [markerActions, ride]
  );

  const handleTimeChange = useCallback(
    (_, value: RideTime) => (ride.time = value),
    [ride]
  );

  useEffect(() => {
    if (model.type === 'arriveBy') return;

    if (!model.departAddress.latitude || !model.departAddress.longitude) {
      model.timezone = undefined;
    }

    getLatLngTimezone(model.departAddress.latitude, model.departAddress.longitude).then(
      timezone => (model.timezone = timezone)
    );
  }, [model.type, model.departAddress]);

  useEffect(() => {
    if (model.type === 'departAt') return;

    if (!model.arriveAddress.latitude || !model.arriveAddress.longitude) {
      model.timezone = undefined;
    }

    getLatLngTimezone(model.arriveAddress.latitude, model.arriveAddress.longitude).then(
      timezone => (model.timezone = timezone)
    );
  }, [model.type, model.arriveAddress]);

  return (
    <>
      <article
        className="reset-div py-[24px] px-[8px] flex flex-col gap-[8px] self-center"
        style={{ width: '436px' }}
      >
        <div className="flex flex-col gap-[16px] pb-[8px] relative">
          <Address
            type="depart"
            address={model.departAddress}
            onAddressChange={handleAddressChange}
          />
          <Address
            type="arrive"
            address={model.arriveAddress}
            onAddressChange={handleAddressChange}
          />
          <Icon
            type="SwitchDirections"
            title="Flip Addresses"
            onClick={onFlipAddresses}
            className={
              `absolute w-[24px] h-[24px] fill-current cursor-pointer ` +
              `text-${TOKENS.COMPONENT.INPUT.SELECTED} hover:text-${TOKENS.COMPONENT.BUTTON.HOVER}`
            }
            style={{ top: '72px', right: 0 }}
          />
        </div>
        <Divider />
        <div className="flex flex-row">
          <div style={{ flex: '3', visibility: isReturnRide ? 'hidden' : 'visible' }}>
            <Input
              inputId={2}
              type="radio"
              name="type"
              label=" "
              options={[
                { label: 'Arrive By', value: 'arriveBy' },
                { label: 'Depart At', value: 'departAt' }
              ]}
              value={model.type}
              onChange={(_, value: RideType) => (model.type = value)}
              error={'type' in model.ruleErrors}
              className="flex-1"
            />
          </div>
          <div style={{ flex: '2' }}>
            <RidesTimePicker
              inputId={3}
              type={model.type}
              time={model.time}
              timezone={model.timezone}
              onChange={handleTimeChange}
              error={'time' in model.ruleErrors}
            />
          </div>
        </div>
        <Divider />
        <div className="flex flex-col">
          <Input
            inputId={4}
            type="textarea"
            name="notes"
            label="Ride Note"
            placeholder="Any additional message for the driver"
            caption="For this ride only"
            value={model.notes}
            onChange={(_, value: string) => (model.notes = value)}
            error={'notes' in model.ruleErrors}
          />
        </div>
      </article>

      <footer
        className="flex flex-col px-[8px] items-center gap-[8px] self-stretch"
        style={{ paddingBottom: '24px' }}
      >
        <Divider />
        {isNewRide && (
          <>
            <div className={`text-sm-italic text-center text-${TOKENS.FONT.NEUTRAL}`}>
              Does the member need a return ride?
            </div>
            <Button
              label="Return Ride"
              iconLeft="AddCircleOutline"
              size="sm"
              onClick={onAddRide}
              alt
            />
          </>
        )}

        {isEdit && (
          <div className="flex flex-row">
            <Button label="Save Edit" size="sm" onClick={onSaveEdit} />
            <Button label="Cancel Edit" size="sm" onClick={onCancelEdit} alt />
          </div>
        )}

        {isReturnRide && (
          <div
            className="reset-div flex flex-row"
            style={{ width: '436px', justifyContent: 'flex-end' }}
          >
            <Link
              id="remove-ride"
              label="Remove Return"
              iconLeft="RemoveCircleOutline"
              size="sm"
              onClick={onRemoveRide}
            />
          </div>
        )}
      </footer>
    </>
  );
}
