import type { RideBookingStore } from './RideBooking.types';

/**
 * Determine if the date selected is in the past.
 * @param date
 * @returns
 */
export function isPastBooking(date: RideBookingStore['date']['date']): boolean {
  if (!date) return false;

  const dateArr = date.split('-');

  if (Number(date[0]) > new Date().getFullYear()) return false;

  if (Number(dateArr[1]) > new Date().getMonth()) return false;

  return (
    Number(date[0]) <= new Date().getFullYear() &&
    Number(dateArr[1]) <= new Date().getMonth() + 1 &&
    Number(dateArr[2]) < new Date().getDate()
  );
}
