import type { UserStore, DependentField as DependentFieldRecord } from '~/Modules/user';
import type { PatientsStore } from '~/Modules/patients';
import { Is } from '@SRHealth/frontend-lib';
import type { MemberProfileStore } from '~/types';
import type { FundingSourceProps } from '~/models';
import type {
  RideBenefitsRecord,
  RideBenefitsUnlimitedRecord
} from './FundingSource.types';
import { convertISOToDisplayDate } from '~/utilities/timesAndDates';

/** The displayed label if the passenger has a rides/year benefit. This
 * value is used in a comparison check for which benefit we are displaying
 * in the UI. */
export const RIDES_PER_YEAR_LABEL = 'Rides/Year';
/** The displayed label if the passenger has a rides/month benefit. */
export const RIDES_PER_MONTH_LABEL = 'Rides/Month';

/** Retrieve the list of hospitals/facilities available to the passenger.
 * This is largely defined by the user store, and user's role. */
export function getFacilities(user: UserStore) {
  let hospitals = user?.hospitalData || [];

  // Standardize this because Hospital Owner and Hospital Network Manager
  // have different data structures.
  if (!Array.isArray(hospitals)) hospitals = [hospitals];

  return hospitals
    .map(hospital => ({
      id: hospital.id,
      label: hospital.hospitalName,
      value: hospital.id
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

/** Identify if the patient's subplan has treatments enabled and
 * return the list of treatments if so. */
export function getTreatments(patientDetails: PatientsStore['patientDetails']) {
  const showTreatments = patientDetails?.treatments;

  if (showTreatments) {
    return patientDetails?.health_sub_plan_treatments ?? [];
  }

  return undefined;
}

/** The BE sends a list of custom fields in different formats based on
 * user role. Unfortunately we cannot fix that without breaking legacy RBF
 * and other parts of the app. This function standardizes the format so that
 * the data structure is consistent. */
function standardizeCustomFieldFormat(
  fields?: Record<number, BE.HospitalCustomField[]> | BE.HospitalCustomField[]
): Record<number, BE.HospitalCustomField[]> {
  if (!fields) return {};

  if (Is.JsonObject(fields)) return fields;

  return Object.values(fields).reduce((acc, field) => {
    acc[field.hospitalId] ??= [];
    acc[field.hospitalId].push(field);
    return acc;
  }, {});
}

/** Retrieves the custom fields for the user's selected hospital. This is dependent
 * on the facilityId set in the FundingSource model. */
export function getHospitalCustomFields(user: UserStore, facilityId: number | undefined) {
  if (Is.Undefined(facilityId)) return [];

  const patientCustomFields = standardizeCustomFieldFormat(user?.patientCustomFields);
  const rideCustomFields = standardizeCustomFieldFormat(user?.rideCustomFields);

  let customFields: BE.HospitalCustomField[] = [];

  if (!Array.isArray(patientCustomFields) && facilityId in patientCustomFields) {
    customFields = customFields.concat(patientCustomFields[facilityId]);
  }

  if (!Array.isArray(rideCustomFields) && facilityId in rideCustomFields) {
    customFields = customFields.concat(rideCustomFields[facilityId]);
  }

  return customFields;
}

/** Retrieves the rides benefits for the member. Prioritizes displaying
 * rides per year and falling back to rides per month. If the plan is
 * Medicaid then nothing is returned. */
export function getRideBenefits(user: UserStore, memberProfileStore: MemberProfileStore) {
  const subPlanId = memberProfileStore.formData.benefits.healthSubPlanId;
  const subPlan = user?.healthPlans?.find(
    ({ healthSubPlanId }) => healthSubPlanId === subPlanId
  );

  if (subPlan?.benefitsProgram === 'medicaid') {
    return {
      label: RIDES_PER_YEAR_LABEL,
      limit: 'Unlimited'
    } as RideBenefitsUnlimitedRecord;
  }

  const rideBenefits = memberProfileStore.formData.benefits.rideBenefit;

  // Why yes, this is a very silly way to do this but the alternative is rewriting
  // how we store benefits on the FE and adding the time to do that correctly, as well
  // as QA the member profile that uses that data is too expensive for RBF 4.0.
  const yearlyBenefit = rideBenefits.find(
    benefit => 'rideLimit' in benefit && benefit.usageYear.toLowerCase().includes('rides')
  );

  if (yearlyBenefit) {
    return {
      label: RIDES_PER_YEAR_LABEL,
      limit: yearlyBenefit.rideLimit,
      usage: yearlyBenefit.rideUsage,
      alertAfter: yearlyBenefit.alertAfter || 0,
      hardBlock: yearlyBenefit.hardBlock ?? false
    } as RideBenefitsRecord;
  }

  const monthlyBenefit = rideBenefits.find(
    benefit =>
      'rideLimit' in benefit && benefit.usageMonth.toLowerCase().includes('rides')
  );

  if (monthlyBenefit) {
    return {
      label: RIDES_PER_MONTH_LABEL,
      limit: monthlyBenefit.rideLimit,
      usage: monthlyBenefit.rideUsage,
      alertAfter: monthlyBenefit.alertAfter || 0,
      hardBlock: monthlyBenefit.hardBlock ?? false
    } as RideBenefitsRecord;
  }
}

/** Automatically scroll to the bottom of the footer so that the TripQuestions
 * component is in view for the user. */
export function scrollToFooter(ref: React.RefObject<HTMLDivElement>) {
  if (!ref.current) return;

  ref.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
}

/** Crawls the dependent fields and extracts the field label and value.
 * Used primarily in the Summary component. This is what happens when we
 * let Hayk rewrite data for FE consumption. */
export function crawlDependentFields(
  field: DependentFieldRecord,
  modelData: Readonly<FundingSourceProps>
) {
  const collection: { label: string; value: string }[] = [];

  let _field = field;
  for (const fieldId in modelData.dependentFields) {
    const record = _field.compliance_options.find(
      ({ id }) => id === modelData.dependentFields[fieldId]
    );

    const label = _field.input_name;
    const value = record?.option_value ?? '';

    _field = _field.dependent_field!;

    collection.push({ label, value });
  }

  return collection;
}

/** Retrieves the eligibility dates from the member profile. */
export function getEligibilityDates(memberProfile: MemberProfileStore) {
  const { eligibleStartDate, eligibleEndDate } = memberProfile.formData.benefits;

  const startDate = eligibleStartDate?.split(' ')?.[0] as IsoDate | undefined;
  const endDate = eligibleEndDate?.split(' ')?.[0] as IsoDate | undefined;

  return {
    startDate: startDate ? convertISOToDisplayDate(startDate) : '',
    endDate: endDate ? convertISOToDisplayDate(endDate) : ''
  };
}
