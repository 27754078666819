import _ from 'lodash-es';
import axios from '../safeAxios';
import { ActionTypes } from './constants';
import { MEMBERS_SET } from '~/Modules/members';
import { put, takeEvery } from 'redux-saga/effects';
import {
  getRidesharePreferences,
  getMobilityAssessment
} from '~/services/member.service';
import { baseRequestConfig } from '~/utilities/auth.helper';

const {
  LOAD_MEMBER_FORM,
  UPDATE_MEMBER_FORM,
  LOAD_MEMBER_FORM_ERROR,
  LOAD_MEMBER_FORM_SUCCESS,
  UPDATE_MEMBER_FORM_ERROR,
  UPDATE_MEMBER_FORM_SUCCESS,
  LOAD_MEMBER_AUDIT_LOGS,
  LOAD_MEMBER_AUDIT_LOGS_ERROR,
  LOAD_MEMBER_AUDIT_LOGS_SUCCESS,
  GET_MEMBER_RIDESHARE_PREFERENCES,
  SET_MEMBER_RIDESHARE_PREFERENCES,
  SET_MEMBER_MOBILITY_ASSESSMENT,
  GET_MILEAGE_REIMBURSEMENT_DRIVERS_START,
  GET_MILEAGE_REIMBURSEMENT_DRIVERS_ERROR,
  GET_MILEAGE_REIMBURSEMENT_DRIVERS_SUCCESS
} = ActionTypes;

/**
 * generator for getting member profile
 * @param {object} action - action data
 * @return {void} doesn't return anything but yield
 */
function* getMemberProfileFromApi(action = {}) {
  const { passengerId, subPlanId, externalSubPlanId } = action.data;

  const config = {
    ...baseRequestConfig(),
    method: 'GET',
    params: {},
    url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/view/${passengerId}`
  };

  if (subPlanId) {
    config.params.subPlanId = subPlanId;
  }

  if (externalSubPlanId) {
    config.params.externalSubPlanId = externalSubPlanId;
  }

  try {
    const results = yield axios(config);

    if (results?.data?.status === true) {
      // We are unfortunately passing either the passenger id or the client unique id to the
      // getMemberProfileFromApi function. Pull the _passengerId_ directly from the
      // member response payload
      const mobilityAssessment = yield getMobilityAssessment(
        results.data.data.member_data._id
      );

      if ('data' in mobilityAssessment) {
        yield put({
          type: SET_MEMBER_MOBILITY_ASSESSMENT,
          data: mobilityAssessment?.data
        });
      }

      yield put({
        type: LOAD_MEMBER_FORM_SUCCESS,
        passengerId: passengerId,
        data: results.data?.data ?? [],
        user: action.user,
        timestamp: results.data?.timestamp ?? {}
      });
    } else {
      yield put({
        type: LOAD_MEMBER_FORM_ERROR,
        data: { errors: [results?.data?.message] }
      });
    }
  } catch (error) {
    const errors = _.isEmpty(error?.response?.data?.errors)
      ? ['Error getting member profile.']
      : error?.response?.data?.errors;

    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      // eslint-disable-next-line no-console
      console.error('Failed to load member profile', error);
    }

    yield put({ type: LOAD_MEMBER_FORM_ERROR, data: { errors } });
  }
}

/**
 * generator for getting member audit logs
 * @param {object} action - action data
 * @return {void} doesn't return anything but yield
 */
function* getMemberAuditLogsFromApi(action = {}) {
  const { passengerId } = action.data;
  const config = baseRequestConfig();
  config.method = 'GET';
  config.url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${passengerId}/audit-logs`;
  try {
    const results = yield axios(config);
    const responseData = _.get(results, 'data.data', []);
    const timestamp = _.get(results, 'data.timestamp', {});
    if (_.get(results, 'data.status', false) === true) {
      yield put({
        type: LOAD_MEMBER_AUDIT_LOGS_SUCCESS,
        data: responseData,
        timestamp,
        passengerId,
        user: action.user
      });
    } else {
      const errors = [_.get(results, 'data.message')];
      yield put({
        type: LOAD_MEMBER_AUDIT_LOGS_ERROR,
        data: { errors }
      });
    }
  } catch (error) {
    let errors;
    if (_.isEmpty(_.get(error, 'response.data.errors'), [])) {
      errors = ['Error getting member audit logs.'];
    } else {
      errors = _.get(error, 'response.data.errors');
    }
    yield put({
      type: LOAD_MEMBER_AUDIT_LOGS_ERROR,
      data: { errors }
    });
  }
}

/**
 * generator for getting editing member profile
 * @param {object} action - action data
 * @return {void} doesn't return anything but yield
 */
function* putMemberProfileToApi(action = {}) {
  const params = action.data;
  const { passengerId, memberProfile, formType, subPlanId, externalSubPlanId } = params;

  const config = baseRequestConfig();
  config.method = 'PUT';
  config.url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/update/${passengerId}`;
  config.data = memberProfile;

  config.params = {};

  if (subPlanId) {
    config.params.subPlanId = subPlanId;
  }

  if (externalSubPlanId) {
    config.params.externalSubPlanId = externalSubPlanId;
  }

  if ('member_rideshare_preference' in config.data) {
    config.data.member_rideshare_preference ??= null;
  }

  try {
    const results = yield axios(config);
    if (results?.data?.status) {
      yield put({
        type: UPDATE_MEMBER_FORM_SUCCESS,
        data: {
          passengerId,
          memberProfile,
          formType
        }
      });

      //Update search listings
      yield put({
        type: MEMBERS_SET,
        data: {
          id: passengerId,
          ...memberProfile
        }
      });

      yield* getMemberProfileFromApi({
        data: { ...params, passengerId },
        user: action.user
      });
    } else {
      const errors = [results?.data?.message];
      yield put({
        type: UPDATE_MEMBER_FORM_ERROR,
        data: { errors },
        formType
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('edit error ', error);
    let errors;
    if (_.isEmpty(_.get(error, 'response.data.errors'), [])) {
      errors = ['Error editing member profile.'];
    } else {
      errors = _.get(error, 'response.data.errors');
    }
    if (error.message.indexOf('403') > -1) {
      //Access restriction error
      errors = ['You no longer have edit/view rights.'];
    }

    yield put({
      type: UPDATE_MEMBER_FORM_ERROR,
      data: {
        errors,
        formType
      }
    });
  }
}

function* putMemberRidesharePreferences(action) {
  const passengerId = action.data;

  try {
    const { data } = yield getRidesharePreferences(passengerId);
    yield put({ type: SET_MEMBER_RIDESHARE_PREFERENCES, data });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to load member_rideshare_preferences', e);
  }
}

/**
 * Saga function to get mileage reimbursement drivers from api
 */
function* getMileageReimbursementDriversFromApi(action) {
  try {
    const passengerId = action.data;
    const response = yield fetch(
      `${process.env.APP_LAYER_URL}/member/v1/passengers/${passengerId}/drivers`,
      {
        ...baseRequestConfig(),
        method: 'GET'
      }
    );

    const results = yield response.json();

    if (results?.status) {
      yield put({
        type: GET_MILEAGE_REIMBURSEMENT_DRIVERS_SUCCESS,
        data: results.data
      });
    } else {
      const error =
        results?.message ?? 'API Error getting mileage reimbursement drivers.';
      // eslint-disable-next-line no-console
      console.error(error);
      yield put({
        type: GET_MILEAGE_REIMBURSEMENT_DRIVERS_ERROR,
        data: { error }
      });
    }
  } catch (e) {
    const error =
      e?.message ?? 'Care frontend error getting mileage reimbursement drivers.';
    // eslint-disable-next-line no-console
    console.error(error);
    yield put({
      type: GET_MILEAGE_REIMBURSEMENT_DRIVERS_ERROR,
      data: { error }
    });
  }
}

/**
 * Saga function wrapper
 * @returns {undefined}
 */
export function* memberProfileSaga() {
  yield takeEvery(LOAD_MEMBER_FORM, getMemberProfileFromApi);
  yield takeEvery(LOAD_MEMBER_AUDIT_LOGS, getMemberAuditLogsFromApi);
  yield takeEvery(UPDATE_MEMBER_FORM, putMemberProfileToApi);
  yield takeEvery(GET_MEMBER_RIDESHARE_PREFERENCES, putMemberRidesharePreferences);
  yield takeEvery(
    GET_MILEAGE_REIMBURSEMENT_DRIVERS_START,
    getMileageReimbursementDriversFromApi
  );
}
