import type { AppStore } from '~/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { type UnknownAction } from 'redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { type ThunkAction, type ThunkDispatch } from 'redux-thunk';

export type RootState = AppStore;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppDispatch = ThunkDispatch<AppStore, any, UnknownAction>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppStore,
  unknown,
  UnknownAction
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppStore> = useSelector;
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  extra: { s: string; n: number };
}>();
