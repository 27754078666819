import { createSelector } from '@reduxjs/toolkit';
import { selectEligibility } from '~/Modules/memberProfile';
import type { AppStore } from '~/types';
import type { TreatmentRecord } from '~/Modules/patients';
import type {
  RideBenefitsRecord,
  RideBenefitsUnlimitedRecord
} from '~/Pages/RideBooking/Components/FundingSource/FundingSource.types';
import { RIDES_PER_YEAR_LABEL } from '~/Pages/RideBooking/Components/FundingSource/FundingSource.utils';
import { minutesUntilDate } from '~/utilities/timesAndDates';

const selectDateRestrictions = (s: AppStore) => s.rideBooking.date.dateRestrictions;
const selectPastBookingDays = (s: AppStore) => s.user?.pastBookingDays ?? 0;

const receiveActiveTreatment = (
  _s: AppStore,
  treatment: TreatmentRecord | undefined,
  _b: RideBenefitsUnlimitedRecord | RideBenefitsRecord | undefined
) => treatment;

const receiveBenefitsRecord = (
  _s: AppStore,
  _t: TreatmentRecord | undefined,
  benefitsRecord: RideBenefitsUnlimitedRecord | RideBenefitsRecord | undefined
) => benefitsRecord;

/**
 * Determines if the passenger is blocked from booking a ride based on their
 * current benefits and if the sub health plan has treatments enabled. This only
 * affects passengers with health sub plans that have a rides per year benefit
 * AND have treatments enabled.
 *
 * If a passenger is no longer eligible but WAS eligible then we have to check
 * the date of their latest eligibility. If it's within the past booking days
 * range then they should be allowed to book a past ride. The date lookup accounts
 * for member eligibility.
 */
export const selectIsBlocked = createSelector(
  [
    selectEligibility,
    selectPastBookingDays,
    selectDateRestrictions,
    receiveActiveTreatment,
    receiveBenefitsRecord
  ],
  (
    isEligible,
    pastBookingDays,
    dateRestrictions,
    treatment,
    benefitsRecord
  ): [boolean, 'Hard' | 'Soft' | 'Past' | undefined] => {
    // If the user is no longer eligible but WAS eligible then we have to check
    // the date of their latest eligibility. If it's within the past booking days
    // range then they should be allowed to book a past ride. The date lookup accounts
    // for member eligibility.
    if (!isEligible) {
      if (!pastBookingDays || !dateRestrictions) return [true, 'Hard'];

      if (minutesUntilDate(dateRestrictions.latestDate) <= pastBookingDays * 24 * 60) {
        return [true, 'Past'];
      }
    }

    // Unlimited benefits don't block
    if (benefitsRecord?.limit === 'Unlimited') return [false, undefined];

    // Treatments is disabled by health sub plan or no benefits limit
    if (!treatment || !benefitsRecord) return [false, undefined];

    // Only block rides per year
    if (benefitsRecord.label !== RIDES_PER_YEAR_LABEL) return [false, undefined];

    // Non-deducting benefit doesn't count against limit
    if (treatment.benefit_deduction === 0) return [false, undefined];

    // Passenger is not medicaid and still has remaining benefits
    if (benefitsRecord.usage < benefitsRecord.limit) {
      return [false, undefined];
    }

    if (benefitsRecord.hardBlock) return [true, 'Hard'];

    return [true, 'Soft'];
  }
);
