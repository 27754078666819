import { Is, createModelFactory, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';
import { isStringOrNumber } from '~/utilities/guards';

export type AddressSaved = {
  type: 'saved';
  value: string;
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  zip: number | string;
  timezone?: string;
};

export type AddressSavedModel = ModelType<AddressSaved>;

export type AddressSavedPropertyRule = PropertyRuleSignature<AddressSaved>;

export const ADDRESS_SAVED_DEFAULT = (): AddressSaved => ({
  type: 'saved',
  value: '',
  id: 0,
  name: '',
  latitude: 0,
  longitude: 0,
  zip: ''
});

export const addressSavedFactory = createModelFactory<AddressSavedModel>(
  ADDRESS_SAVED_DEFAULT(),
  {
    properties: [
      ['is-address-type', 'type', v => v === 'saved'],
      [
        'is-string',
        'value',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Address cannot be empty.');

          return Is.String.strict(v);
        }
      ],
      [
        'is-number',
        'id',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Id cannot be empty.');

          return Is.Number.strict(v);
        }
      ],
      [
        'is-string',
        'name',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Name cannot be empty.');

          return Is.String.strict(v);
        }
      ],
      [
        'is-string',
        'zip',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Zipcode cannot be empty.');

          return isStringOrNumber.strict(v);
        }
      ],
      [
        'is-number',
        'latitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Latitude cannot be empty.');

          return Is.Number.strict(v);
        }
      ],
      [
        'is-number',
        'longitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Longitude cannot be empty.');

          return Is.Number.strict(v);
        }
      ]
    ],
    model: []
  }
);
