import { createAppAsyncThunk } from '~/Modules';
import { getDateRestrictions } from '~/services/rideBooking.service';
import type { PassengerInfoModel } from '~/models';

/**
 *  Thunk to get the date restrictions for the ride booking flow
 */
export const initializeDateThunk = createAppAsyncThunk(
  'rideBooking/initializeDate',
  (passengerInfo: PassengerInfoModel, { rejectWithValue }) => {
    try {
      const { passengerId, tripType, transportType } = passengerInfo;

      if (!passengerId || !tripType || !transportType) {
        throw Error('passengerId, tripType, and transportType are required');
      }

      return getDateRestrictions(passengerId, tripType, transportType).then(
        ({ data }) => data
      );
    } catch (error) {
      return rejectWithValue({ error: error.response });
    }
  }
);
