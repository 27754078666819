import { TOKENS } from '@SRHealth/frontend-lib';
import React, { useContext, useEffect, useState } from 'react';
import type { RideModel } from '~/models';
import RideCardSummary from './RideCardSummary';
import RideCardForm from './RideCardForm';
import { MapContext, type MapMarker } from './RideBookingMap.context';
import { addressToMarker, rideToMarkers } from '../Rides.utils';

export type RideCardProps = {
  /** The ride to render. */
  ride: RideModel;
  /** Callback to perform when adding a second leg. Passing this
   * indicates that this is the first leg of the trip. */
  onAddRide?: () => Promise<boolean>;
  onRemoveRide?: () => void;
  onEditRide: () => Promise<void>;
};

type RideCardState = 'edit' | 'summary';

const defaultState = (ride: RideModel) => (ride?.distance ? 'summary' : 'edit');

export default function RideCard({
  ride,
  onAddRide,
  onRemoveRide,
  onEditRide
}: RideCardProps) {
  const markerActions = useContext(MapContext)[1];
  const [cardState, setCardState] = useState<RideCardState>(defaultState(ride));

  const cardLabel = onAddRide ? 'Initial Ride' : 'Return Ride';

  /** Flips the depart and arrive addresses. */
  const handleFlipAddresses = () => {
    const newMarkers: MapMarker[] = [];

    if (ride.arriveAddress.value) {
      newMarkers.push(addressToMarker(ride.arriveAddress, 'depart'));
    }

    if (ride.departAddress.value) {
      newMarkers.push(addressToMarker(ride.departAddress, 'arrive'));
    }

    markerActions.setMarkers(newMarkers);

    [ride.arriveAddress, ride.departAddress] = [ride.departAddress, ride.arriveAddress];
  };

  /** Event handle for adding a second leg to the trip. */
  const handleAddRide = onAddRide
    ? () => onAddRide().then(success => success && setCardState('summary'))
    : undefined;

  /** Event handler for removing a ride leg. */
  const handleRemoveRide = onRemoveRide ? onRemoveRide : undefined;

  /** Event handler for clicking the Edit button. */
  const handleEdit = () => onEditRide().then(() => setCardState('edit'));

  // On card state change, update which markers we display
  useEffect(() => {
    switch (cardState) {
      case 'edit':
        if (ride.arriveAddress.value) {
          markerActions.setMarkers([addressToMarker(ride.arriveAddress, 'arrive')]);
        } else if (ride.departAddress.value) {
          markerActions.setMarkers([addressToMarker(ride.departAddress, 'depart')]);
        }
        break;
      case 'summary':
        markerActions.setMarkers(Object.values(rideToMarkers(ride)));
    }
  }, [cardState]);

  return (
    <section
      className={
        'reset-div w-full flex flex-col items-center rounded-[8px] shadow-b-wrap overflow-hidden' +
        ` bg-${TOKENS.SURFACE.PRIMARY.WHITE} border-1 border-${TOKENS.BORDER.NEUTRAL_LT}`
      }
      style={{ width: '620px' }}
    >
      <header
        className={
          'py-[16px] w-full heading-lg text-center ' +
          ` bg-${TOKENS.SURFACE.PRIMARY.XDK} text-${TOKENS.FONT.INVERSE}`
        }
      >
        {cardLabel}
      </header>

      {cardState === 'edit' ? (
        <RideCardForm
          ride={ride}
          onAddRide={handleAddRide}
          onRemoveRide={handleRemoveRide}
          onFlipAddresses={handleFlipAddresses}
        />
      ) : (
        <RideCardSummary ride={ride.toJSON()} onEdit={handleEdit} />
      )}
    </section>
  );
}
