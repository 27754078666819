import React from 'react';
import { useMemberPortalStyles, memberPortalDescriptionStyles } from './styles';
import { Icon, TOKENS } from '@SRHealth/frontend-lib';
import MemberPortalManage from './subcomponents/MemberPortalManage';
import MemberPortalAccount from './subcomponents/MemberPortalAccount';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { getMemberPortalInfoFromApi } from '~/Modules/memberProfile/actions';
import LoadingModal from '~/Shared/Components/LoadingModal/LoadingModal';
import { MemberPortalInfo } from '~/Modules/memberProfile/types';

type MemberPortalDescriptionProps = {
  description?: MemberPortalInfo['portalDescription'];
};

function MemberPortalSupportDescription({ description }: MemberPortalDescriptionProps) {
  return description ? (
    <div style={memberPortalDescriptionStyles.container}>
      <Icon style={memberPortalDescriptionStyles.icon} type="Info" />
      <p className="text-sm">{description}</p>
    </div>
  ) : null;
}

type MemberPortalHeaderProps = {
  ssoEnabled: MemberPortalInfo['ssoEnabled'];
};

function MemberPortalSupportHeader({ ssoEnabled }: MemberPortalHeaderProps) {
  const headerText = ssoEnabled
    ? `Member Portal access using health plan login credentials (SSO)`
    : `Member Portal access using Saferide login credentials`;
  return (
    <div className={`heading-lg font-bold text-${TOKENS.FONT.PRIMARY}`}>{headerText}</div>
  );
}

function MemberPortalSupport() {
  const classes = useMemberPortalStyles();
  const dispatch = useAppDispatch();

  const fetchMemberPortalInfo = async (passengerId: number) => {
    try {
      await dispatch(getMemberPortalInfoFromApi(passengerId));
    } catch (err) {
      console.log(err);
    }
  };

  const memberPortalInfo = useAppSelector(state => state.memberProfile.memberPortalInfo);
  const memberPortalSlug = useAppSelector(
    state => state.memberProfile.formData.benefits.memberPortalSlug
  );
  const isLoading = useAppSelector(
    state => state.memberProfile.isLoadingMemberPortalInfo
  );

  if (isLoading) {
    return <LoadingModal label="Loading..." isOpen={isLoading} />;
  }

  return (
    <div className="parentTab">
      <div className={classes.container}>
        <MemberPortalSupportHeader ssoEnabled={memberPortalInfo.ssoEnabled} />
        <MemberPortalSupportDescription
          description={memberPortalInfo.portalDescription}
        />
        <div className={classes.accountContainer}>
          <MemberPortalAccount memberPortalInfo={memberPortalInfo} />
          <MemberPortalManage
            refreshData={fetchMemberPortalInfo}
            memberPortalInfo={memberPortalInfo}
            portalSlug={memberPortalSlug}
          />
        </div>
      </div>
    </div>
  );
}

export default MemberPortalSupport;
